import { Circle, InfoWindow, Marker } from "@react-google-maps/api";
import React, { useMemo, useState } from "react";

interface IPosition {
  lat: number;
  lng: number;
}

const circleOptions: google.maps.CircleOptions = {
  strokeColor: "#216EF2",
  strokeWeight: 1,
  fillOpacity: 0.1,
  fillColor: "#ababab",
};

export interface IMapMarkerProps {
  position: IPosition;
  icon: string;
  hoverComponent?: React.ReactNode | null;
  hover?: boolean;
  onClick?: () => any;
  radius?: null | number;
  id?: string;
  clusterer?: any;
  zIndex?: number;
}

const MapMarker: React.FC<IMapMarkerProps> = (props) => {
  const { position, icon, onClick, hoverComponent, radius, clusterer, zIndex } =
    props;

  const [hovered, setHovered] = useState(false);

  const MemoisedMarkers = useMemo(
    () => {
      return (
        <Marker
          animation={2}
          position={position}
          zIndex={zIndex}
          icon={{
            url: icon,
            scaledSize: new google.maps.Size(64, 64),
          }}
          onClick={onClick}
          onMouseOver={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          clusterer={clusterer}
        >
          {hoverComponent !== null && hovered && (
            <InfoWindow>
              <>{hoverComponent}</>
            </InfoWindow>
          )}
          {radius && (
            <Circle options={circleOptions} radius={radius} center={position} />
          )}
        </Marker>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [position.lat, position.lng, hovered, icon, zIndex]
  );

  return MemoisedMarkers;
};

export default MapMarker;
