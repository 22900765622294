import {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

export enum Subscription {
  Energy = 1,
  Vehicle = 2,
  ChargingStation = 3,
  ReportingFinance = 4,
}

export const SubReportingFinance = [
  Subscription.ChargingStation,
  Subscription.ReportingFinance,
];

export interface SubscriptionContextInterface {
  orgId: string;
  isInitialized: boolean;
  checkSubscription: (sub: Subscription | Subscription[]) => boolean;
  setSubscriptions: (sub: Subscription[], orgId: string) => void;
}

const SubscriptionContext = createContext<SubscriptionContextInterface>({
  orgId: "",
  isInitialized: false,
  checkSubscription: () => false,
  setSubscriptions: () => void 0,
});

export const SubscriptionProvider: FunctionComponent<{
  subscriptions?: Subscription[];
  orgId?: string;
}> = ({ subscriptions: nextSub, orgId: nextOrgId, children }) => {
  const [subscriptions, setSubscriptions] = useState<
    Subscription[] | undefined
  >(Array.isArray(nextSub) ? nextSub : undefined);
  const [orgId, setOrgId] = useState<string>(nextOrgId ?? "");

  useEffect(() => {
    setSubscriptions((prevSub) => {
      if (
        prevSub?.length === nextSub?.length &&
        nextSub?.every((x) => prevSub?.includes(x))
      ) {
        return prevSub;
      }
      return nextSub ?? prevSub;
    });
  }, [nextSub]);

  const checkSubscription = useCallback(
    (sub: Subscription | Subscription[]) => {
      const subs = Array.isArray(sub) ? sub : [sub];
      if (!Array.isArray(subscriptions)) {
        return false;
      }
      return subs.every((s) => subscriptions.includes(s));
    },
    [subscriptions]
  );

  if (process.env.NODE_ENV !== "production") {
    (window as any).__setSubscriptions__ = (s: any) => setSubscriptions(s);
  }

  const value: SubscriptionContextInterface = useMemo(() => {
    const externalSetSubscriptions = (sub: Subscription[], orgId: string) => {
      setSubscriptions(sub);
      setOrgId(orgId);
    };
    return {
      orgId,
      checkSubscription,
      isInitialized: Array.isArray(subscriptions),
      setSubscriptions: externalSetSubscriptions,
    };
  }, [subscriptions, orgId, checkSubscription, setSubscriptions]);

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export interface SubscriptionTemplateProp {
  sub: Subscription | Subscription[];
  ErrorComponent?: React.FunctionComponent;
}

export const SubscriptionTemplate: FunctionComponent<SubscriptionTemplateProp> =
  ({ sub, ErrorComponent, children }) => {
    const { checkSubscription, isInitialized } =
      useContext(SubscriptionContext);

    const [state, setState] = useState<"Yes" | "No" | "Pending">("Pending");

    useEffect(() => {
      if (isInitialized) {
        const result = checkSubscription(sub);
        setState(result ? "Yes" : "No");
      }
    }, [checkSubscription, isInitialized, sub]);

    if (state === "No") {
      return ErrorComponent == null ? null : <ErrorComponent />;
    } else if (state === "Pending") {
      return null;
    }

    return <>{children} </>;
  };

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};
