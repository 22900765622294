import cx from "classnames";
import { IconsPortal } from "drax-design-system";
import React, { FunctionComponent } from "react";
import gtmTags from "services/Analytics/GtmTags";
import { DashboardView } from "store/slices/dashboard/types";
import "./styles.scss";

interface IToggler {
  currentView: boolean;
  onToggle: (t: DashboardView) => any;
}

// For simplicity true means map view and false is table view
const Toggler: FunctionComponent<IToggler> = ({ currentView, onToggle }) => {
  const topCx = cx(
    `c-toggler__button c-toggler__button--top ${gtmTags.mapToggle}`,
    {
      "c-toggler__button--selected": currentView,
    }
  );

  const bottomCx = cx(
    `c-toggler__button c-toggler__button--bottom ${gtmTags.tableToggle}`,
    {
      "c-toggler__button--selected": !currentView,
    }
  );

  return (
    <div className="c-toggler" data-testid="toggler">
      <div
        className={topCx}
        onClick={() => onToggle(DashboardView.Map)}
        data-testid="map-toggle"
      >
        <IconsPortal.IcMap
          width="25"
          height="25"
          className={gtmTags.mapToggle}
        />
      </div>
      <div
        onClick={() => onToggle(DashboardView.Table)}
        data-testid="table-toggle"
        className={bottomCx}
      >
        <IconsPortal.IcList
          width="25"
          height="25"
          className={gtmTags.tableToggle}
        />
      </div>
    </div>
  );
};

export default Toggler;
