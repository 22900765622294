import strings from "assets/strings";
import {
  Badge,
  Grid,
  Heading,
  Loaders,
  Text,
  TextItem,
} from "drax-design-system";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectChargeStations } from "store/slices/chargestations";
import { ChargeStationStatus } from "store/slices/chargestations/types";
import { genChargeStationColor } from "utils/colors";
interface ICsToolTips {
  dataId: string;
}

const CsToolTip: FunctionComponent<ICsToolTips> = ({ dataId }) => {
  const chargeStations = useSelector(selectChargeStations);

  const cst = chargeStations.find((f) => f.id === dataId)!;

  const {
    dashboard: {
      infoPanel: { chargeStation },
    },
  } = strings;

  if (!cst) {
    return (
      <Grid template="1fr">
        <div className="p-map-tooltip">
          <Loaders
            data-testid="loading-spinner"
            size={"1.5em"}
            type="spinner"
          />
        </div>
      </Grid>
    );
  }

  return (
    <Grid className="p-map-tooltips">
      <Heading.h5 textAlign="center">{cst.name}</Heading.h5>

      {cst.ports.map(({ status, id, connectors }, i) => (
        <Grid template="1fr" key={`tooltip-port-${id}`}>
          <TextItem
            title={`Port ${i + 1}`}
            subtitle={connectors[0].powerType}
            value={
              <Badge
                className={`${
                  status === ChargeStationStatus.UNKNOWN &&
                  "port-status--watchlist-override"
                }`}
                color={genChargeStationColor(status)}
                title={strings.chargeStationStatus[status].toUpperCase()}
              />
            }
          />
        </Grid>
      ))}
      <Grid template="1fr">
        <Text size="h6" className="bold">
          {chargeStation.loadingManagement}
          <Heading.h4 className="bold">{cst.loadManagement}</Heading.h4>
        </Text>
      </Grid>
    </Grid>
  );
};

export default CsToolTip;
