import { IODataQuery } from "./types";

class ODataQueryBuilder<T> {
  query: IODataQuery = {};

  skip = (skip: number) => {
    this.query["$skip"] = skip;
    return this;
  };

  top = (top: number) => {
    this.query["$top"] = top;
    return this;
  };

  count = () => {
    this.query["$count"] = true;
    return this;
  };

  orderBy = (name: keyof T, dir: "asc" | "desc" = "asc") => {
    let orderBy = this.query["$orderBy"] ?? "";
    ///@ts-ignore
    const newOrderBy = `${name} ${dir}`;
    orderBy += orderBy.length > 0 ? `, ${newOrderBy}` : newOrderBy;
    this.query["$orderBy"] = orderBy;
    return this;
  };

  filter = (
    name: keyof T,
    exp: "ne" | "eq" | "in" | "ge" | "le" | "gt" | "lt",
    value: string | number | Array<string | number> | Date
  ) => {
    const filter = this.query["$filter"];
    const stringifyValue = (v: any) => {
      if (typeof v == "string") {
        return `'${v}'`;
      } else if (v instanceof Date) {
        return v.toJSON().slice(0, 10); // eg '2023-02-01'
      }
      return v;
    };

    let param = null;

    if (exp === "in" && Array.isArray(value)) {
      param = `(${value.map(stringifyValue).join(",")})`;
    } else {
      param = stringifyValue(value);
    }

    ///@ts-ignore
    this.query["$filter"] = [filter, `${name} ${exp} ${param}`]
      .filter((x) => x)
      .join(" and ");
    return this;
  };

  toString = () => {
    return JSON.stringify(this.query);
  };
}

export const buildDataQuery = <T>(
  queryBuilder: (builder: ODataQueryBuilder<T>) => void
) => {
  const dataQuery = new ODataQueryBuilder<T>();
  queryBuilder(dataQuery);
  return dataQuery.query;
};
