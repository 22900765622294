import ErrorValidator from "./common/ErrorValidator";
import Domains, { DomainTypes, DraxDomains } from "./domains";
import httpClient from "./httpClient";

class DraxiApi extends ErrorValidator {
  public domains: DraxDomains;

  constructor() {
    super();
    this.domains = this.mapDomains();
  }

  public mapDomains(): DraxDomains {
    const keys = Object.keys(Domains) as (keyof DomainTypes)[];

    return keys.reduce((acc, curr) => {
      const CurrDomain = Domains[curr];
      return {
        ...acc,
        [curr]: new CurrDomain(httpClient),
      };
    }, {} as DraxDomains);
  }
}

export default new DraxiApi();
