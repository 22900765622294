import Domain from "api/common/Domain";
import apiErrorHandler from "api/common/errorHandler";
import { IHttpClient } from "api/httpClient";
import { IDomainRequestDetails } from "api/types";
import urls from "../urls";
import {
  IConsumption,
  IConsumptionsParams,
  IInsightsResponse,
  ISitesResponse,
} from "./types";

export class Consumption extends Domain {
  httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
  }

  async getSites() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.consumption}/sites`,
    };
    const response = await this.httpClient.request<ISitesResponse>(request);

    if (this.isError(response)) {
      return apiErrorHandler(response);
    }

    return response;
  }

  async getInsights() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.consumption}/insights`,
    };
    const response = await this.httpClient.request<IInsightsResponse>(request);

    if (this.isError(response)) {
      return apiErrorHandler(response);
    }

    return response;
  }

  async getConsumptions({
    siteId,
    mpanId,
    startDate,
    endDate,
    level,
  }: IConsumptionsParams) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.consumption}/sites/${siteId}/mpans/${mpanId}/consumptions?startDate=${startDate}&endDate=${endDate}&level=${level}`,
    };
    const response = await this.httpClient.request<IConsumption[]>(request);

    if (this.isError(response)) {
      return apiErrorHandler(response);
    }

    return response;
  }
}
