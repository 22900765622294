import {
  VehicleFuelType,
  VehicleStatus,
  VehicleStatusIdle,
} from "store/slices/vehicles/types";

export interface IVehicleBase {
  id: string;
  lastUpdated: Date;
  statusLastChangedDate: Date;
  stateOfCharge: number;
  imei: string;
  range: number;
  totalMileage: number;
  coordinates: ILatLng;
  fault: VehicleFault;
  status: VehicleStatus | VehicleStatusIdle;
  registeredAddress: IFullAddress;
  fuelType: VehicleFuelType;
}

export interface IVehicle extends IVehicleBase {
  statuses: VehicleStatus[];
}

export interface IVehicleDetails extends IVehicleBase {
  licensePlate: string;
  chargeLevel: number;
  ecoSafetyScore: number;
  averageUtilisation: number;
  averageMiles: number;
}

export interface ILatLng {
  latitude: number;
  longitude: number;
}

export interface IVehiclesResponse {
  items: IVehicle[];
}

export interface IEVSavingsResponse {
  numberTrees: number | null;
  poundSterling: number | null;
  cO2Emissions: number | null;
}

export interface IJourneysSummaryResponse {
  durationHours: number | null;
  distanceMiles: number | null;
}

export interface IFullAddress {
  addressLine: string;
  city: string;
  postalCode: string;
  country: string;
}

export interface IEcoSafetyScore {
  current: number;
  previous: number;
}

export interface IPerformancePayload {
  ecoSafetyScore: IEcoSafetyScore;
}

export interface IVehicleJourneysPayload {
  journeys: VehicleJourney[];
}

export interface VehicleJourney {
  id: string;
  vrn: string;
  startPostcode: string;
  startTime: string;
  endTime: string;
  endPostcode: string;
  startCharge: number;
  endCharge: number;
  chargeUsed: number;
  kwhUsed: number;
  averageMiles: number;
  ecoSafetyScore: number;
  journeyTime: number;
  fuelType: VehicleFuelType;
}

export enum VehicleFault {
  None = "None",
  HeartBeat24h = "HeartBeat24h",
  MIL = "MIL",
  Replugged = "Replugged",
  Unplugged = "Unplugged",
}
