import { createSelector } from "@reduxjs/toolkit";
import { IChargingStation } from "api/domains/chargingStations/types";
import {
  selectChargeStationDetails,
  selectChargeStations,
} from "store/slices/chargestations";
import { ChargeStationStatus } from "store/slices/chargestations/types";
import { getCsInRange } from "store/slices/vehicles/helpers";
import { RootState } from "store/store";
import { rangePercentageInMeters } from "utils/distanceUtils";
import { selectVehicleByVehicleId } from "./common";
const EmptyArray: Array<IChargingStation> = [];

export const selectSelectedChargingStationDetails = createSelector(
  selectChargeStations,
  selectChargeStationDetails,
  (_state: RootState, props: { stationId: string | null }) => props.stationId,
  (chargeStations, details, id) => {
    if (!id || !details[id]) {
      return null;
    }

    const apiData = details[id];
    if (apiData.data) {
      const { ports: detailsPorts, ...remaining } = apiData.data;
      const listStation = chargeStations.find(
        (cs) => cs.id === apiData.data.id
      );

      let ports = [...detailsPorts];

      if (listStation?.ports?.length) {
        ports = detailsPorts.map((detailsPort) => {
          const listStationPort = listStation.ports.find(
            (upr) => upr.id === detailsPort.id
          );
          return { ...detailsPort, ...listStationPort };
        });
      }

      return { ...apiData, data: { ...remaining, ports } };
    }
    return apiData;
  }
);

export const selectChargingStations = createSelector(
  [selectChargeStations],
  (chargeStations) => {
    return chargeStations.filter((station) =>
      station.ports.find((port) =>
        port.status.includes(ChargeStationStatus.CHARGING)
      )
    );
  }
);

export const selectNearestChargeStations = createSelector(
  selectChargeStations,
  selectVehicleByVehicleId,
  (chargeStations, vehicle) => {
    if (!vehicle) return EmptyArray;
    const rangeInMeters = rangePercentageInMeters(vehicle.range);
    const available = chargeStations.filter((f) =>
      f.ports.find((p) => p.status === ChargeStationStatus.AVAILABLE)
    );
    return getCsInRange(vehicle.coordinates, available, rangeInMeters);
  }
);

export const selectFilteredChargeStations = createSelector(
  selectChargeStations,
  (_state: RootState, props: { filter: string[] }) => props.filter,
  (chargeStations, filters) => {
    if (!filters.length) return chargeStations;

    return chargeStations.filter((cs) =>
      cs.ports.map((port) => port.status).find((f) => filters.includes(f))
    );
  }
);
