import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.scss";
import { ITableColumn, TableColumnConfig } from "./types";

const addDataIndexWrapper = <T,>(
  clmns: Array<ITableColumn<T>>
): Array<ITableColumn<T>> => {
  return clmns?.map((col) => {
    const { title: prevTitle, dataIndex, width, children: prevChildren } = col;
    const title = dataIndex ? (
      <span data-index={dataIndex}>{prevTitle}</span>
    ) : (
      prevTitle
    );
    const children =
      Array.isArray(prevChildren) && prevChildren.length > 0
        ? addDataIndexWrapper(prevChildren)
        : undefined;
    return { ...col, title, children, width: width ?? 200 };
  });
};

export const useColumnsSize = <T extends unknown>(
  prevColumns: TableColumnConfig<T>
): [
  TableColumnConfig<T>,
  React.RefObject<HTMLDivElement>,
  Record<string, number>,
  number | undefined
] => {
  const tableWrapperRef = useRef<HTMLDivElement>(null);

  const [size, setSize] = useState<Record<string, number>>({});
  const [width, setWidth] = useState<number>();

  const columns: TableColumnConfig<T> = useMemo(
    () => addDataIndexWrapper(prevColumns),
    [prevColumns]
  );

  useEffect(() => {
    if (tableWrapperRef.current == null) {
      return;
    }
    const cells =
      tableWrapperRef.current.getElementsByClassName("rc-table-cell");
    if (!cells.length) {
      return;
    }
    const size: Record<string, any> = {};
    //@ts-ignore
    for (const cell of cells) {
      const dataCell = cell.querySelector("[data-index]");
      const dataIndex = dataCell?.getAttribute("data-index");
      if (dataIndex) {
        size[dataIndex] = cell.offsetWidth;
      }
    }
    setSize(size);
    setWidth(tableWrapperRef.current.offsetWidth);
  }, [columns, tableWrapperRef]);

  return [columns, tableWrapperRef, size, width];
};
