import strings from "assets/strings";
import classnames from "classnames";
import { Icons, Panel, Text } from "drax-design-system";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import gtmTags from "services/Analytics/GtmTags";
import { RootState } from "store";
import { selectNearestChargeStations } from "store/selectors/chargeStations";
import { pluralise } from "utils/stringHelpers";
import "./styles.scss";

interface StationsInRangeProps {
  vehicleId: string;
  show: boolean;
  onToggleShow: () => void;
}

const StationsInRange: FunctionComponent<StationsInRangeProps> = ({
  vehicleId,
  show,
  onToggleShow,
}) => {
  const {
    dashboard: {
      infoPanel: { vehiclePanel },
    },
  } = strings;

  const stations = useSelector((state: RootState) =>
    selectNearestChargeStations(state, { vehicleId })
  );

  const noStationsAlert = stations.length === 0;
  const panelCx = classnames(
    `p-info-panel__stn-range ${gtmTags.stationsInRange} background-Primary`,
    {
      "p-info-panel__stn-range--error": noStationsAlert,
      "p-info-panel__stn-range--show": show,
    }
  );

  const handleClick = () => {
    if (noStationsAlert) return null;
    onToggleShow();
  };

  return (
    <Panel className={panelCx} onClick={handleClick}>
      <div
        className={`p-info-panel__stn-range-wrapper ${gtmTags.stationsInRange}`}
      >
        {noStationsAlert && (
          <div className="p-info-panel__stn-range-alert-icon">
            <Icons.IcDanger />
          </div>
        )}
        <Text
          className={`p-info-panel__stn-range-text ${gtmTags.stationsInRange}`}
          size="small"
        >
          {show ? vehiclePanel.showing : vehiclePanel.thereAre}{" "}
          <b>
            {vehiclePanel.availableStns
              .replace("{{num}}", stations.length.toString())
              .replace(
                "{{station}}",
                pluralise(vehiclePanel.station, stations.length)
              )}
          </b>
          {vehiclePanel.withinRange}
        </Text>
        {!noStationsAlert && (
          <Text
            className={`p-info-panel__stn-range-show-me ${gtmTags.stationsInRange}`}
            size="small"
          >
            <b>{show ? vehiclePanel.undo : vehiclePanel.showMe}</b>
          </Text>
        )}
      </div>
    </Panel>
  );
};

export default StationsInRange;
