import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { IPerformancePayload } from "api/domains/chargingStations/types";
import { RootState } from "store";
import { AppThunk } from "store/types";
import { initialState } from "./initialChargeStationsState";
import {
  IChargeStationsError,
  IChargeStationsState,
  IChargingStationDetailsPayload,
  IChargingStationsPayload,
} from "./types";

// ----------------------------
// Export our actions here
// ----------------------------
const chargeStations = createSlice({
  name: "chargeStations",
  initialState: initialState,
  reducers: {
    setAllChargingStations(
      state: IChargeStationsState,
      { payload: { data } }: PayloadAction<IChargingStationsPayload>
    ) {
      state.chargeStations = data;
      state.wasInitialLoad = true;
    },
    updateChargeStationDetailsData(
      state: IChargeStationsState,
      {
        payload: { locationId, ...remain },
      }: PayloadAction<IChargingStationDetailsPayload>
    ) {
      const prev = state.chargeStationDetails[locationId] ?? {};
      state.chargeStationDetails[locationId] = { ...prev, ...remain };
    },
    setPerformance(
      state: IChargeStationsState,
      {
        payload: { availability, utilisation },
      }: PayloadAction<IPerformancePayload>
    ) {
      state.csPerformance.availability = availability;
      state.csPerformance.utilisation = utilisation;
    },
    setChargeStationsError(
      state: IChargeStationsState,
      { payload: { chargeStationsError } }: PayloadAction<IChargeStationsError>
    ) {
      state.chargeStationsError = chargeStationsError;
    },
    resetChargeStations(state: IChargeStationsState) {
      return initialState;
    },
  },
});

export const {
  setAllChargingStations,
  setPerformance,
  setChargeStationsError,
  updateChargeStationDetailsData,
  resetChargeStations,
} = chargeStations.actions;
export default chargeStations;

export const getChargeStations = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setChargeStationsError({ chargeStationsError: false }));
    const data = await api.domains.ChargingStations.getChargingStations();
    dispatch(setAllChargingStations({ data }));
  } catch (e) {
    dispatch(setChargeStationsError({ chargeStationsError: true }));
  }
};

export const getChargeStationDetails =
  (locationId: string): AppThunk =>
  async (dispatch) => {
    dispatch(updateChargeStationDetailsData({ locationId, isLoading: true }));
    try {
      const data = await api.domains.ChargingStations.getChargeStation(
        locationId
      );
      dispatch(
        updateChargeStationDetailsData({ locationId, data, isLoading: false })
      );
    } catch (error: any) {
      const { name, message } = error;
      dispatch(
        updateChargeStationDetailsData({
          error: { name, message },
          locationId,
          isLoading: false,
        })
      );
    }
  };

export const getCSPerformanceMetrics = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setChargeStationsError({ chargeStationsError: false }));
    const resp = await api.domains.ChargingStations.getCSPerformanceMetrics();
    dispatch(setPerformance(resp));
  } catch (e) {
    dispatch(setChargeStationsError({ chargeStationsError: true }));
  }
};

export const selectChargeStations = (state: RootState) =>
  state.chargeStations.chargeStations;

export const selectChargeStationDetails = (state: RootState) =>
  state.chargeStations.chargeStationDetails;

export const selectChargeStationsPerformance = (state: RootState) =>
  state.chargeStations.csPerformance;

export const selectChargeStationsError = (state: RootState) =>
  state.chargeStations.chargeStationsError;
