import ApiError from "./common/ApiError";
import { IHttpClient } from "./httpClient";
import { IDomainRequestDetails } from "./types";
import {
  IODataCollectionRequest,
  ODataCollectionResponse,
} from "./protocols/OData/types";
import {
  hasNextLink,
  isODataCollectionResponse,
  stringifyODataQuery,
} from "./protocols/OData/ODataUtils";

export interface IODataClient {
  getODataCollection<T>(
    request: IODataCollectionRequest
  ): Promise<ODataCollectionResponse<T> | ApiError>;
}

export class ODataClient implements IODataClient {
  httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient;
  }

  private internalGetODataCollection = async <T>(
    url: string
  ): Promise<ODataCollectionResponse<T> | ApiError> => {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url,
    };

    const response = await this.httpClient.request<ODataCollectionResponse<T>>(
      request
    );

    if (isODataCollectionResponse(response)) {
      if (hasNextLink(response)) {
        const nextResp = await this.internalGetODataCollection<T>(
          response["@odata.nextLink"]!
        );
        if (isODataCollectionResponse(nextResp)) {
          return { ...nextResp, value: [...response.value, ...nextResp.value] };
        }
      }
    }
    return response;
  };

  getODataCollection = async <T>(
    request: IODataCollectionRequest
  ): Promise<ODataCollectionResponse<T> | ApiError> => {
    const { url, query } = request;

    const queryString = query == null ? "" : stringifyODataQuery(query);
    return await this.internalGetODataCollection(
      `${url}${queryString.length ? "?" + queryString : ""}`
    );
  };
}
