import React, { FunctionComponent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IDatePickerView } from "./types";

const DatePickerDayView: FunctionComponent<IDatePickerView> = ({
  selectedDate,
  onChange,
  mode,
  maxDate,
}) => {
  return (
    <div
      className="consumption-date-picker__day-view"
      data-testid="date-picker-day-view"
    >
      <DatePicker
        selected={selectedDate}
        monthsShown={2}
        onChange={onChange}
        inline
        calendarStartDay={1}
        maxDate={maxDate}
      />
    </div>
  );
};

export default DatePickerDayView;
