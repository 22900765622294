import { formatTimeDate } from "./dateFormat";
import { endOfYesterday, isBefore } from "date-fns";

export const getWarrantyValue = (warrantyDate: string | null) => {
  if (warrantyDate == null) return "—";

  return formatTimeDate(new Date(warrantyDate), "dd/MM/yyyy");
};

export const isWarrantyElapsed = (warrantyDate: string | null) =>
  warrantyDate ? isBefore(new Date(warrantyDate), endOfYesterday()) : false;
