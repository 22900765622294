import paths from "appRoutes/paths";
import {
  dashboardParser,
  defaultDashboard
} from "pages/Dashboard/dashboardUtils";
import { DashboardParamsType } from "pages/Dashboard/types";
import { useQuery } from "./useQuery";

export const useDashboardNavigation = (
  go: (p: { pathname: string; search: string }) => void
): [DashboardParamsType, (params: Partial<DashboardParamsType>) => void] => {
  const [params, buildParams] = useQuery<DashboardParamsType>(
    defaultDashboard,
    dashboardParser
  );

  const navigate = (p: Partial<DashboardParamsType>) => {
    const search = buildParams(p);
    go({ pathname: paths.home, search });
  };

  return [params, navigate];
};
