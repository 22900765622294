import Domain from "api/common/Domain";
import apiErrorHandler from "api/common/errorHandler";
import { IHttpClient } from "api/httpClient";
import { IDomainRequestDetails } from "api/types";
import urls from "../urls";
import { Organisation } from "./types";

export class Organisations extends Domain {
  httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
  }

  async getUserOrganisations() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: `${urls.organisations}/organisations`,
    };
    const response = await this.httpClient.request<Array<Organisation>>(
      request
    );

    if (this.isError(response)) {
      throw apiErrorHandler(response);
    }

    return response;
  }
}
