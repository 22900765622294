import { useEffect } from "react";
import { debounce } from "utils/debounce";

export const useDocumentEventListener = <K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => void,
  timeout?: number
) => {
  useEffect(() => {
    const debounceListener =
      typeof timeout === "number" && timeout > 0
        ? debounce(listener, timeout)
        : listener;
    document.addEventListener(type, debounceListener);
    return () => {
      document.removeEventListener(type, debounceListener);
    };
  }, [type, listener, timeout]);
};
