import { IChargingStation } from "api/domains/chargingStations/types";
import { IVehicle } from "api/domains/vehicles/types";
import {
  ChargeStation,
  ChargeStationStatus,
} from "store/slices/chargestations/types";
import { IDashboardTableData } from "store/slices/dashboard/types";
import { VehicleStatus } from "store/slices/vehicles/types";
import { IDataMapperType } from "./types";

export const getCSIconType = (cs: IChargingStation) => {
  if (
    cs.ports.every((port) => port.status === ChargeStationStatus.OUTOFORDER)
  ) {
    return "error";
  } else if (
    cs.ports.every((port) => port.status === ChargeStationStatus.UNKNOWN)
  ) {
    return "warning";
  }
  return "normal";
};

export const getVehIconType = (veh: IVehicle) => {
  if (
    veh.statuses.includes(VehicleStatus.Fault) ||
    veh.statuses.includes(VehicleStatus.Unknown)
  ) {
    return "error";
  } else if (veh.statuses.includes(VehicleStatus.UnderCharged)) {
    return "warning";
  }

  return "normal";
};

export const dataMap = ({
  vehicles,
  chargeStations,
}: IDataMapperType): IDashboardTableData[] => {
  // Map values
  const vehiclesMapped: IDashboardTableData[] = vehicles.map((v: IVehicle) => ({
    id: v.id,
    type: v.fuelType,
    status: null,
    statuses: v.statuses,
    range: v.range,
    name: v.id,
    address: v.registeredAddress?.addressLine || "",
    town: v.registeredAddress?.city || "",
    postcode: v.registeredAddress?.postalCode || "",
    iconType: getVehIconType(v),
    warranty: null,
  }));

  const chargeStationsMapped: IDashboardTableData[] = chargeStations.map(
    (cs) => ({
      id: cs.id,
      type: ChargeStation.Type,
      status: cs.ports[0].status,
      name: cs.name,
      address: cs.address.addressLine,
      town: cs.address.city,
      postcode: cs.address.postalCode,
      iconType: getCSIconType(cs),
      warranty: cs.warrantyExpiry,
    })
  );

  // Merge
  return [...vehiclesMapped, ...chargeStationsMapped];
};
