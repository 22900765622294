import cx from "classnames";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { Checkbox, Text } from "drax-design-system";
import { FunctionComponent } from "react";
import gtmTags from "services/Analytics/GtmTags";
import { IFilter } from "../types";
import "./styles.scss";

const Filter: FunctionComponent<IFilter> = ({
  maxValue,
  currentValue,
  title,
  isSelected,
  filter,
  state = "primary",
}) => {
  const currentPercent = currentValue / maxValue;
  const isDisabled = currentValue === 0;

  const toggle = () => {
    if (!isDisabled) return filter();
    return null;
  };

  const containerCx = cx("p-filter__container", {
    "p-filter__container--selected": isSelected,
  });

  return (
    <div className={containerCx} data-testid="filter-container">
      <Checkbox
        disabled={isDisabled}
        checked={isDisabled ? false : isSelected}
        onChange={toggle}
        label={""}
        // label={strings.dashboard.infoPanel.fitlerTitle.replace(
        //   "{{title}}",
        //   title
        // )}
        className={gtmTags.filter}
        data-testid="filter-checkbox"
      />
      <div className="p-filter__mid-section" onClick={toggle}>
        <div className="p-filter__upper">
          <Text.div
            className="p-filter__title"
            textColor={isDisabled ? "gray" : "tertiary"}
            data-testid="filter-title"
          >
            {title}
          </Text.div>
          <Text.div
            className={isDisabled ? gtmTags.filter : `${gtmTags.filter} bold`}
            textColor={(isDisabled && "gray") || "tertiary"}
            data-testid="filtered-value"
          >
            {currentValue}
          </Text.div>
        </div>
        <div className={`p-filter__progress ${gtmTags.filter}`}>
          <ProgressBar value={currentPercent} state={state} />
        </div>
      </div>
    </div>
  );
};
export default Filter;
