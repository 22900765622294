import { ISite } from "api/domains/consumption/types";
import strings from "assets/strings";
import { Select } from "components";
import { ISelectOption } from "components/Select/Select";
import { Container, Heading } from "drax-design-system";
import { useReportNavigation } from "hooks/useReportNavigation";
import { ChartControls } from "pages/Reports/components/Charts";
import {
  createRef,
  FunctionComponent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsumptions,
  getInsights,
  getSites,
  selectConsumptionsData,
} from "store/slices/consumption";
import { formatTimeDate } from "utils/dateFormat";
import { dateOnlyParameter } from "utils/queryUtils";
import { ReportChartContainer } from "../Reports/components/Charts/ReportChartContainer";
import DatePicker, {
  DatePickerModes,
  getDayDates,
  getMonthDates,
  getWeekDates,
  getYearDates,
} from "./components/DatePicker";
import {
  generateChartData,
  generateExportDataStructure,
} from "./components/energyConsumptionUtils";
import Insights from "./components/Insights";
import "./styles.scss";
import {
  chartLabels,
  IChartDateFilter,
  IDatasetHidden,
  IFormatedSite,
} from "./types";

const aggregationLevels = {
  1: "Year",
  2: "Month",
  3: "Week",
  4: "Day",
};

const formatSelectEl = (label: string, value: string): ISelectOption => ({
  label,
  value,
});

const formatSitesData = (sites: ISite[]) =>
  sites?.map((el: ISite) => ({
    ...formatSelectEl(el.name, el.id),
    id: el.id,
    mpans: el.mpans.map((el) => formatSelectEl(el.mpan, el.mpan)),
  })) || [];

const defaultDate = new Date(
  new Date().getFullYear(),
  new Date().getMonth() - 1,
  1
);

interface EnergyConsumptionFilter {
  date: Date;
  level: DatePickerModes;
}

const adjustDates = (date: Date, aggregationLevel: DatePickerModes) => {
  let chartDates: IChartDateFilter;
  switch (aggregationLevel) {
    case DatePickerModes.DAY:
      chartDates = getDayDates(date);
      break;
    case DatePickerModes.WEEK:
      chartDates = getWeekDates(date);
      break;
    case DatePickerModes.YEAR:
      chartDates = getYearDates(date);
      break;
    default: // or MONTH
      chartDates = getMonthDates(date);
      break;
  }
  return chartDates;
};

const parcer = { date: dateOnlyParameter };

const EnergyConsumption: FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    reports: {
      energyConsumption: { mpan, site, title, description, headerTitle },
    },
  } = strings;
  const { sites, sitesError, insights, consumptionsError, consumptionsData } =
    useSelector(selectConsumptionsData);
  const [consumptionsDataKey, setConsumptionsDataKey] = useState<string>();
  const { isLoading, data: consumptions } = consumptionsData?.[
    consumptionsDataKey ?? "_"
  ] ?? { isLoading: true };
  const [isPdf, setIsPdf] = useState(false);

  const [filter, setFilter] = useReportNavigation<EnergyConsumptionFilter>(
    { date: defaultDate, level: DatePickerModes.MONTH },
    parcer
  );

  const { date, level: aggregationLevel } = filter;

  const chartDates = useMemo(
    () => adjustDates(date, aggregationLevel),
    [date, aggregationLevel]
  );
  const { startDate, endDate } = chartDates;

  const buttonRef: RefObject<HTMLDivElement> = createRef();
  const [selectedSite, selectSite] = useState<IFormatedSite>();
  const [selectedMpan, selectMpan] = useState<ISelectOption>();
  const [sitesData, setSitesData] = useState<IFormatedSite[]>([]);

  const [dataHidden, setDataHidden] = useState<IDatasetHidden>({
    0: false,
    1: false,
    2: false,
    3: true,
  });

  const hasData =
    (consumptions ?? []).length > 0 &&
    (consumptions ?? []).some(
      (c) =>
        c.evConsumptionKwh !== 0 ||
        c.siteConsumptionKwh !== 0 ||
        c.totalConsumptionKwh !== 0
    );

  const toggleDataset = (datasetKey: any) => {
    setDataHidden((prev: any) => ({
      ...prev,
      [datasetKey]: !prev[datasetKey],
    }));
  };

  const handleDateChange = useCallback(
    (date: Date, level: DatePickerModes) => {
      const { startDate } = adjustDates(date, level);
      setFilter({ date: startDate, level });
    },
    [setFilter]
  );

  const handleBarClick = (date: Date) => {
    if (aggregationLevel === DatePickerModes.DAY) {
      return;
    }
    handleDateChange(new Date(date), aggregationLevel + 1);
  };

  const chartData = useMemo(
    () => generateChartData(dataHidden, aggregationLevel, consumptions),
    [dataHidden, aggregationLevel, consumptions]
  );

  const selectSiteHandler = (site: IFormatedSite) => {
    selectSite(site);
    selectMpan(site.mpans[0]);
  };

  const backLinkAction = () => {
    if (aggregationLevel === DatePickerModes.YEAR) {
      return;
    }
    handleDateChange(date, aggregationLevel - 1);
  };

  const exportData = useMemo(() => {
    if (!selectedMpan || !consumptions?.length) {
      return [];
    }
    return generateExportDataStructure(
      selectedMpan,
      date,
      consumptions,
      aggregationLevel
    );
  }, [selectedMpan, date, aggregationLevel, consumptions]);

  useEffect(() => {
    if (!insights) {
      dispatch(getInsights());
    }
  }, [insights, dispatch]);

  useEffect(() => {
    if (!sites) {
      dispatch(getSites());
    } else {
      const formatedSites = formatSitesData(sites);
      setSitesData(formatedSites);
      selectSite(formatedSites[0] || undefined);
      selectMpan(formatedSites[0]?.mpans[0] || undefined);
    }
  }, [sites, dispatch]);

  const createConsumptionsKey = (
    siteId: string,
    mpanId: string,
    startDate: string,
    endDate: string,
    level: string
  ) =>
    JSON.stringify({
      siteId,
      mpanId,
      startDate,
      endDate,
      level,
    });

  useEffect(() => {
    const { startDate: startDateTime, endDate: endDateTime } = chartDates;

    if (!selectedSite || !selectedMpan) {
      return;
    }

    const siteId = selectedSite?.id;
    const mpanId = selectedMpan?.value as string;
    const startDate = formatTimeDate(startDateTime, "yyyy-MM-dd");
    const endDate = formatTimeDate(endDateTime, "yyyy-MM-dd");
    const level = aggregationLevels[aggregationLevel];

    const dataKey = createConsumptionsKey(
      siteId,
      mpanId,
      startDate,
      endDate,
      level
    );
    const data = consumptionsData[dataKey];
    setConsumptionsDataKey(dataKey);
    if (data) {
      return;
    }
    dispatch(
      getConsumptions({ siteId, mpanId, startDate, endDate, level }, dataKey)
    );
    // eslint-disable-next-line
  }, [startDate, endDate, selectedMpan?.value, selectedSite?.id]);

  useEffect(() => {
    const hide = aggregationLevel !== DatePickerModes.DAY;
    setDataHidden((prev: any) => ({ ...prev, 3: hide }));
  }, [aggregationLevel]);

  const showExport = !!consumptions?.length || !!sites?.length;

  const isReportLoading = !sites || (isLoading && sites.length > 0);

  return (
    <section className="p-page__panel" data-testid="energy-consumption-page">
      <Container layout="maxWidth">
        <div className="p-panel__page-title-wrap">
          <Heading.h3 textColor="tertiary">{headerTitle}</Heading.h3>
        </div>
        <Insights />
        <ChartControls
          buttonRef={buttonRef}
          exportData={hasData ? exportData : []}
          isPdf={isPdf}
          setIsPdf={setIsPdf}
          showExport={showExport}
        >
          <DatePicker
            selectedDate={date}
            onFilterApply={handleDateChange}
            mode={aggregationLevel}
            onModeChange={() => void 0}
          />
          {!!sitesData?.length && selectedSite && selectedMpan && (
            <>
              <Select
                options={sitesData}
                value={selectedSite}
                showIcon={false}
                label={site}
                placeholder=""
                onSelectChange={(el: IFormatedSite) => selectSiteHandler(el)}
                isClearable={false}
                data-testid="sites-dropdown"
              />
              <Select
                options={selectedSite.mpans || []}
                value={selectedMpan}
                showIcon={false}
                label={mpan}
                placeholder=""
                onSelectChange={(el: ISelectOption) => selectMpan(el)}
                isClearable={false}
                data-testid="mpans-dropdown"
              />
            </>
          )}
        </ChartControls>
        <ReportChartContainer
          title={title}
          description={description}
          chartData={chartData}
          toggleDataset={toggleDataset}
          onBarClick={handleBarClick}
          backLink={chartLabels[aggregationLevel].backString}
          backLinkAction={backLinkAction}
          isLoading={isReportLoading}
          hasFetchError={sitesError || consumptionsError}
          hasData={hasData}
        />
      </Container>
    </section>
  );
};

export default EnergyConsumption;
