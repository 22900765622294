import { Info } from "assets/images/svgs";
import classNames from "classnames";
import { useId } from "hooks/useId";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./styles.scss";

interface IInformationTooltip {
  title: string | React.ReactElement;
  description?: string | React.ReactElement;
  stopPropagation?: boolean;
  clickable?: boolean;
  wider?: boolean;
}

const InformationTooltip: React.FC<IInformationTooltip> = ({
  title,
  description,
  stopPropagation,
  wider = true,
}) => {
  const id = useId();
  return (
    <div
      className="information-tooltip"
      onClick={(e) => {
        if (stopPropagation === true) {
          e.stopPropagation();
        }
      }}
    >
      <Info id={`tooltip#${id}`} />
      <Tooltip
        clickable
        anchorId={`tooltip#${id}`}
        events={["click"]}
        variant="light"
        positionStrategy="fixed"
        className={classNames("information-tooltip__overlay d-Panel", {
          wider: wider,
        })}
        classNameArrow="information-tooltip__arrow"
        place="bottom"
      >
        <>
          <div className="information-tooltip__title">{title}</div>
          <div className="information-tooltip__description">{description}</div>
        </>
      </Tooltip>
    </div>
  );
};

export default InformationTooltip;
