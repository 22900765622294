import { useSelector } from "react-redux";
import { selectModalInfo } from "store/slices/modal";
import { modalsDefinition } from "./modalTypes";

const ModalFactory = () => {
  const { type } = useSelector(selectModalInfo);

  if (!type) {
    return null;
  }

  const OpenModal = modalsDefinition.get(type);

  if (!OpenModal) {
    throw new Error(`Undefined modal type ${type}`);
  }

  return <OpenModal data-testid="open-modal" />;
};

export default ModalFactory;
