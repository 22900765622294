import { groupByDate, sortByDate } from "../../utils/dateFormat";
import { INotification } from "../../store/slices/notifications/types";

export const mapNotificationsToViewItems = (items: INotification[]) => {
  const groupedNotifications = groupByDate(items, "dateCreatedUtc");

  const sortedNotifications = Object.keys(groupedNotifications).map((date) => {
    const dateNotifications = groupedNotifications[date];
    const notifications = sortByDate(dateNotifications, "dateCreatedUtc");
    const dateCreatedUtc = notifications[0].dateCreatedUtc;
    return { dateCreatedUtc, notifications };
  });

  const result = sortedNotifications.reduce((acc, curr) => {
    acc.push({
      notificationGroupDate: curr.dateCreatedUtc,
      dateCreatedUtc: curr.dateCreatedUtc,
    } as any);
    acc.push(...curr.notifications);
    return acc;
  }, [] as Array<INotification>);

  return sortByDate(result, "dateCreatedUtc");
};
