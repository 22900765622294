import { ChevronLeft } from "assets/images/svgs";
import { FunctionComponent } from "react";
import ErrorBox from "../ErrorBox";
import "./styles.scss";

export interface ITrend {
  title: string;
  subTitle: string;
  prevValue?: number | null;
  currValue: number | null;
  targetValue?: number;
  ValueComponent?: React.ComponentType<ITrend>;
  TooltipComponent?: React.ComponentType<any>;
  TargetComponent?: React.ComponentType<ITrend> | string;
  onForward?: () => void;
  theme?: "dark" | "gray";
  testId?: string;
}

const Trend: FunctionComponent<ITrend> = (props) => {
  const { theme } = props;
  const { title, subTitle } = props;
  const { currValue, prevValue, targetValue } = props;
  const { ValueComponent, TargetComponent, TooltipComponent } = props;
  const { testId } = props;
  const { onForward } = props;

  const value = ValueComponent ? <ValueComponent {...props} /> : currValue;
  const target =
    typeof TargetComponent == "string" ? (
      TargetComponent
    ) : TargetComponent ? (
      <TargetComponent {...props} />
    ) : (
      ""
    );

  const themeClass =
    theme === "dark"
      ? "trend-card__dark"
      : theme === "gray"
      ? "trend-card__gray"
      : "";

  let currWidth =
    typeof currValue == "number" ? (currValue / (targetValue ?? 100)) * 100 : 0;
  let prevWidth =
    typeof prevValue == "number" ? (prevValue / (targetValue ?? 100)) * 100 : 0;
  currWidth = Math.min(100, currWidth);
  prevWidth = Math.min(100, prevWidth);

  const hasForward = typeof onForward === "function";

  const Tooltip =
    TooltipComponent != null ? (
      <div className="trend-card__tooltip">
        <TooltipComponent />
      </div>
    ) : null;
  return (
    <div
      className={`trend-card ${themeClass}`}
      data-testid={testId ?? ""}
      onClick={onForward}
    >
      {Tooltip}
      {hasForward && (
        <div className="trend-card__forward">
          <span>Report</span>
          <ChevronLeft />
        </div>
      )}
      <div className="trend-card__title">{title}</div>
      <div className="trend-card__sub-title">{subTitle}</div>
      <div className="trend-card__current-value">{value}</div>
      <div className="trend-card__progress-bar">
        {typeof prevValue == "number" && (
          <div
            className="trend-card__progress-bar-previous"
            style={{ width: `${prevWidth}%` }}
          />
        )}
        <div
          className="trend-card__progress-bar-current"
          style={{ width: `${currWidth}%` }}
        />
      </div>
      <div className="trend-card__target">{target}</div>
    </div>
  );
};

export default Trend;

export const TrendError = () => {
  return (
    <div className={`trend-card trend-card__error`}>
      <ErrorBox />
    </div>
  );
};
