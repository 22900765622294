import strings from "assets/strings";
import { FunctionComponent } from "react";
import { roundHour, Scales } from "../Insights/reportSavingsData";
import { GeneralTrend } from "./GeneralTrend";
import "./styles.scss";

export const CharginsStationsUptimeTrend: FunctionComponent<{
  lastFaultTime: number | null;
  bestUptime: number | null;
}> = ({ lastFaultTime, bestUptime }) => {
  const {
    reports: {
      trends: { timeSinceLastFault, inTheGreen },
    },
  } = strings;

  let value = roundHour(lastFaultTime);

  const targetData =
    bestUptime != null && (value ?? 0) <= bestUptime
      ? [{ min: 0, max: bestUptime, name: "" }]
      : [];

  return (
    <GeneralTrend
      testId="cs-uptime"
      value={value}
      title={inTheGreen}
      subTitle={timeSinceLastFault}
      valueTemplate="{0}{1} hrs"
      targetTemplate="best time {0}{1} hrs"
      targetData={targetData}
      scales={Scales.Number}
    />
  );
};
