export enum InsightMetrics {
  ConsumptionSite = "ConsumptionSite",
  ConsumptionEV = "ConsumptionEV",
}

export enum InsightTrends {
  NoChange = "NoChange",
  Upward = "Upward",
  Downward = "Downward",
}

export interface IMpan {
  mpan: string;
}

export interface ISite {
  id: string;
  name: string;
  mpans: IMpan[];
}

export interface IInsight {
  metric: InsightMetrics;
  unit: string;
  trend: InsightTrends;
  monthToDate: number;
  lastMonth: number;
  previousMonth: number;
}
export interface IConsumption {
  label: string;
  evConsumptionKwh: number;
  siteConsumptionKwh: number;
  totalConsumptionKwh: number;
  capacityKva: number;
  capacityKwh: number;
}

export interface ISitesResponse {
  sites: ISite[];
}

export interface IInsightsResponse {
  insights: IInsight[];
}

export interface IConsumptionsResponse {
  consumptions: IConsumption[] | null;
  dataKey: string;
}

export interface IConsumptionsParams {
  siteId: string;
  mpanId: string;
  startDate: string;
  endDate: string;
  level: string;
}
