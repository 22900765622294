import React from "react";

import "./styles.scss";

const PageNotFound: React.FC = () => {
  return (
    <section className="page-not-found">
      <div className="text-container">
        <div>
          <b>404 Error</b>
          <div>Page not found</div>
          <div>The requested URL / error page was not found</div>
        </div>
      </div>
    </section>
  );
};

export default PageNotFound;
