import strings from "assets/strings";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEvPerformanceMetrics,
  selectElectricVehiclePerformance,
  selectVehiclesError,
} from "store/slices/vehicles";
import { TrendTarget, TrendValue, TrendWrapper } from "../Trend/TrendWrapper";

export const ElectricVehiclePerformanceTrends = () => {
  const {
    reports: {
      trends: { ecoScore, thisMonth },
    },
  } = strings;

  const dispatch = useDispatch();

  useEffect(() => {
    // EV: Eco Score
    dispatch(getEvPerformanceMetrics());
  }, [dispatch]);

  const eVPerformance = useSelector(selectElectricVehiclePerformance);
  const vehiclesError = useSelector(selectVehiclesError);

  return (
    <TrendWrapper
      testId="ev-eco-score"
      title={ecoScore}
      subTitle={thisMonth}
      prevValue={eVPerformance?.ecoSafetyScore?.previous}
      currValue={eVPerformance?.ecoSafetyScore?.current}
      error={vehiclesError}
      errorTestId={"electric-vehicle-data-error"}
      ValueComponent={TrendValue}
      TargetComponent={TrendTarget}
    />
  );
};
