import strings from "assets/strings";
import InformationTooltip from "components/InformationTooltip";
import "./styles.scss";

export const Co2SavedTooltipEV = () => {
  const {
    reports: {
      insights: { co2Saved },
    },
  } = strings;
  return (
    <InformationTooltip
      title={co2Saved}
      description={
        <>
          <p>
            Based on the <b>avoidance of CO2</b> emitted by diesel vehicles over
            the number of miles the EVs have travelled.
          </p>
          <div className="explanation-list">
            Emissions calculations based on:
            <ul>
              <li>
                The Nissan eNV200-Combi (which doesn’t emit any CO2) versus the
                Nissan NV200 Combi 1.5 Acenta
              </li>
              <li>
                Emissions value sourced from weblink{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/co2-and-vehicle-tax-tools"
                  rel="noreferrer"
                >
                  https://www.gov.uk/co2-and-vehicle-tax-tools
                </a>
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};

export const Co2SavedTooltipCS = () => {
  const {
    reports: {
      insights: { co2Saved },
    },
  } = strings;
  return (
    <InformationTooltip
      title={co2Saved}
      description={
        <>
          <p>
            Based on the <b>avoidance of CO2</b> emitted by diesel vehicles over
            the number of miles the EVs have travelled. (Estimated using an
            average m/kWh based on your energy delivered)
          </p>
          <div className="explanation-list">
            Emissions calculations based on:
            <ul>
              <li>
                The Nissan eNV200-Combi (which doesn’t emit any CO2) versus the
                Nissan NV200 Combi 1.5 Acenta
              </li>
              <li>
                Emissions value sourced from weblink{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/co2-and-vehicle-tax-tools"
                  rel="noreferrer"
                >
                  https://www.gov.uk/co2-and-vehicle-tax-tools
                </a>
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};
