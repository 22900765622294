import { useLocation, useNavigate } from "react-router-dom";
import { ParamOptions, useQuery } from "./useQuery";

export const useReportNavigation = <T>(
  defaults: Partial<T> = {},
  ///@ts-ignore
  paramOptions?: ParamOptions<T>,
  url?: string
): [T, (params: Partial<T>) => void] => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = url ?? location.pathname;

  const [params, buildParams] = useQuery<T>(defaults, paramOptions);

  const go = (p: Partial<T>) => {
    const search = buildParams(p);
    navigate({ pathname, search }, { replace: true });
  };

  return [params, go];
};
