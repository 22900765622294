import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { useMemo } from "react";
import { NotificationTypes } from "store/slices/notifications/types";

export const useSubscriptionNotificationTypes = () => {
  const sub = useSubscription();
  const notificationTypes = useMemo(() => {
    const types: Array<NotificationTypes> = [];
    if (sub.checkSubscription(Subscription.ChargingStation)) {
      types.push(NotificationTypes.ChargingStation);
    }
    if (sub.checkSubscription(Subscription.Vehicle)) {
      types.push(NotificationTypes.CombustionVehicle);
      types.push(NotificationTypes.ElectricVehicle);
    }
    return types;
  }, [sub]);

  return notificationTypes;
};
