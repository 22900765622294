import strings from "assets/strings";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { Button } from "drax-design-system";
import React from "react";
import gtmTags from "services/Analytics/GtmTags";
import "./styles.scss";

const AccessDenied: React.FC = () => {
  const { profile } = strings;
  const { logout } = useAuthenticationContext();
  return (
    <section className="p-page__panel" style={{ boxShadow: "none" }}>
      <section className="access-denied-page">
        <div className="title">Access denied</div>
        <div className="description">
          You don't have permissions to access this page.
        </div>
        <Button
          appearance="primary"
          onClick={logout}
          title={profile.logOut}
          className={gtmTags.logOut}
        />
      </section>
    </section>
  );
};

export default AccessDenied;
