import api from "api";
import { IActionResult, IEnquiry } from "api/domains/enquiry/types";
import strings from "assets/strings";
import { AppThunk } from "store/types";
import { setModalType } from "../modal";
import { ModalTypes } from "../modal/types";

export const makeEnquiry =
  (enquiry: IEnquiry): AppThunk | string =>
  async (dispatch) => {
    try {
      const data = (await api.domains.Enquiry.makeEnquiry(
        enquiry
      )) as IActionResult;

      await dispatch(
        setModalType({
          type: ModalTypes.Success,
          message: strings.form.sent,
        })
      );
      return data;
    } catch (e: any) {
      await dispatch(
        setModalType({
          type: ModalTypes.GenericError,
          message: e.message,
        })
      );
    }
  };
