import {
  ChargeStation,
  ChargeStationStatus,
} from "store/slices/chargestations/types";
import { VehicleFuelType, VehicleStatus } from "store/slices/vehicles/types";

type sortDir = "asc" | "desc";
export type StatusIconType = "normal" | "warning" | "error";

export type IDashboardTableData = {
  id: string;
  type: VehicleFuelType | ChargeStation.Type;
  status: ChargeStationStatus | null;
  statuses?: VehicleStatus[];
  name: string;
  address: string;
  town: string;
  postcode: string;
  iconType?: StatusIconType;
  warranty: string | null;
};

export enum DashboardView {
  Table,
  Map,
}

export enum InfoPanelType {
  Station,
  Vehicle,
  CombustionVehicle,
}

export interface IMapCoords {
  lat: number;
  lng: number;
}
export interface IMappSettings {
  coords: IMapCoords;
  zoom: number;
}

export interface IDashboardViewPayload {
  view: DashboardView;
}

export interface IDashboardPanelFilterVisibilility {
  toggled: boolean;
}

export interface IInfoPanelPayload {
  data: InfoPanelType | null;
}

export interface IMapSettingsPayload {
  coords: IMapCoords;
  zoom: number;
}
export interface IZoomSettings {
  zoom: number;
}

export interface ITableOrderPayload {
  sortKey: keyof IDashboardTableData;
  sortDir: sortDir;
}

export interface IDashboardState {
  mapSettings: IMappSettings;
}
