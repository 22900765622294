import { IRadioInterface } from "../types";

const Radio = (props: IRadioInterface) => {
  const { label, onChange, elements, name, value } = props;

  return (
    <div className="c-form__element">
      <label className="c-form__label c-form__label--radio" htmlFor="email">
        {label}
      </label>

      <div className="c-form__radio-wrapper">
        {elements.map((el, i) => (
          <div className="c-form__radio-element" key={`${el}-${i}`}>
            <input
              onChange={onChange}
              id={el}
              value={el}
              name={name}
              type="radio"
              checked={value === el}
              data-testid="radio-input"
            />
            <label htmlFor={el}>{el}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
