import strings from "assets/strings";
import { Grid, Heading, Loaders, TextItem } from "drax-design-system";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectCombustionVehicles } from "store/selectors";
import VehicleStatusList from "./VehicleStatusList";

interface ICombustionToolTips {
  dataId: string;
}

const CombustionToolTip: FunctionComponent<ICombustionToolTips> = ({
  dataId,
}) => {
  const {
    dashboard: {
      infoPanel: { vehiclePanel },
    },
  } = strings;
  const combustionVehicles = useSelector(selectCombustionVehicles);
  const vehicle = combustionVehicles?.find((f) => f.id === dataId)!;

  if (!vehicle) {
    return (
      <div className="p-map-tooltips">
        <Loaders data-testid="loading-spinner" size={"1.5em"} type="spinner" />
      </div>
    );
  }

  return (
    <Grid className="p-map-tooltips">
      <Grid template="1fr 1fr">
        <Heading.h5 className="p-map-tooltips__vrn">{vehicle.id}</Heading.h5>
        <Grid template="1fr">
          <VehicleStatusList statuses={vehicle.statuses} />
        </Grid>
      </Grid>
      <Grid template="1fr">
        <div>
          <TextItem
            title={vehiclePanel.totalMileage}
            value={`${vehicle?.totalMileage?.toFixed() || 0} ${
              vehiclePanel.miles
            }`}
          />
          <TextItem title={vehiclePanel.imei} value={vehicle.imei} />
        </div>
      </Grid>
    </Grid>
  );
};

export default CombustionToolTip;
