import { useEffect, useState } from "react";

export const usePreviousWhen = <T>(
  obj: T,
  equalFunc: (prev: T, next: T) => boolean = (p, n) =>
    JSON.stringify(p) === JSON.stringify(n)
) => {
  const [prev, setPrev] = useState<T>(obj);
  useEffect(() => {
    if (equalFunc(prev, obj)) {
      return;
    }
    setPrev(obj);
    // eslint-disable-next-line
  }, [obj, equalFunc]);
  return prev;
};
