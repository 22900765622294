import {
  IChargingStation,
  IChargingStationFullDetails,
  IFullAddress,
  IPerformancePayload,
} from "api/domains/chargingStations/types";

export interface IChargingStationPayload {
  data: IChargingStationFullDetails | null;
}
export interface IChargingStationDetailsPayload {
  locationId: string;
  isLoading?: boolean;
  data?: IChargingStationFullDetails;
  error?: any;
}
export interface IChargingStationsPayload {
  data: IChargingStation[];
}

export interface ISortingPayload {
  key: keyof IChargingStation;
  subkey?: keyof IFullAddress;
  dir?: "asc" | "desc";
}

export interface IFilterPayload {
  data: ChargeStationStatus;
}

export interface IChargeStationsError {
  chargeStationsError: boolean;
}
export interface INearestChargeStationsVisibilility {
  visible: boolean;
}
interface ApiData<T> {
  isLoading: boolean;
  error: any;
  data: T;
}

interface ApiDataCache<T> {
  [id: string]: ApiData<T>;
}

export interface IChargeStationsState {
  wasInitialLoad?: boolean;
  chargeStations: IChargingStation[];
  chargeStationDetails: ApiDataCache<IChargingStationFullDetails>;
  csPerformance: IPerformancePayload;
  chargeStationsError: boolean;
}

export enum ChargeStationStatus {
  AVAILABLE = "AVAILABLE",
  BLOCKED = "BLOCKED",
  CHARGING = "CHARGING",
  INOPERATIVE = "INOPERATIVE",
  OUTOFORDER = "OUTOFORDER",
  PLANNED = "PLANNED",
  REMOVED = "REMOVED",
  RESERVED = "RESERVED",
  UNKNOWN = "UNKNOWN",
}

export interface ILiveCsUpdate {
  id: string;
  ports: ILiveCsPortUpdate[];
}
export interface ILiveCsPortUpdate {
  id: string;
  status: ChargeStationStatus;
  lastUpdated: Date;
  lastStatusUpdate: Date;
}

export interface ILiveSessionUpdate {
  chargingStationId: string;
  evseId: string;
  kwh: number;
  lastUpdated: string;
}

export enum ChargeStationEvents {
  UpdateStatus = "UpdateChargingStation",
  UpdateSession = "UpdateSession",
}

export enum ChargeStation {
  Type = "chargeStation",
}
