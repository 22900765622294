export interface ISingleDatePicker {
  singleMode: DatePickerModes;
  selectedDate: Date;
  onFilterApply: (newDate: Date, mode: DatePickerModes) => void;
}
export interface IMultiModeDatePicker {
  mode: DatePickerModes;
  selectedDate: Date;
  onFilterApply: (newDate: Date, mode: DatePickerModes) => void;
  onModeChange: (newMode: DatePickerModes) => void;
}

export type IDatePicker = IMultiModeDatePicker | ISingleDatePicker;

export enum DatePickerModes {
  YEAR = 1,
  MONTH = 2,
  WEEK = 3,
  DAY = 4,
}

export interface IDatePickerView {
  selectedDate: Date;
  mode?: DatePickerModes;
  onChange: (newDate: Date) => void;
  maxDate: Date;
}
