import config from "config/app";
import { VehicleStatus, VehicleStatusIdle } from "store/slices/vehicles/types";
import { isFaultyStatus } from "utils/vehicleStatusHelper";
import { IVehicle, IVehicleBase, VehicleFault } from "./types";

export const vehicleTransfomer = (vehicles: IVehicleBase[]): IVehicle[] => {
  return vehicles.map<IVehicle>(vehicleStatusMapper);
};

export function vehicleStatusMapper<
  P extends {
    status: VehicleStatus | VehicleStatusIdle;
    fault: VehicleFault;
    stateOfCharge: number;
  },
  T extends P & { statuses: VehicleStatus[] }
>(v: P): T {
  const statuses: VehicleStatus[] = [];
  if (v.status === VehicleStatusIdle.Idle) {
    v.status = VehicleStatus.Stationary;
  }
  statuses.push(v.status);

  if (
    v.stateOfCharge !== null &&
    v.stateOfCharge <= config.app.vehicleLowChargeLevel
  ) {
    statuses.push(VehicleStatus.UnderCharged);
  }

  if (isFaultyStatus(v.fault)) {
    statuses.push(VehicleStatus.Fault);
  }

  return { ...v, statuses: statuses } as T;
}
