import { BackLink, ReportsDatePicker } from "components";
import "./styles.scss";

export interface IReportControls {
  linkUrl?: string;
  startDate: Date;
  endDate: Date;
  setStartDate?: (value: Date) => void;
  setEndDate?: (value: Date) => void;
  setDates?: (d: { startDate: Date; endDate: Date | null }) => void;
}

const ReportControls: React.FC<IReportControls> = ({
  linkUrl,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setDates,
  children,
}) => (
  <div className="p-report-controls">
    <div className="p-report-controls__left">
      {!!linkUrl && <BackLink linkUrl={linkUrl} />}
      <ReportsDatePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setDates={setDates}
      />
    </div>
    {children}
  </div>
);

export default ReportControls;
