import { Icons } from "drax-design-system";
import React, { FunctionComponent } from "react";
import gtmTags from "services/Analytics/GtmTags";
import "./style.scss";

interface IZoomControls {
  map: google.maps.Map | null;
}

const ZoomControls: FunctionComponent<IZoomControls> = ({ map }) => {
  if (!map) return null;

  const zoomIn = () => {
    const zoom = map.getZoom();
    if (zoom != null) {
      map.setZoom(zoom + 1);
    }
  };

  const zoomOut = () => {
    const zoom = map.getZoom();
    if (zoom != null) {
      map.setZoom(zoom - 1);
    }
  };

  return (
    <div className="c-zoom-controls">
      <div className={gtmTags.mapZoomIn} onClick={zoomIn}>
        <Icons.IcAdd className={gtmTags.mapZoomIn} />
      </div>
      <div className={gtmTags.mapZoomOut} onClick={zoomOut}>
        <Icons.IcSubtract className={gtmTags.mapZoomOut} />
      </div>
    </div>
  );
};

export default ZoomControls;
