import strings from "assets/strings";
import { Chart, ChartConfiguration, ChartItem, registerables } from "chart.js";
import { Loader, PageError } from "components";
import { createRef, RefObject, useEffect } from "react";
import "./styles.scss";
Chart.register(...registerables);
export interface ChartContentProps {
  chartConfig: ChartConfiguration;
  hasFetchError?: boolean;
  hasData?: boolean;
  isLoading?: boolean;
}

export const ChartContent = (props: ChartContentProps) => {
  const { chartConfig, isLoading, hasFetchError, hasData } = props;

  const chartRef: RefObject<HTMLCanvasElement> = createRef();
  let chart: ChartItem;
  const dataCount = chartConfig.data.datasets[0].data.length;
  const chartWrapperWidth = {
    width: `${dataCount * 54}px`,
  };

  const {
    api: {
      errors: { noData, energyConsumption },
    },
  } = strings;

  useEffect(() => {
    if (chartRef.current) {
      // eslint-disable-next-line
      chart = new Chart(chartRef.current, chartConfig);
    }

    return () => {
      if (chart) {
        // @ts-ignore
        chart.destroy();
      }
    };
  }, [chartRef, chartConfig]);

  if (hasFetchError) {
    return <PageError withPanel={false} message={energyConsumption.error} />;
  }

  if (isLoading) {
    return <Loader className="p-chart__loader" />;
  }

  if (!hasData) {
    return (
      <PageError
        withPanel={false}
        title={noData}
        message={energyConsumption.noData}
      />
    );
  }

  return (
    <div
      className={`p-chart-container p-chart-container--${chartConfig.type}`}
      style={{ paddingBottom: `${dataCount > 16 ? "15px" : "20px"}` }}
    >
      <div
        className="p-chart-wrapper"
        style={dataCount > 16 ? chartWrapperWidth : {}}
      >
        <canvas ref={chartRef} id={`chart-${chartConfig.type}`}></canvas>
      </div>
    </div>
  );
};
