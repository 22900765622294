import { AxiosRequestConfig, AxiosResponse } from "axios";
import ApiError from "./common/ApiError";

export interface IDomainRequestDetails extends IHttpRequest {
  /**
   * Is the request authenticated
   */
  isAuthenticated?: boolean;
}

export interface IHttpRequest {
  url: AxiosRequestConfig["url"];
  method: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
  headers?: AxiosRequestConfig["headers"];
}

export enum ApiErrorTypes {
  NoInternetConnection = -9,
  ApiInternalError = -1,
  AxiosRequestError = 0,
  Unauthorised = 401,
}

export interface IApiError {
  status?: number | ApiErrorTypes;
  statusMessage: string;
  data: any;
}

export type BaseErrorResponse = {
  error: string;
};

export type ApiErrorDetails = BaseErrorResponse;

export type IAxiosResponse<T> = AxiosResponse<T> | IApiError;
export type IHttpResponse<T> = T | ApiError;

export interface IServiceErrorResponse {
  title: string;
  status: number;
  errors: PropertyError[];
}
export interface PropertyError {
  propertyName: string;
  errorMessage: string;
}
