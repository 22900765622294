import strings from "assets/strings";
import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  getChargeStationInsightsData,
  getVehicleInsightsData,
} from "store/slices/reports";
import { GeneralTrend, TrendError } from "../Trend";
import { formatScaleString, Scales } from "./reportSavingsData";
import "./styles.scss";
import {
  Co2SavedTooltipCS,
  Co2SavedTooltipEV,
} from "./Tooltips/Co2SavedTooltip";
import {
  TotalSavingsTooltipCS,
  TotalSavingsTooltipEV,
} from "./Tooltips/TotalSavingsTooltip";
import {
  TreesSavedTooltipCS,
  TreesSavedTooltipEV,
} from "./Tooltips/TreesSavedTooltip";

const Co2PerMile = 0.221;
const MilesPerTrip = 10;
const NoData = "N/A";

const Insights: FunctionComponent = () => {
  const {
    reports: {
      insights: { moneySaved, treesSaved, co2Saved, allTime },
    },
  } = strings;

  const dispatch = useDispatch();

  const sub = useSubscription();

  useEffect(() => {
    // CS or EV:  Trees, CO2 and Money Saved
    if (sub.checkSubscription(Subscription.Vehicle)) {
      dispatch(getVehicleInsightsData());
    } else if (sub.checkSubscription(Subscription.ChargingStation)) {
      dispatch(getChargeStationInsightsData());
    }
  }, [dispatch, sub]);

  const {
    data: { cO2Emissions, poundSterling, numberTrees },
    hasError: insightsError,
  } = useSelector(
    (state: RootState) => state.reports.usage.electricVehicleSavings
  );

  const [TreesSavedTooltip, Co2SavedTooltip, TotalSavingsTooltip] =
    useMemo(() => {
      if (sub.checkSubscription(Subscription.Vehicle)) {
        return [TreesSavedTooltipEV, Co2SavedTooltipEV, TotalSavingsTooltipEV];
      } else if (sub.checkSubscription(Subscription.ChargingStation)) {
        return [TreesSavedTooltipCS, Co2SavedTooltipCS, TotalSavingsTooltipCS];
      }
      return [undefined, undefined, undefined];
    }, [sub]);

  const valueString =
    cO2Emissions != null
      ? formatScaleString(
          cO2Emissions / Co2PerMile / MilesPerTrip,
          Scales.Number
        )
      : NoData;

  if (insightsError) {
    return (
      <>
        <TrendError />
        <TrendError />
        <TrendError />
      </>
    );
  }

  return (
    <>
      <GeneralTrend
        testId="trees-saved"
        value={numberTrees}
        title={treesSaved}
        valueTemplate="{0}{1}"
        targetTemplate="target {0}{1}"
        scales={Scales.Number}
        theme="dark"
        TooltipComponent={TreesSavedTooltip}
      />
      <GeneralTrend
        testId="co2-saved"
        value={cO2Emissions}
        title={co2Saved}
        subTitle={`${valueString} car trips`}
        valueTemplate="{0} {1}"
        targetTemplate="target {0} {1}"
        scales={Scales.Weight}
        theme="dark"
        TooltipComponent={Co2SavedTooltip}
      />
      <GeneralTrend
        testId="total-savings"
        value={poundSterling}
        title={moneySaved}
        subTitle={allTime}
        valueTemplate="£{0}{1}"
        targetTemplate="target £{0}{1}"
        scales={Scales.Number}
        theme="dark"
        TooltipComponent={TotalSavingsTooltip}
      />
    </>
  );
};

export default Insights;
