import { Dispatch, MiddlewareAPI } from "redux";
import { IConnectionHandler } from "services/Connector";
import { RootState } from "store";
import {
  setUnreadNotificationsRealoadNeeded,
  updateNotifications,
} from "store/slices/notifications";
import {
  INotification,
  NotificationEvents,
  NotificationState,
  NotificationUserState,
} from "store/slices/notifications/types";

export const newNotificationHandler = (
  notification: INotification,
  { dispatch, getState }: MiddlewareAPI<Dispatch | any, RootState>
) => {
  //add user state based on update type
  //if added make the user state added too
  if (notification.state === NotificationState.New) {
    notification.userState = NotificationUserState.New;
  } else {
    // if existing copy previous state
    const exitingNotification = (
      getState().notifications.notifications ?? []
    ).find(
      (n: INotification) => n.notificationId === notification.notificationId
    );
    if (exitingNotification) {
      notification.userState = exitingNotification.userState;
    }
  }

  dispatch(setUnreadNotificationsRealoadNeeded());

  dispatch(
    updateNotifications({
      notifications: [notification],
    })
  );
};

export const messagingHandlersFactory = (
  api: MiddlewareAPI<Dispatch, RootState>
): IConnectionHandler[] => {
  return [
    {
      connectionEvent: NotificationEvents.NewStatus,
      connectionHandler: (notification: INotification) =>
        newNotificationHandler(notification, api),
    },
    {
      connectionEvent: NotificationEvents.NotificationReceived,
      connectionHandler: (notification: INotification) => {
        newNotificationHandler(notification, api);
      },
    },
  ];
};
