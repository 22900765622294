import {
  DefaultHttpClient,
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  IHttpConnectionOptions,
  LogLevel,
} from "@microsoft/signalr";

export interface IConnectionHandler {
  connectionEvent: string;
  connectionHandler: (data: any) => any;
}

export interface IConnector {
  buildConnection: () => HubConnection;
  startConnection: (connection: HubConnection) => void;
  handleConnection: (handlers: IConnectionHandler[]) => any;
  invokeAction: (actionName: string, data: any) => void;
}

class BffHttpClient extends DefaultHttpClient {
  constructor() {
    super(console);
  }

  public async send(
    request: signalR.HttpRequest
  ): Promise<signalR.HttpResponse> {
    request.headers = { ...request.headers, "X-CSRF": "1" };
    return super.send(request);
  }
}

class Connector implements IConnector {
  url: string;
  attempts: number;
  maxAttempts: number;
  connection: null | HubConnection;

  options: IHttpConnectionOptions = {
    skipNegotiation: false,
    transport: HttpTransportType.LongPolling,
    httpClient: new BffHttpClient(),
  };

  constructor(url: string) {
    this.url = url;
    this.connection = null;
    this.attempts = 0;
    this.maxAttempts = 20;
  }

  get connectionState() {
    return this.connection;
  }

  buildConnection() {
    const authenticatedOptions = {
      ...this.options,
    };
    return new HubConnectionBuilder()
      .withUrl(this.url, authenticatedOptions)
      .configureLogging(LogLevel.Error)
      .build();
  }

  async startConnection(connection: HubConnection) {
    if (this.attempts >= this.maxAttempts) return;
    this.attempts++;
    try {
      await connection.start();
      console.assert(connection.state === HubConnectionState.Connected);
      console.log(`SignalR connection established - ${this.url}`);
    } catch (err) {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.error("SignalR Connection Error: ", err);
      setTimeout(() => this.startConnection(connection), 10000);
    }
  }

  handleConnection(handlers: IConnectionHandler[]) {
    // schedule connection in a new macrotask
    setTimeout(async () => {
      if (
        this.connection != null &&
        this.connection?.state !== "Disconnected"
      ) {
        return;
      }

      try {
        this.connection = this.connection ?? this.buildConnection();

        await this.startConnection(this.connection).catch((e) =>
          console.log(e)
        );

        handlers.forEach((handler) => {
          this.connection?.on(handler.connectionEvent, (data: any) => {
            handler.connectionHandler(data);
          });
        });

        this.connection?.onclose((err: any) => {
          this.startConnection(this.connection!);
        });
      } catch (e) {
        // Fails silently
      }
    });
  }

  /**
   * For testing purposes
   * @param {string} actionName - Type of connection we're trying to invoke
   * @param {any} data - The data we're returning
   */
  invokeAction(actionName: string, data: any) {
    this.connection?.invoke(actionName, data);
  }
}

export default Connector;
