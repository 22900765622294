import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import {
  IConsumption,
  IConsumptionsResponse,
  IInsightsResponse,
  ISitesResponse,
} from "api/domains/consumption/types";
import { RootState } from "store";
import { AppThunk } from "store/types";
import { initialState } from "./consumptionInitialState";
import { IConsumptionState, IEnergyConsumptionFetchError } from "./types";

const consumptionState = createSlice({
  name: "consumption",
  initialState: initialState,
  reducers: {
    setSitesData(
      state: IConsumptionState,
      { payload: { sites } }: PayloadAction<ISitesResponse>
    ) {
      state.sites = Array.isArray(sites) ? sites : [];
    },
    setInsights(
      state: IConsumptionState,
      { payload: { insights } }: PayloadAction<IInsightsResponse>
    ) {
      state.insights = Array.isArray(insights) ? insights : [];
    },
    setConsumptionsLoading(
      state: IConsumptionState,
      {
        payload: { dataKey, isLoading },
      }: PayloadAction<{ dataKey: string; isLoading: boolean }>
    ) {
      const prevData = state.consumptionsData[dataKey] ?? {};
      state.consumptionsData[dataKey] = { ...prevData, isLoading };
    },
    setConsumptions(
      state: IConsumptionState,
      {
        payload: { consumptions, dataKey },
      }: PayloadAction<IConsumptionsResponse>
    ) {
      const prevData = state.consumptionsData[dataKey] ?? {};
      state.consumptionsData[dataKey] = {
        ...prevData,
        data: consumptions,
        isLoading: false,
      };
    },
    setFetchError(
      state: IConsumptionState,
      { payload: { error, key } }: PayloadAction<IEnergyConsumptionFetchError>
    ) {
      state[key] = error;
    },
    resetConsumptions(state: IConsumptionState) {
      return initialState;
    },
  },
});

export const {
  setSitesData,
  setInsights,
  setConsumptions,
  setConsumptionsLoading,
  setFetchError,
  resetConsumptions,
} = consumptionState.actions;
export default consumptionState;

export const getSites = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFetchError({ error: false, key: "sitesError" }));
    const data = (await api.domains.Consumption.getSites()) as ISitesResponse;

    return dispatch(setSitesData({ ...data }));
  } catch (e) {
    return dispatch(setFetchError({ error: true, key: "sitesError" }));
  }
};

export const getInsights = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFetchError({ error: false, key: "insightsError" }));
    const data =
      (await api.domains.Consumption.getInsights()) as IInsightsResponse;

    return dispatch(setInsights(data));
  } catch (e) {
    return dispatch(setFetchError({ error: true, key: "insightsError" }));
  }
};

type ConsumptionParams = {
  siteId: string;
  mpanId: string;
  startDate: string;
  endDate: string;
  level: string;
};
export const getConsumptions =
  (params: ConsumptionParams, dataKey: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFetchError({ error: false, key: "consumptionsError" }));
      dispatch(setConsumptionsLoading({ dataKey, isLoading: true }));
      const data = (await api.domains.Consumption.getConsumptions(
        params
      )) as IConsumption[];
      return dispatch(setConsumptions({ consumptions: data, dataKey }));
    } catch (e) {
      dispatch(setConsumptionsLoading({ dataKey, isLoading: false }));
      return dispatch(setFetchError({ error: true, key: "consumptionsError" }));
    }
  };

export const selectConsumptionsData = (state: RootState) => state.consumption;
