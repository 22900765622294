import strings from "assets/strings";

const {
  reports: {
    insights: { trees },
  },
} = strings;

export type TargetDataType = {
  min: number | null;
  max: number;
  name: string;
};

export const generalTargetData: Array<TargetDataType> = [
  { min: null, max: 10, name: trees.copse },
  { min: 10, max: 25, name: trees.grove },
  { min: 25, max: 50, name: trees.wood },
  { min: 50, max: 100, name: trees.wood },
  { min: 100, max: 250, name: trees.smallForest },
  { min: 250, max: 500, name: trees.smallForest },
  { min: 500, max: 1000, name: trees.smallForest },
  { min: 1000, max: 2500, name: trees.mediumForest },
  { min: 2500, max: 5000, name: trees.mediumForest },
  { min: 5000, max: 10000, name: trees.mediumForest },
  { min: 10000, max: 25000, name: trees.largeForest },
  { min: 25000, max: 50000, name: trees.largeForest },
  { min: 50000, max: 100000, name: trees.largeForest },
  { min: 100000, max: 250000, name: trees.largeForest },
  { min: 250000, max: 500000, name: trees.largeForest },
  { min: 500000, max: 1000000, name: trees.largeForest },
];

export const findTarget = (list: Array<TargetDataType>, value: number | null) =>
  value == null
    ? undefined
    : list.find((x) => {
        const min = x.min == null || x.min <= value;
        const max = value < x.max;
        return min && max;
      });

export const formatToLocaleString = (
  value: number | null,
  precision: number = 0
) =>
  value?.toLocaleString(undefined, {
    maximumFractionDigits: precision,
  });

export const formatScaleString = (
  value: number | null | undefined,
  scales: [string, string, string],
  opt?: { gep?: any; space?: string }
) => {
  const space = opt?.space ?? "";
  const gep = opt?.hasOwnProperty("gep") ? opt?.gep : "-";
  if (value == null) {
    return gep;
  }
  const { value: v, scale } = getScale(value, scales);
  return `${formatToLocaleString(v)}${space}${scale}`;
};

export type ScalesType = [string, string, string];

export const getScale = (value: number, scales: ScalesType) =>
  value >= 1000000
    ? { value: value / 1000000, scale: scales[2] }
    : value >= 1000
    ? { value: value / 1000, scale: scales[1] }
    : { value: value, scale: scales[0] };

export const Scales: { [k: string]: ScalesType } = {
  Number: ["", "K", "M"],
  Weight: ["kg", "t", "kt"],
  Energy: ["kWh", "MWh", "GWh"],
};

export const roundHour = (value: number | null) => {
  if (value! < 1000) {
    return Math.round(value!);
  }
  return value;
};
