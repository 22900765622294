import classNames from "classnames";
import Title from "components/Title";
import EntityToolTip from "pages/Dashboard/components/EntityToolTip";
import RcTable from "rc-table";
import { CustomizeScrollBody } from "rc-table/lib/interface";
import RcTooltip from "rc-tooltip";
import React from "react";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { ExportTableToolbar } from "./ExportTableToolbar";
import "./styles.scss";
import TablePlaceholders from "./TablePlaceholders";
import { ITable, ITableColumn } from "./types";
import { useColumnsSize } from "./useColumnsSize";

const flattenColumns = <T,>(columns: ITableColumn<T>[]) => {
  const internalFlattenColumns = (clmns: ITableColumn<T>[]): any[] =>
    clmns.map((c) => {
      if (c.children) {
        return internalFlattenColumns(c.children).flatMap((x) => x);
      }
      return [c];
    });

  return internalFlattenColumns(columns).flatMap((x) => x);
};

const Cell = <T,>(
  colCfg: ITableColumn<T>,
  rowIndex: number,
  record: T | any,
  size: Record<string, number>,
  scrollbarSize: number,
  isLastEl: boolean
) => {
  const { render, dataIndex, align, formatFunc } = colCfg;
  const key = dataIndex as string;

  let cell: any = "";
  if (key != null && record != null) {
    let value = record[key];

    if (typeof formatFunc === "function") {
      value = formatFunc(record[key], record);
    }

    if (typeof render == "function") {
      cell = render(value, record, rowIndex);
    } else {
      cell = value;
    }
  }

  let width = size[key] ?? 200;

  if (width > scrollbarSize && isLastEl) {
    width -= scrollbarSize;
  }
  const cellStyle: React.CSSProperties = { width };

  const tdElement = (
    <div
      key={`el.${key ?? String(dataIndex)}`}
      style={cellStyle}
      onClick={(e) => (isLastEl ? e.stopPropagation() : null)}
      className={classNames("rc-table-cell rw-cell", {
        "rw-cell--right": align === "right",
      })}
    >
      {cell}
    </div>
  );

  return tdElement;
};

/* minimum number of rows to be loaded at a time */
const MINIMUM_BATCH_SIZE = 100;
/* data will start loading when a user scrolls within {threshold | 15} rows */
const THRESHOLD = Math.round(MINIMUM_BATCH_SIZE * 0.33);

export const TableComponent = <T extends object>(props: ITable<T>) => {
  const {
    title,
    data,
    dataKey,
    columns: prevColumns,
    dataError,
    isLoading,
    showTooltip,
    tableHeight = 500,
    selectedRowId,
    ToolbarComponent,
    onRowClick,
  } = props;

  const { totalCount, loadMoreData } = props;

  const [columns, tableWrapperRef, size, tableWidth] =
    useColumnsSize(prevColumns);

  const renderVirtualList: CustomizeScrollBody<any> = (
    rawData,
    { scrollbarSize }
  ) => {
    const Row = ({
      index: rowIndex,
      style: rowStyle,
    }: ListChildComponentProps) => {
      const record = rawData[rowIndex];

      const cellElements = flattenColumns(columns).map(
        (colCfg, colIndex, clmns) => {
          const isLastEl = colIndex === clmns.length - 1;
          const tdElement = Cell(
            colCfg,
            rowIndex,
            record,
            size,
            scrollbarSize,
            isLastEl
          );

          if (!showTooltip || isLastEl) {
            return tdElement;
          }

          return (
            <RcTooltip
              key={`c.${colIndex}`}
              placement="bottom"
              overlayClassName="d-Panel"
              overlayInnerStyle={{ border: "none" }}
              overlay={() => (
                <div data-testid="p-table-row-tooltip">
                  <EntityToolTip type={record.type} dataId={record.id} />
                </div>
              )}
            >
              {tdElement}
            </RcTooltip>
          );
        }
      );

      const internalStyle = {
        cursor: showTooltip ? "pointer" : "auto",
      };

      const selected = record[dataKey] === selectedRowId;
      const onClick = onRowClick ? () => onRowClick(record) : undefined;
      return (
        <div
          key={`r.${record[dataKey]}`}
          style={{ ...rowStyle, ...internalStyle }}
          data-testid="p-table-row"
          onClick={onClick}
          className={classNames("rc-table-row rw-row", {
            "rc-table-row__selected": selected,
          })}
        >
          {cellElements}
        </div>
      );
    };

    const isInfinite =
      typeof totalCount == "number" && typeof loadMoreData === "function";

    const tableContainerWidth =
      tableWidth ?? tableWrapperRef?.current?.offsetWidth;

    if (isInfinite) {
      return (
        <InfiniteLoader
          isItemLoaded={(index) => data[index] != null}
          itemCount={totalCount}
          loadMoreItems={loadMoreData}
          minimumBatchSize={MINIMUM_BATCH_SIZE}
          threshold={THRESHOLD}
        >
          {({ onItemsRendered, ref }) => {
            return (
              <List
                /* */
                onItemsRendered={onItemsRendered}
                ref={ref}
                /* */
                height={data.length ? tableHeight : 0}
                itemCount={data.length}
                itemSize={50}
                width={tableContainerWidth!}
              >
                {Row}
              </List>
            );
          }}
        </InfiniteLoader>
      );
    } else {
      return (
        <List
          height={data.length ? tableHeight : 0}
          itemCount={data.length}
          itemSize={50}
          width={tableContainerWidth!}
        >
          {Row}
        </List>
      );
    }
  };

  const componentsVirtual = {
    body: renderVirtualList,
  };

  const Toolbar: any = ToolbarComponent ?? ExportTableToolbar;

  return (
    <div ref={tableWrapperRef}>
      {title && <Title title={title} />}
      <Toolbar {...props} />
      <RcTable
        data={data}
        columns={columns as any}
        className="p-table"
        data-testid="p-table"
        scroll={{
          y: tableHeight,
        }}
        rowKey={(row: any) => row[dataKey]}
        emptyText={null}
        components={componentsVirtual}
      />
      <TablePlaceholders
        loading={isLoading}
        error={dataError!}
        noData={!data.length}
      />
    </div>
  );
};

export default TableComponent;
