import { FinanceReportingData } from "api/domains/chargingStations/types";
import { Select } from "components";
import { ISelectOption } from "components/Select/Select";
import { ITable } from "components/Table";
import { CSVExportButtonContainer } from "components/Table/CSVExportButtonContainer";
import { useMemo } from "react";
import { FinanceReportingFilter } from "./types";

export const RFID_ALL = "__all__";

export const useFinanceReportingToolbar = (
  tableData: FinanceReportingData[],
  filter: FinanceReportingFilter,
  setFilter: (filter: Partial<FinanceReportingFilter>) => void
) => {
  const rfids = useMemo(() => {
    const uniqueAuthIds = tableData
      .map((t) => t.authId)
      .filter((v, index, arr) => arr.indexOf(v) === index);
    let options: ISelectOption[] = uniqueAuthIds.map((value) => ({
      value,
      label: value,
    }));
    options = options.sort((l, r) => {
      return l.label.toLowerCase().localeCompare(r.label.toLowerCase());
    });
    options.unshift({ value: RFID_ALL, label: "All" });
    return options;
  }, [tableData]);

  const ToolbarFactory = useMemo(() => {
    const Toolbar: React.FC<ITable<any>> = () => {
      const premises: ISelectOption[] = [
        { value: "Both", label: "Both" },
        { value: "Business", label: "Business" },
        { value: "Home", label: "Home" },
      ];

      const rfid = rfids.find((x) => x.value === filter.rfid);
      const premise = premises.find((x) => x.value === filter.premise);

      return (
        <div className="p-table-toolbar">
          <div className="p-table-toolbar left">
            <Select
              options={rfids}
              value={rfid!}
              showIcon={false}
              label={"RFID"}
              placeholder=""
              onSelectChange={(el) => {
                setFilter({ rfid: el.value as string });
              }}
              isClearable={false}
              data-testid="sites-dropdown"
            />
            <Select
              options={premises}
              value={premise!}
              showIcon={false}
              label={"Premise"}
              placeholder=""
              onSelectChange={(el) => {
                setFilter({ premise: el.value as any });
              }}
              isClearable={false}
              data-testid="sites-dropdown"
            />
          </div>
          <div className="p-table-toolbar right">
            <CSVExportButtonContainer />
          </div>
        </div>
      );
    };

    return Toolbar;
  }, [rfids, filter, setFilter]);

  return ToolbarFactory;
};

export default useFinanceReportingToolbar;
