import paths from "appRoutes/paths";
import strings from "assets/strings";
import { BackLink } from "components";
import { TableLocalSorting } from "components/Table";
import { Container } from "drax-design-system";
import { ParamOptions } from "hooks/useQuery";
import { useReportNavigation } from "hooks/useReportNavigation";
import TableConfig from "pages/Reports/configs";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { getFinanceReportingData } from "store/slices/reports";
import { dateOnlyParameter } from "utils/queryUtils";
import DatePicker, {
  DatePickerModes
} from "../../../EnergyConsumption/components/DatePicker";
import TotalCalculations from "./TotalCalculations/TotalCalculations";
import { FinanceReportingFilter } from "./types";
import useFinanceReportingToolbar, {
  RFID_ALL
} from "./useFinanceReportingToolbar";

const parser: ParamOptions<FinanceReportingFilter> = {
  month: dateOnlyParameter,
};

const FinanceReporting: FunctionComponent = () => {
  const dispatch = useDispatch();

  const today = new Date();
  const defaultMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const [filter, setFilter] = useReportNavigation<FinanceReportingFilter>(
    { rfid: RFID_ALL, premise: "Both", month: defaultMonth },
    parser
  );

  const date = filter.month;

  const finances = useSelector(
    (state: RootState) => state.reports.financesData
  );
  const financeError = useSelector(
    (state: RootState) => state.reports.financeError
  );
  const data = finances ?? [];

  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getFinanceReportingData(date));
      setLoading(false);
    };
    fetchData();
  }, [dispatch, date?.toISOString()]);

  const FinanceReportingToolbar = useFinanceReportingToolbar(
    data,
    filter,
    setFilter
  );

  const tableData = useMemo(() => {
    return data.filter((x) => {
      const d1 = filter.rfid === RFID_ALL ? true : x.authId === filter.rfid;
      const d2 =
        filter.premise === "Both" ? true : x.premise === filter.premise;
      return d1 && d2;
    });
  }, [data, filter]);

  const showTotal = !financeError && tableData.length > 0;

  return (
    <Container layout="maxWidth">
      <div className="p-report-controls">
        <div className="p-report-controls__left">
          <BackLink linkUrl={paths.reports} />
          <DatePicker
            selectedDate={date}
            onFilterApply={(date) => {
              const month = new Date(
                Date.UTC(date.getFullYear(), date.getMonth(), 1)
              );
              setFilter({ month, rfid: RFID_ALL });
            }}
            singleMode={DatePickerModes.MONTH}
          />
        </div>
      </div>
      <TableLocalSorting
        dataKey="id"
        columns={TableConfig.FinanceReporting}
        data={tableData}
        title={strings.reports.allReports.financeReporting}
        dataError={financeError}
        isLoading={isLoading}
        defaultSortColumn={"chargingStationId"}
        showTooltip={false}
        tableHeight={450}
        showExportDataCount={true}
        ToolbarComponent={FinanceReportingToolbar}
      />
      {showTotal && <TotalCalculations data={tableData} />}
    </Container>
  );
};

export default FinanceReporting;
