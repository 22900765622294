import paths from "appRoutes/paths";
import { ChargeStation } from "assets/images/svgs";
import strings from "assets/strings";
import classnames from "classnames";
import { IconButton, If, Loader } from "components";
import { Grid, Icons, Panel, Text, TextItem } from "drax-design-system";
import { useChargingStationDetails } from "hooks/useChargingStationDetails";
import FilterError from "pages/Dashboard/components/FilterMenu/FilterSections/FilterError";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import { getWarrantyValue, isWarrantyElapsed } from "utils/warrantyDateUtils";
import PortInfo from "./PortInfo";

interface StationInfoProps {
  stationId: string;
  onBack: () => void;
}

const StationInfo: FunctionComponent<StationInfoProps> = ({
  stationId,
  onBack,
}) => {
  const navigate = useNavigate();

  const {
    api: {
      errors: { generic },
    },
    dashboard: {
      infoPanel: { chargeStation },
      table: { warranty },
    },
  } = strings;
  const { station, details, ports } = useChargingStationDetails(stationId);

  if (station == null && details?.data == null) {
    return null;
  }

  const {
    name,
    address: { addressLine, city, country, postalCode },
    loadManagement,
    averageUtilisation, // from details
    warrantyExpiry,
  } = { ...station!, ...details?.data };

  const isLoading = details?.isLoading ?? true;
  const showLoading = isLoading && details?.data?.ports?.length == null;
  const showError = !isLoading && details?.error != null;

  return (
    <Panel
      className={`${gtmTags.stationPanel} p-info-panel`}
      data-testid="charge-station-info-panel"
    >
      <If value={showLoading}>
        <div className=" p-info-panel__backdrop" />
        <Loader />
      </If>
      <IconButton
        text="Back"
        onClick={onBack}
        className={gtmTags.stationPanelBack}
        icon={
          <Icons.IcChevronLeft
            height="25px"
            className={gtmTags.combustionVehiclePanelBack}
          />
        }
        data-testid="back-button"
      />

      <Grid template="1fr">
        <div className="p-info-panel__title">
          <ChargeStation className="p-info-panel__title-icon" />
          <Text size="h3" textColor="tertiary">
            {name}
          </Text>
        </div>

        <Grid className="p-info-panel__section" template="1fr">
          {ports.map((port, i) => (
            <PortInfo key={port.id} port={port} index={i} />
          ))}
        </Grid>

        <If value={showError}>
          <FilterError error={generic.unableToGet} />
        </If>
        <Grid className="p-info-panel__section" template="1fr">
          <Grid
            template="1fr"
            gap="var(--spacing-gap-xxs)"
            data-testid="av-utilisation-section"
          >
            <TextItem
              title={chargeStation.avUtil}
              value={
                averageUtilisation != null
                  ? `${Math.round(averageUtilisation)}%`
                  : "—"
              }
              className="p-info-panel__item"
            />
          </Grid>
          <Grid
            template="1fr"
            gap="var(--spacing-gap-xxs)"
            data-testid="address-section"
          >
            <TextItem
              title={chargeStation.address}
              value={[addressLine, city, country, postalCode]}
              className="p-info-panel__item"
            />
          </Grid>
          <Grid
            template="1fr"
            gap="var(--spacing-gap-xxs)"
            data-testid="load-management-section"
          >
            <TextItem
              title={chargeStation.loadingManagement}
              value={[loadManagement]}
              className="p-info-panel__item"
            />
          </Grid>
          <Grid
            template="1fr"
            gap="var(--spacing-gap-xxs)"
            data-testid="load-management-section"
          >
            <TextItem
              title={warranty}
              value={getWarrantyValue(warrantyExpiry)}
              className={classnames("p-info-panel__item", {
                "p-info-panel__item--error": isWarrantyElapsed(warrantyExpiry),
              })}
            />
          </Grid>
        </Grid>

        <IconButton
          text={chargeStation.reports}
          onClick={() => navigate(paths.reports)}
          className={gtmTags.stationReportsButton}
          data-testid="reports-button"
          icon={
            <Icons.IcChevronRight
              height="25px"
              className={gtmTags.vehicleJourniesReport}
            />
          }
          iconAlign="right"
        />
      </Grid>
    </Panel>
  );
};

export default StationInfo;
