export const setThisYearDates = (
  setStartDate: React.Dispatch<Date>,
  setEndDate: React.Dispatch<Date>
) => {
  setStartDate(new Date(new Date().getFullYear(), 0, 1));
  setEndDate(new Date());
};

export const getThisYearDates = (): [Date, Date] => {
  return [new Date(new Date().getFullYear(), 0, 1), new Date()];
};

export const setThisMonthDates = (
  setStartDate: React.Dispatch<Date>,
  setEndDate: React.Dispatch<Date>
) => {
  setStartDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  setEndDate(new Date());
};

export const getThisMonthDates = (): [Date, Date] => {
  return [
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    new Date(),
  ];
};

export const setThisQuarterDates = (
  setStartDate: React.Dispatch<Date>,
  setEndDate: React.Dispatch<Date>
) => {
  const quarter = Math.floor(new Date().getMonth() / 3);
  setStartDate(new Date(new Date().getFullYear(), quarter * 3, 1));
  setEndDate(new Date());
};

export const getThisQuarterDates = (): [Date, Date] => {
  const quarter = Math.floor(new Date().getMonth() / 3);
  return [new Date(new Date().getFullYear(), quarter * 3, 1), new Date()];
};
