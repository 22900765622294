import { ExportButton } from "components";
import { RefObject } from "react";
import "../styles.scss";

interface IChartControls {
  buttonRef: RefObject<HTMLDivElement>;
  exportData: Array<any>;
  isPdf: boolean;
  setIsPdf: (value: boolean) => void;
  showExport: boolean;
}

const ChartControls: React.FC<IChartControls> = ({
  buttonRef,
  isPdf,
  setIsPdf,
  children,
  exportData,
  showExport,
}) => (
  <div
    className="p-report-controls"
    data-testid="energy-consumption-controls-section"
  >
    {children}
    {showExport && (
      <ExportButton
        refer={buttonRef}
        data={exportData}
        isPdf={isPdf}
        setIsPdf={setIsPdf}
      />
    )}
  </div>
);

export default ChartControls;
