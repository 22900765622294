import { mapStyles } from "./styles";

export const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

export const mapOptions = {
  styles: mapStyles,
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
  maxZoom: 20,
};
