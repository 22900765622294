import { useBffUser, UserClaims } from "hooks/useBffUser";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
const runtimeConfig: any = (window as any).runtimeEnv ?? {};

export interface IUserInfo {
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  orgId: string;
  roles: Array<string>;
  sso: boolean;
  sessionExpiresIn: number;
  sessionTimeout: number;
}

const mapUser = (userClaims: Partial<UserClaims>): IUserInfo => {
  const {
    name,
    email,
    given_name,
    family_name,
    org_id,
    role,
    amr,
    "bff:session_expires_in": timeout,
    "bff:session_timeout": sessionTimeout,
  } = userClaims;

  const orgId =
    typeof org_id == "string" && org_id.trim().length > 0 ? org_id.trim() : "";

  let roles: string[] = [];
  if (Array.isArray(role)) {
    roles = role;
  } else if (typeof role == "string") {
    roles = [role];
  }

  const amrClaim =
    typeof amr == "string" && amr.trim().length > 0 ? amr.trim() : "";

  let sso: boolean = amrClaim.toLowerCase() == "external";

  return {
    name: name ?? "",
    firstName: given_name ?? "",
    lastName: family_name ?? "",
    email: email ?? "",
    orgId,
    roles,
    sso,
    sessionExpiresIn: timeout ?? 0,
    sessionTimeout: sessionTimeout ?? 0,
  };
};

export interface AuthenticationContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  user: IUserInfo | null;
  error: any;
  login: (returnUrl?: string) => void;
  logout: () => void;
  changePwd: () => void;
}

const AuthenticationContext =
  React.createContext<AuthenticationContextType | null>(null);

export const AuthenticationContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const state = useBffUser();
  const { isLoggedIn, logoutUrl, isLoading, error, user: userClaims } = state;

  const user = useMemo(() => {
    return userClaims != null ? mapUser(userClaims) : null;
  }, [userClaims]);

  const login = useCallback((returnUrl: string = "/") => {
    window.location.href = "/bff/login?returnUrl=" + returnUrl;
  }, []);

  const logout = useCallback(() => {
    if (logoutUrl != null) {
      window.location.href = logoutUrl;
    }
  }, [logoutUrl]);

  const changePwd = useCallback(() => {
    window.location.href =
      runtimeConfig.REACT_APP_AUTH_AUTHORITY +
      "/manage/changePassword?returnUrl=" +
      window.location.origin;
  }, []);

  const value = useMemo(
    () => ({
      isLoggedIn,
      user,
      isLoading,
      login,
      logout,
      changePwd,
      error,
    }),
    [isLoggedIn, isLoading, login, logout, changePwd, user, error]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const data = useContext(AuthenticationContext);

  if (data == null) {
    throw new Error(
      "Use <AuthenticationContext.Provider>>... as parent component"
    );
  }

  return data;
};
