import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./initialAppState";
import { IAppState, IInitialisedPayload, ITestDataPayload } from "./types";

const appState = createSlice({
  name: "appState",
  initialState: initialState,
  reducers: {
    setInitialised(
      state: IAppState,
      { payload: { initialised } }: PayloadAction<IInitialisedPayload>
    ) {
      state.initialised = initialised;
    },
    setTestData(
      state: IAppState,
      { payload: { testData } }: PayloadAction<ITestDataPayload>
    ) {
      state.testData = testData;
    },
  },
});

export const { setInitialised, setTestData } = appState.actions;

export default appState;
