import { ChargeStationStatus } from "store/slices/chargestations/types";

export const genChargeStationColor = (s: ChargeStationStatus) => {
  switch (s) {
    case ChargeStationStatus.AVAILABLE:
      return "green";
    case ChargeStationStatus.CHARGING:
    case ChargeStationStatus.RESERVED:
      return "blue";
    case ChargeStationStatus.BLOCKED:
    case ChargeStationStatus.PLANNED:
      return "yellow";
    case ChargeStationStatus.INOPERATIVE:
    case ChargeStationStatus.OUTOFORDER:
      return "red";
    default:
      return "gray";
  }
};
