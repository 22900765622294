import InformationTooltip from "components/InformationTooltip";
import "./styles.scss";

export const TotalSavingsTooltipEV = () => {
  return (
    <InformationTooltip
      title={"Money saved"}
      description={
        <>
          <p>
            The difference between the{" "}
            <b>cost of charging EVs and the cost of fueling diesel vehicles</b>{" "}
            over the number of miles the EVs have travelled.
          </p>
          <div className="explanation-list">
            Calculations based on:
            <ul>
              <li>
                Average electricity tariff calculated from Drax current fixed
                tariffs
              </li>
              <li>
                National UK average price of diesel taken annually from{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/government/statistical-data-sets/oil-and-petroleum-products-monthly-statistics"
                  rel="noreferrer"
                >
                  https://www.gov.uk/government/statistical-data-sets/oil-and-petroleum-products-monthly-statistics
                </a>
              </li>
              <li>
                Vehicle used to calculate fuel costs - Nissan NV200 Combi 1.5
                Acenta
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};

export const TotalSavingsTooltipCS = () => {
  return (
    <InformationTooltip
      title={"Money saved"}
      description={
        <>
          <p>
            The difference between the{" "}
            <b>cost of charging EVs and the cost of fueling diesel vehicles</b>{" "}
            over the number of miles the EVs have travelled. (Estimated using an
            average m/kWh based on your energy delivered)
          </p>
          <div className="explanation-list">
            Calculations based on:
            <ul>
              <li>
                Average electricity tariff calculated from Drax current fixed
                tariffs
              </li>
              <li>
                National UK average price of diesel taken annually from{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/government/statistical-data-sets/oil-and-petroleum-products-monthly-statistics"
                  rel="noreferrer"
                >
                  https://www.gov.uk/government/statistical-data-sets/oil-and-petroleum-products-monthly-statistics
                </a>
              </li>
              <li>
                Vehicle used to calculate fuel costs - Nissan NV200 Combi 1.5
                Acenta
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};
