import config from "config/app";
import TagManager, { DataLayerArgs, TagManagerArgs } from "react-gtm-module";

interface ITagManager {
  dataLayer: (datalayerArgs: DataLayerArgs) => void;
  initialize: (tagManagerArgs: TagManagerArgs) => void;
}

interface IAnalyticsManager {
  initialiseAnalytics: () => void;
}

class AnalyticsManager implements IAnalyticsManager {
  tagManager: ITagManager;

  constructor() {
    this.tagManager = TagManager;
  }

  public initialiseAnalytics() {
    const args = {
      gtmId: config.googleTagContainerId,
      dataLayerName: "evPortal",
    };
    if (config.googleTagContainerId) {
      TagManager.initialize(args);
    }
  }
}

const analyticsManager = new AnalyticsManager();

export default analyticsManager;
