import { CombustionCar, CombustionCarFaulty } from "assets/images/svgs";
import strings from "assets/strings";
import { IconButton } from "components";
import { Badge, Grid, Icons, Text, TextItem } from "drax-design-system";
import React from "react";
import gtmTags from "services/Analytics/GtmTags";
import { IVehicleDetailView, VehicleStatus } from "store/slices/vehicles/types";
import { generateBadge } from "utils/vehicleBadgeHelper";
import "./styles.scss";

export interface CombustionVehicleInfoPanelProps {
  vehicleDetails: IVehicleDetailView;
  openJourneys: () => void;
}

export const CombustionVehicleInfoPanel: React.FC<CombustionVehicleInfoPanelProps> =
  ({ openJourneys, vehicleDetails }) => {
    const {
      dashboard: {
        infoPanel: { vehiclePanel },
      },
    } = strings;

    const {
      id,
      imei,
      status,
      statuses,
      totalMileage = 0,
      registeredAddress,
    } = vehicleDetails;
    const badge = generateBadge(status);

    return (
      <Grid template="1fr" gap="var(--spacing-gap-m)">
        <div>
          <div className="p-info-panel__title">
            {statuses.includes(VehicleStatus.Fault) ? (
              <CombustionCarFaulty className="p-info-panel__title-icon" />
            ) : (
              <CombustionCar className="p-info-panel__title-icon" />
            )}
            <Text size="h3" textColor="tertiary">
              {id}
            </Text>
          </div>
          {badge !== null && (
            <Badge
              title={badge.text}
              appearance="secondary"
              color={badge.color}
            />
          )}
        </div>
        <Grid template="1fr">
          <div data-testid="total-mileage">
            <TextItem
              title={vehiclePanel.totalMileage}
              value={`${totalMileage?.toFixed(1) || 0}`}
            />
          </div>
          <div data-testid="imei">
            <TextItem title={vehiclePanel.imei} value={imei} />
          </div>
          <div data-testid="address">
            <TextItem
              title={vehiclePanel.address}
              value={[
                registeredAddress.addressLine,
                registeredAddress.city,
                registeredAddress.postalCode,
              ]}
            />
          </div>
        </Grid>
        <IconButton
          text={vehiclePanel.journeys}
          onClick={openJourneys}
          className={gtmTags.vehicleJourniesButton}
          data-testid="vehicle-journeys-button"
          icon={
            <Icons.IcChevronRight
              height="25px"
              className={gtmTags.vehicleJourniesReport}
            />
          }
          iconAlign="right"
        />
      </Grid>
    );
  };
