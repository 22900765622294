import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { IPerformancePayload, IVehicle } from "api/domains/vehicles/types";
import { RootState } from "store";
import { AppThunk } from "store/types";
import { initialState } from "./initialVehiclesState";
import {
  ISingleVehicleDataPayload,
  IVehicleDataState,
  IVehiclesDataPayload,
  IVehiclesError,
} from "./types";

// ----------------------------
// Export our actions here
// ----------------------------

const vehicleData = createSlice({
  name: "vehicleData",
  initialState: initialState,
  reducers: {
    setVehicles(
      state: IVehicleDataState,
      { payload: { vehicles } }: PayloadAction<IVehiclesDataPayload>
    ) {
      state.vehicles = vehicles;
      state.wasInitialLoad = true;
    },
    setVehicleDetails(
      state: IVehicleDataState,
      { payload: { data } }: PayloadAction<ISingleVehicleDataPayload>
    ) {
      if (data != null) {
        const vehicleId = data.id;
        state.details[vehicleId] = data;
      }
      return state;
    },
    setPerformance(
      state: IVehicleDataState,
      { payload: { ecoSafetyScore } }: PayloadAction<IPerformancePayload>
    ) {
      state.eVPerformance.ecoSafetyScore = ecoSafetyScore;
    },
    setVehiclesError(
      state: IVehicleDataState,
      { payload: { vehiclesError } }: PayloadAction<IVehiclesError>
    ) {
      state.vehiclesError = vehiclesError;
    },
    resetVehicles(state: IVehicleDataState) {
      return initialState;
    },
  },
});

export const {
  setVehicles,
  setVehicleDetails,
  setPerformance,
  setVehiclesError,
  resetVehicles,
} = vehicleData.actions;
export default vehicleData;

export const getVehicles = (): AppThunk => async (dispatch) => {
  try {
    const vehicles =
      (await api.domains.Vehicles.getAllVehicles()) as IVehicle[];
    await dispatch(setVehicles({ vehicles }));
  } catch (e) {
    dispatch(setVehiclesError({ vehiclesError: true }));
  }
};

// Returns Information for Single Vehicle in more detail
export const getVehicleDetails =
  (vehicleId: string): AppThunk =>
  async (dispatch) => {
    const vehicle = await api.domains.Vehicles.getVehicle(vehicleId);
    dispatch(setVehicleDetails({ data: vehicle }));
  };

export const getEvPerformanceMetrics = (): AppThunk => async (dispatch) => {
  try {
    const resp =
      (await api.domains.Vehicles.getEVPerformanceMetrics()) as IPerformancePayload;
    if (resp.ecoSafetyScore) dispatch(setPerformance(resp));
  } catch (e) {
    dispatch(setVehiclesError({ vehiclesError: true }));
  }
};

export const selectVehicles = (state: RootState) => state.vehicles.vehicles;

export const selectVehiclesError = (state: RootState) =>
  state.vehicles.vehiclesError;

export const selectElectricVehiclePerformance = (state: RootState) =>
  state.vehicles.eVPerformance;
