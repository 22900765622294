import axios from "axios";
import { useEffect, useState } from "react";

export interface UserClaims {
  sub: string;
  email: string;
  name: string;
  family_name: string;
  given_name: string;
  org_id: string;
  role: Array<string>;
  ["bff:logout_url"]: string;
  ["bff:session_expires_in"]: number;
  ["bff:session_timeout"]: number;
  amr: string;
}

type Claims = Array<{ type: string; value: string }>;

const mapClaimsToUser = (claims: Claims) => {
  const user: Partial<UserClaims> = claims?.reduce((agg, curr) => {
    const { type, value } = curr;
    if (agg[type] == null) {
      agg[type] = value;
    } else if (agg[type] != null) {
      if (Array.isArray(agg[type])) {
        agg[type].push(value);
      } else {
        agg[type] = [agg[type], value];
      }
    }

    return agg;
  }, {} as Record<string, any>);

  return user;
};

export interface AuthUserState {
  user: Partial<UserClaims> | null;
  logoutUrl: string | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  error: any;
}

export type FetchBffUser = (
  slide: boolean
) => Promise<Partial<UserClaims> | null>;

export const fetchBffUser: FetchBffUser = (slide) => {
  let url = "/bff/user";
  if (slide === false) {
    url += "?slide=false";
  }
  return axios
    .get(url, {
      headers: {
        "X-CSRF": "1",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    })
    .then((res) => res.data)
    .then((result) => {
      const claims: Claims = Array.isArray(result) ? result : [];
      const user = claims.length > 0 ? mapClaimsToUser(claims) : null;
      return user;
    });
};

export const useBffUser = (slide: boolean = true): AuthUserState => {
  const [state, setState] = useState<AuthUserState>({
    user: null as Partial<UserClaims> | null,
    logoutUrl: "",
    isLoading: true,
    isLoggedIn: false,
    error: null,
  });

  useEffect(() => {
    fetchBffUser(slide)
      .then((user) => {
        const logoutUrl = user?.["bff:logout_url"] ?? null;
        const isLoggedIn = typeof logoutUrl === "string";
        const error: any = isLoggedIn ? null : new Error("No user found");
        setState({ isLoading: false, isLoggedIn, logoutUrl, error, user });
      })
      .catch((err) => {
        const isUnauthorized = err?.response?.status === 401;
        const error = isUnauthorized ? null : err;
        setState({
          isLoading: false,
          isLoggedIn: false,
          logoutUrl: null,
          user: null,
          error,
        });
      });
  }, [slide]);

  return state;
};
