import classNames from "classnames";
import { Grid, Text } from "drax-design-system";
import { FunctionComponent } from "react";
import { ChartTypeKeys, ChartTypesObj } from "../types";

// TODO: Get datasets strongly typed
interface ILegend {
  datasets: any[];
  type: ChartTypeKeys;
  labels?: any[];
  disableIcons?: boolean;
}

const Legend: FunctionComponent<
  ILegend & { toggleDataset?: (index: number) => void }
> = ({ datasets, type, labels, disableIcons, toggleDataset }) => {
  return (
    <Grid template="1fr" data-testid="legend-section">
      {type === ChartTypesObj.bar &&
        datasets
          .filter((dataset) => dataset.showLabel)
          .map((dataset, index) => {
            const DatasetIcon = dataset.hidden
              ? dataset.hideIcon
              : dataset.showIcon;
            return (
              <Grid
                template="16px 1fr 16px"
                key={index}
                data-testid={`${dataset.label
                  .toLowerCase()
                  .replace(/\s/g, "-")}`}
              >
                <>
                  {dataset.legendIcon ? (
                    <dataset.legendIcon />
                  ) : (
                    <div
                      style={{ backgroundColor: dataset.borderColor }}
                      className="p-legend-block"
                    />
                  )}
                  <Text textColor="gray">{dataset.label}</Text>
                  <DatasetIcon
                    className={classNames("p-legend-block__icon", {
                      "p-legend-block__icon--disabled": disableIcons,
                    })}
                    width="24px"
                    onClick={() =>
                      toggleDataset?.(index) || dataset.toggleDataset?.(dataset)
                    }
                    data-testid={`${
                      dataset.hidden ? "legend-hide-icon" : "legend-show-icon"
                    }`}
                  />
                </>
              </Grid>
            );
          })}
      {type === ChartTypesObj.doughnut &&
        labels?.map((label, index) => (
          <Grid template="16px 1fr" key={index}>
            <div
              style={{ backgroundColor: datasets[0].backgroundColor[index] }}
              className="p-legend-block"
            ></div>
            <Text textColor="gray">{label ? label : ""}</Text>
          </Grid>
        ))}
    </Grid>
  );
};

export default Legend;
