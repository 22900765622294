import { addDays } from "date-fns";
import { IChartDateFilter } from "../../types";

// weekStartsOn  0 = Sunday, 1 = Monday...
export const getWeekStartDate = (weekDate: Date, weekStartsOn: number = 1) => {
  const date = new Date(weekDate);
  const day = date.getDay();
  const diff = (day < weekStartsOn ? 7 : 0) + day - weekStartsOn;
  date.setDate(date.getDate() - diff);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const getDayDates = (newDate: Date): IChartDateFilter => {
  const startDate = new Date(newDate);
  const endDate = new Date(newDate);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return { startDate, endDate };
};

export const getWeekDates = (newDate: Date): IChartDateFilter => {
  const startDate = getWeekStartDate(newDate);
  const endDate = addDays(startDate, 6);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return { startDate, endDate };
};

export const getMonthDates = (newDate: Date): IChartDateFilter => {
  const date = new Date(newDate);
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return { startDate, endDate };
};

export const getYearDates = (newDate: Date): IChartDateFilter => {
  const date = new Date(newDate);
  const startDate = new Date(date.getFullYear(), 0, 1);
  const endDate = new Date(date.getFullYear(), 12, 0);
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);
  return { startDate, endDate };
};
