import { IChargingStation } from "api/domains/chargingStations/types";
import {
  chargeStation,
  chargeStation1Car,
  chargeStation1CarFocused,
  chargeStation2Car,
  chargeStation2CarFocused,
  chargeStationFocused,
} from "assets/images/svgs/";
import { MapMarker } from "components";
import { FunctionComponent } from "react";
import {
  ChargeStation,
  ChargeStationStatus,
} from "store/slices/chargestations/types";
import EntityToolTip from "../EntityToolTip";

export interface ChargeStationMarkersProps {
  stationId: string | null;
  onSelectStation: (id: string) => void;
  stations: IChargingStation[];
}

const ChargeStationMarkers: FunctionComponent<ChargeStationMarkersProps> = ({
  stationId,
  stations,
  onSelectStation,
}) => {
  const getPinIcon = (st: IChargingStation) => {
    const numCharging = st.ports.filter(
      ({ status }) => status === ChargeStationStatus.CHARGING
    ).length;

    switch (numCharging) {
      case 0:
        return stationId === st.id ? chargeStationFocused : chargeStation;
      case 1:
        return stationId === st.id
          ? chargeStation1CarFocused
          : chargeStation1Car;
      case 2:
        return stationId === st.id
          ? chargeStation2CarFocused
          : chargeStation2Car;
      default:
        return chargeStation;
    }
  };

  return (
    <>
      {stations.map((st) => (
        <MapMarker
          key={st.id}
          id={st.id}
          zIndex={
            st.id === stationId ? google.maps.Marker.MAX_ZINDEX : undefined
          }
          icon={getPinIcon(st)}
          position={{
            lat: st.coordinates.latitude,
            lng: st.coordinates.longitude,
          }}
          onClick={() => onSelectStation(st.id)}
          hoverComponent={
            <EntityToolTip type={ChargeStation.Type} dataId={st.id} />
          }
        />
      ))}
    </>
  );
};

export default ChargeStationMarkers;
