import classNames from "classnames";
import { ExportContextProvider } from "contexts/ExportContext";
import { Checkbox } from "drax-design-system";
import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { ICSVConfig, ITable } from "./types";

export interface ExportSelectionUnControlled<T = object> {
  enableSelectAll?: boolean;
  tableCSVConfig?: Array<ICSVConfig<T>>;
  showExportDataCount?: boolean;
}

export interface ExportSelectionControlled<T = object>
  extends ExportSelectionUnControlled<T> {
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
}

export type ExportSelectionProps<T> =
  | ExportSelectionControlled<T>
  | ExportSelectionUnControlled<T>;

export const withExportSelection =
  <T extends object>(Component: React.ComponentType<ITable<T>>) =>
  (props: ITable<T> & ExportSelectionProps<T>) => {
    const { columns: prevColumns, data, dataKey } = props;
    const { enableSelectAll = true } = props;
    const { selectedIds, setSelectedIds } =
      props as ExportSelectionControlled<T>;

    const [localSelectedIds, setLocalSelectedIds] = useState<string[]>(
      selectedIds ?? []
    );
    const [isSelectedAllData, setSelectedAllData] = useState<boolean>(false);

    useEffect(() => {
      if (selectedIds == null) {
        return;
      }
      setLocalSelectedIds((prev) => {
        if (prev === selectedIds) {
          return prev;
        }
        if (prev.length !== selectedIds.length) {
          return selectedIds;
        }
        const isEqual = selectedIds.every((x) => prev.includes(x));
        return isEqual ? prev : selectedIds;
      });
    }, [selectedIds, setLocalSelectedIds]);

    useEffect(() => {
      if (!enableSelectAll) {
        return;
      }
      let shouldSelectData =
        !!localSelectedIds.length && localSelectedIds.length === data.length;
      if (shouldSelectData) {
        shouldSelectData = data
          .map((row: any) => row[dataKey])
          .every((x) => localSelectedIds.includes(x));
      }
      setSelectedAllData(shouldSelectData);
      // eslint-disable-next-line
    }, [localSelectedIds, data]);

    useEffect(() => {
      if (typeof setSelectedIds == "function") {
        setSelectedIds(localSelectedIds);
      }
    }, [localSelectedIds, setSelectedIds]);

    const toggleSelectRow = (id: string) => {
      localSelectedIds.includes(id)
        ? setLocalSelectedIds(localSelectedIds.filter((f) => f !== id))
        : setLocalSelectedIds((prevSelectedData) => [...prevSelectedData, id]);
    };

    const selectAll = () => {
      setSelectedAllData((prev) => {
        if (prev) setLocalSelectedIds([]);
        else setLocalSelectedIds(data.map((row: any) => row[dataKey]));
        return !prev;
      });
    };

    const nextColumns = useMemo(() => {
      const renderCheckBox = (checked: boolean, handleChange: any) => {
        return (
          <Checkbox
            className={classNames("p-table-checkbox", {
              "p-table-checkbox--selected": checked,
            })}
            onChange={handleChange}
            checked={checked}
            label=""
          />
        );
      };

      const columnsWithActions = [...prevColumns];
      columnsWithActions.push({
        title: enableSelectAll
          ? renderCheckBox(isSelectedAllData, selectAll)
          : "Select",
        //@ts-ignore
        dataIndex: "__select_all__",
        //@ts-ignore
        key: "__select_all__",
        width: 50,
        align: "center",
        render: (value, record) =>
          renderCheckBox(
            //@ts-ignore
            localSelectedIds.includes(value?.[dataKey] ?? record?.[dataKey]),
            //@ts-ignore
            toggleSelectRow.bind(null, value?.[dataKey] ?? record?.[dataKey])
          ),
      });

      return columnsWithActions;
    }, [prevColumns, isSelectedAllData, toggleSelectRow, selectAll]);

    const { showExportDataCount, tableCSVConfig } = props;

    let csvConfig: ICSVConfig<T>[];

    if (tableCSVConfig && tableCSVConfig?.length > 0) {
      csvConfig = tableCSVConfig;
    } else {
      // @ts-ignore
      // TODO: Handle empty key cases
      csvConfig = prevColumns.map((x) => {
        return {
          key: x.key,
          label: x.title as string,
          formatFunc: x.formatFunc,
          exclude: x.exclude,
        };
      });
    }

    return (
      <ExportContextProvider
        csvConfig={csvConfig}
        data={data}
        dataKey={dataKey as string}
        selectedIds={localSelectedIds}
        showDataCount={showExportDataCount}
      >
        <Component {...props} columns={nextColumns} />
      </ExportContextProvider>
    );
  };
