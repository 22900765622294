import paths from "appRoutes/paths";
import { Avatar } from "assets/images/svgs";
import strings from "assets/strings";
import { If } from "components";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { Button, Grid, Heading, Text } from "drax-design-system";
import RcTooltip from "rc-tooltip";
import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import "./styles.scss";

const Profile: FunctionComponent<{ userName: string }> = ({ userName }) => {
  return (
    <RcTooltip
      trigger={["click"]}
      placement="bottomLeft"
      overlayClassName="d-Panel profile-user__tooltip-overlay"
      overlayInnerStyle={{ border: "none" }}
      overlay={ProfileOverlay}
    >
      <section className="profile-user">
        <Heading.h6 className="profile-user__name">{userName}</Heading.h6>
        <div style={{ display: "flex" }}>
          <Avatar />
        </div>
      </section>
    </RcTooltip>
  );
};

const ProfileOverlay: FunctionComponent = () => {
  const { profile } = strings;
  const navigate = useNavigate();
  const { logout, changePwd, user } = useAuthenticationContext();

  return (
    <Grid template="1fr">
      <Button
        appearance="primary"
        onClick={logout}
        title={profile.logOut}
        className={gtmTags.logOut}
      />

      <If value={!user?.sso}>
        <Button
          className={gtmTags.changePassword}
          appearance="secondary"
          title={profile.changePassword}
          onClick={changePwd}
        />
        <Text.div>
          {profile.requestLineOne}
          <Text.span
            textColor="primary"
            onClick={() => navigate(paths.support)}
          >
            {profile.submitRequest}
          </Text.span>
          {profile.requestLineTwo}
        </Text.div>
      </If>
    </Grid>
  );
};

export default Profile;
