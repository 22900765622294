import { Box, Grid, Icons, Text } from "drax-design-system";
import React, { FunctionComponent } from "react";

interface IFilterProps {
  error: string;
}

const FilterError: FunctionComponent<IFilterProps> = ({ error }) => (
  <Box className="p-filter-menu__error-box">
    <Grid template="30px 1fr">
      <Icons.IcDanger width="30px" />
      <Text>{error}</Text>
    </Grid>
  </Box>
);

export default FilterError;
