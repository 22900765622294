import Table from "./Table";
import { ITable } from "./types";
import {
  withExportSelection,
  ExportSelectionProps,
} from "./withExportSelection";
import { withSortingData, WithSortingDataProps } from "./withSortingData";
import {
  withSortingHandlers,
  WithSortingHandlersProps,
} from "./withSortingHandlers";

export type { ITable } from "./types";

export const TableLocalSorting: <T>(
  props: ITable<T> & WithSortingDataProps<T> & ExportSelectionProps<T>
) => JSX.Element = withExportSelection(withSortingData(Table) as any) as any;

export const TableRemoteSorting: <T>(
  props: ITable<T> & WithSortingHandlersProps<T> & ExportSelectionProps<T>
) => JSX.Element = withExportSelection(
  withSortingHandlers(Table) as any
) as any;

export default Table as <T>(props: ITable<T>) => JSX.Element;
