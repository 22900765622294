import { DatePickerCloseBtn } from "assets/images/svgs";
import strings from "assets/strings";
import classNames from "classnames";
import { subDays } from "date-fns";
import { Panel } from "drax-design-system";
import { SyntheticEvent, useMemo, useState } from "react";
import DayView from "./DatePickerDayView";
import MonthView from "./DatePickerMonthView";
import WeekView from "./DatePickerWeekView";
import YearView from "./DatePickerYearView";
import "./styles.scss";
import { DatePickerModes } from "./types";

export interface DatePickerOverlayProps {
  mode: DatePickerModes;
  allowModeChange: boolean;
  selectedDate: Date;
  onClose: () => void;
  onFilterApply: (date: Date, mode: DatePickerModes) => void;
}

export const DatePickerOverlay = (props: DatePickerOverlayProps) => {
  const { mode, selectedDate, allowModeChange } = props;
  const { onClose, onFilterApply } = props;

  const {
    reports: { datePicker },
    notificationStrings: { cancel },
  } = strings;

  const [localMode, setLocalMode] = useState(mode);
  const [localDate, setLocalDate] = useState(selectedDate);

  const isActiveMode = (checkingMode: DatePickerModes) =>
    checkingMode === localMode;

  // we are allowing to select max date today minus 2 days
  const maxDate = subDays(new Date(), 2);

  const printHeaderButtons = useMemo(() => {
    const buttons = [
      {
        mode: DatePickerModes.YEAR,
        text: datePicker.year,
      },
      {
        mode: DatePickerModes.MONTH,
        text: datePicker.month,
      },
      {
        mode: DatePickerModes.WEEK,
        text: datePicker.week,
      },
      {
        mode: DatePickerModes.DAY,
        text: datePicker.day,
      },
    ];
    return buttons.map(({ mode: dpMode, text }) => (
      <button
        key={dpMode}
        type="button"
        className={classNames("consumption-date-picker__header-button", {
          "consumption-date-picker__header-button--active":
            dpMode === localMode,
        })}
        onClick={() => setLocalMode(dpMode)}
        data-testid={`${text.toLowerCase()}-view-button`}
      >
        {text}
      </button>
    ));
  }, [datePicker, localMode]);

  return (
    <div
      className={classNames("consumption-date-picker__overlay", {
        "consumption-date-picker__overlay--open": true,
      })}
      onClick={(e: SyntheticEvent) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <Panel
        className={classNames("consumption-date-picker", {
          "consumption-date-picker__year": DatePickerModes.YEAR === localMode,
        })}
        data-testid="date-picker-section"
      >
        <div
          className="consumption-date-picker__header"
          data-testid="date-picker-header"
        >
          {allowModeChange && printHeaderButtons}
          <div
            onClick={onClose}
            className="consumption-date-picker__close"
            data-testid="close-button"
          >
            <DatePickerCloseBtn />
          </div>
        </div>
        <div
          className="consumption-date-picker__body"
          data-testid="date-picker-body"
        >
          {isActiveMode(DatePickerModes.DAY) && (
            <DayView
              selectedDate={localDate}
              onChange={setLocalDate}
              maxDate={maxDate}
            />
          )}
          {isActiveMode(DatePickerModes.WEEK) && (
            <WeekView
              selectedDate={localDate}
              onChange={setLocalDate}
              maxDate={maxDate}
            />
          )}
          {isActiveMode(DatePickerModes.MONTH) && (
            <MonthView
              selectedDate={localDate}
              onChange={setLocalDate}
              maxDate={maxDate}
            />
          )}
          {isActiveMode(DatePickerModes.YEAR) && (
            <YearView
              selectedDate={localDate}
              onChange={setLocalDate}
              maxDate={maxDate}
            />
          )}
        </div>
        <div
          className="consumption-date-picker__footer"
          data-testid="date-picker-footer"
        >
          <button
            type="button"
            className={"consumption-date-picker__header-button"}
            onClick={onClose}
            data-testid="cancel-button"
          >
            {cancel}
          </button>
          <button
            type="button"
            className={classNames(
              "consumption-date-picker__header-button",
              "consumption-date-picker__header-button--active"
            )}
            onClick={() => {
              onFilterApply(localDate, localMode);
              onClose();
            }}
            data-testid="apply-filter-button"
          >
            Apply filter
          </button>
        </div>
      </Panel>
    </div>
  );
};
