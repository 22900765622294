import classnames from "classnames";
import { Text } from "drax-design-system";
import { ITextareaInterface } from "../types";

const Textarea = ({ errorMessage, className, ...rest }: ITextareaInterface) => {
  const { label, id } = rest;
  const cx = classnames(`c-form__input ${className}`, {
    "c-form__input--error": errorMessage,
  });
  const cxWrapper = classnames(`c-form__input-wrapper`, {
    "c-form__input-wrapper--error": errorMessage,
  });
  const updatedProps = { ...rest, className: cx };

  return (
    <div className="c-form__element">
      <label className="c-form__label" htmlFor={id}>
        {label}
      </label>
      <div className={cxWrapper}>
        <textarea {...updatedProps} />
      </div>
      {errorMessage && (
        <Text.p className="c-form__error-text" textColor={"error"}>
          {errorMessage}
        </Text.p>
      )}
    </div>
  );
};

export default Textarea;
