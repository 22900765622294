import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { IViewProps } from "pages/Dashboard/types";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectFilteredChargeStations,
  selectNearestChargeStations,
} from "store/selectors/chargeStations";
import {
  selectFilteredCombustionVehicles,
  selectMapElectricVehicles,
} from "store/selectors/vehicles";
import { InfoPanelType } from "store/slices/dashboard/types";
import { RootState } from "store/store";

const EmptyArray: any[] = [];

export const useViewData = ({
  selectedItemId,
  panelType,
  enabledPanels,
  filters,
  hideChargingVehicles,
  showNearestStations,
}: IViewProps & { hideChargingVehicles: boolean }) => {
  const evPanelVisible = enabledPanels.includes("ev");
  const cvPanelVisible = enabledPanels.includes("cv");
  const csPanelVisible = enabledPanels.includes("cs");

  const vehicleId = panelType === InfoPanelType.Vehicle ? selectedItemId : null;

  const sub = useSubscription();

  const electricVehicles = useSelector((state: RootState) =>
    evPanelVisible && sub.checkSubscription(Subscription.Vehicle)
      ? selectMapElectricVehicles(state, {
          csPanelVisible,
          filter: filters.ev,
          hideChargingVehicles,
        })
      : EmptyArray
  );

  const combustionVehicles = useSelector((state: RootState) =>
    cvPanelVisible && sub.checkSubscription(Subscription.Vehicle)
      ? selectFilteredCombustionVehicles(state, { filter: filters.cv })
      : EmptyArray
  );

  const chargingStations = useSelector((state: RootState) => {
    if (!sub.checkSubscription(Subscription.ChargingStation)) {
      return EmptyArray;
    }

    if (csPanelVisible && !showNearestStations) {
      return selectFilteredChargeStations(state, { filter: filters.cs });
    } else if (showNearestStations) {
      return selectNearestChargeStations(state, { vehicleId });
    }

    return EmptyArray;
  });

  const data = useMemo(() => {
    return [...electricVehicles, ...combustionVehicles, ...chargingStations];
  }, [electricVehicles, combustionVehicles, chargingStations]);

  return {
    data,
    electricVehicles,
    combustionVehicles,
    chargingStations,
  };
};
