import { EyeStrike, Lock } from "assets/images/svgs";
import classnames from "classnames";
import { Icons, Text } from "drax-design-system";
import React, { useRef, useState } from "react";
import { IInputInterface } from "../types";

const Input = ({
  errorMessage,
  locked,
  className,
  ...rest
}: IInputInterface) => {
  const { label, type, value } = rest;
  const ref = useRef<HTMLInputElement>(null);
  const [inputType, setInputType] = useState<string>(type);
  const cxInput = classnames(`c-form__input ${className}`, {
    "c-form__input--locked": locked,
    "c-form__input--error": errorMessage,
  });
  const cxWrapper = classnames(`c-form__input-wrapper`, {
    "c-form__input-wrapper--error": errorMessage,
  });

  const updatedProps = { ...rest, className: cxInput, type: inputType };

  const toggleShowPassword = () => {
    if (inputType === "password") {
      setInputType("text");
      if (ref.current) {
        ref.current.focus();
      }
      return;
    }
    if (ref.current) {
      ref.current.focus();
    }
    setInputType("password");
  };

  return (
    <div className="c-form__element">
      <label className="c-form__label" htmlFor={rest.id}>
        {label}
      </label>
      <div className={cxWrapper}>
        <input data-testid="input" ref={ref} {...updatedProps} />
        {locked && <Lock data-testid="lock-icon" />}
        {type === "password" &&
          typeof value === "string" &&
          value.length >= 1 && (
            <>
              {inputType === "password" ? (
                <Icons.IcEye
                  className="c-form__input-icon"
                  onClick={toggleShowPassword}
                  width="20px"
                />
              ) : (
                <EyeStrike
                  className="c-form__input-icon"
                  onClick={toggleShowPassword}
                  width="20px"
                />
              )}
            </>
          )}
      </div>
      {errorMessage && (
        <Text.p className="c-form__error-text" textColor={"error"}>
          {errorMessage}
        </Text.p>
      )}
    </div>
  );
};

export default Input;
