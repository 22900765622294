import { IConsumptionState } from "./types";

export const initialState: IConsumptionState = {
  sites: null,
  sitesError: false,
  insights: null,
  insightsError: false,
  consumptionsError: false,
  consumptionsData: {},
};
