import { TrendingDown, TrendingUp } from "assets/images/svgs";
import strings from "assets/strings";
import { FunctionComponent } from "react";
import Trend, { ITrend, TrendError } from "../Trend";

export const TrendTarget: FunctionComponent<ITrend> = ({ prevValue }) => {
  const {
    reports: {
      trends: { lastMonth },
    },
  } = strings;
  return (
    <>
      {lastMonth.toLowerCase()} {prevValue}%
    </>
  );
};

export const TrendValue: FunctionComponent<ITrend> = (props) => {
  const { prevValue, currValue } = props;
  const trend =
    typeof prevValue == "number" && typeof currValue == "number" ? (
      currValue > prevValue ? (
        <TrendingUp />
      ) : currValue < prevValue ? (
        <TrendingDown />
      ) : (
        ""
      )
    ) : (
      ""
    );

  return (
    <>
      {trend} {currValue}%
    </>
  );
};

export const TrendWrapper: FunctionComponent<
  ITrend & { error: boolean; errorTestId?: string }
> = (props) => {
  const { errorTestId, error, ...trendProps } = props;

  if (error) {
    return <TrendError data-testid={props.errorTestId} />;
  }

  return <Trend {...trendProps} />;
};
