import { useReportNavigation } from "hooks/useReportNavigation";
import React from "react";
import { dateEndDayParameter, dateOnlyParameter } from "utils/queryUtils";
import { ParamOptions } from "./useQuery";

export const startEndDateParcer = {
  startDate: dateOnlyParameter,
  endDate: dateEndDayParameter,
};

const today = new Date();
const StartDate = new Date(today.getFullYear(), today.getMonth(), 1);
const EndDate = today;

interface StartEndDateFilter {
  startDate: Date;
  endDate: Date;
}

export const useStartEndDateFilterNavigation = <T extends StartEndDateFilter>(
  options?: ParamOptions<T>
): [T, React.Dispatch<T>, (params: Partial<T>) => void] => {
  const [filter, setFilter] = useReportNavigation<T>(
    { startDate: StartDate, endDate: EndDate } as T,
    options ?? startEndDateParcer
  );

  const setDates = ({ startDate, endDate }: T) => {
    setFilter({ startDate, endDate: endDate ?? startDate } as T);
  };

  return [filter, setDates, setFilter];
};
