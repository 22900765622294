import paths from "appRoutes/paths";
import { If, Loader } from "components";
import IconButton from "components/IconButton/IconButton";
import { Icons, Panel } from "drax-design-system";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { useVehicleDetails } from "hooks/useVehicleDetails";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import "./styles.scss";
import { VehicleInfoPanel } from "./VehicleInfoPanel";

interface VehicleInfoProps {
  vehicleId: string;
  onBack: () => void;
}

const VehicleInfo: FunctionComponent<VehicleInfoProps> = ({
  vehicleId,
  onBack,
}) => {
  const navigate = useNavigate();
  const [params, go] = useDashboardNavigation(navigate);
  const showNearest = params.showNearest ?? false;
  const toggleShowNearest = () => {
    go({ showNearest: !showNearest });
  };

  const openJourneys = () => navigate(paths.journeys);
  const selectedVehicle = useVehicleDetails(vehicleId);

  return (
    <Panel
      className={`${gtmTags.vehiclePanel} p-info-panel p-vehicle-panel`}
      data-testid="electric-vehicle-info-panel"
    >
      <IconButton
        text="Back"
        onClick={onBack}
        className={gtmTags.vehiclePanelBack}
        icon={
          <Icons.IcChevronLeft
            height="25px"
            className={gtmTags.vehiclePanelBack}
          />
        }
        data-testid="back-button"
      />
      <If value={selectedVehicle == null}>
        <div className=" p-info-panel__backdrop" />
        <Loader />
      </If>
      <If value={selectedVehicle != null}>
        <VehicleInfoPanel
          vehicleDetails={selectedVehicle!}
          showNearest={showNearest}
          toggleShowNearest={toggleShowNearest}
          openJourneys={openJourneys}
        />
      </If>
    </Panel>
  );
};

export default VehicleInfo;
