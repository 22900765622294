import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  ChartType,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import React, { MouseEvent, useRef } from "react";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import "./styles.scss";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export interface ReactChartProps {
  chartData: ChartData;
  chartOptions: ChartOptions;
  chartType: ChartType;
  onBarClick: (date: Date) => void;
}

export const ReactChart = (props: ReactChartProps) => {
  const { chartType, chartData, chartOptions } = props;
  const { onBarClick } = props;

  const dataCount = chartData.datasets[0].data.length;
  const chartWrapperWidth = {
    width: `${dataCount * 54}px`,
  };
  const chartRef = useRef<ChartJS<"bar">>(null);

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;
    if (!chart) {
      return;
    }

    const elements = getElementAtEvent(chart, event);

    if (!elements || !elements.length) {
      return;
    }
    const { datasetIndex, index } = elements[0];
    const data = chartData.datasets[datasetIndex].data[index] as any;
    const date = data.startOfAggregate ?? new Date();
    onBarClick(new Date(date));
  };

  return (
    <div
      className={`p-chart-container p-chart-container--${chartType}`}
      style={{ paddingBottom: `${dataCount > 16 ? "15px" : "20px"}` }}
    >
      <div
        className="p-chart-wrapper"
        style={dataCount > 16 ? chartWrapperWidth : {}}
      >
        <Chart
          ref={chartRef}
          type={chartType}
          onClick={onClick}
          options={chartOptions}
          data={chartData}
        />
      </div>
    </div>
  );
};
