import { Organisation } from "api/domains/organisations/types";
import { Subscription } from "contexts/subscriptionContext";

const subscriptionMap = {
  "Charging Stations": Subscription.ChargingStation,
  "Energy Management": Subscription.Energy,
  "Finance Reporting": Subscription.ReportingFinance,
  Vehicles: Subscription.Vehicle,
};

type SubscriptionMap = keyof typeof subscriptionMap;

export const findOrganisationSubscriptions = (
  organisations: Array<Organisation>,
  orgId: string
) => {
  const organisation = organisations.find((o) => o.id === orgId);
  if (!organisation) {
    return null;
  }
  const subscriptions = organisation.subscriptions.map(
    ({ name }) => subscriptionMap[name as SubscriptionMap]
  );
  return subscriptions;
};
