import strings from "assets/strings";
import { TableLocalSorting } from "components/Table";
import { useViewData } from "hooks/useViewData";
import { IViewProps } from "pages/Dashboard/types";
import { dashboardTableConfig } from "pages/Reports/reportsTables";
import {
  FunctionComponent,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { selectChargeStationsError } from "store/slices/chargestations";
import { ChargeStation } from "store/slices/chargestations/types";
import {
  IDashboardTableData,
  InfoPanelType,
} from "store/slices/dashboard/types";
import { selectVehiclesError } from "store/slices/vehicles";
import { pluralise } from "utils/stringHelpers";
import "./styles.scss";
import { dataMap } from "./tableDataMapper";

const useDashboardTableView = (): [RefObject<HTMLDivElement>, number] => {
  const [tableHeight, setTableHeight] = useState(580);
  const wrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapElement = wrapRef.current;

    if (wrapElement == null) {
      return;
    }

    const resizeHandler = () => {
      const wrapRect = wrapRef.current?.getClientRects()?.[0];
      if (wrapRect == null) {
        return;
      }
      const tableHederElement =
        wrapRef.current?.getElementsByClassName("rc-table-header")?.[0];
      if (tableHederElement == null) {
        return;
      }

      const headerRect = tableHederElement.getClientRects()?.[0];

      const h = wrapRect.height;
      const t = wrapRect.top;
      const b = headerRect.bottom;

      const th = h - (b - t) - 10;
      setTableHeight(th);
    };

    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [wrapRef]);

  return [wrapRef, tableHeight];
};

const TableComponent: FunctionComponent<IViewProps> = (props) => {
  const {
    dashboard: {
      table: { result },
    },
  } = strings;

  const { selectedItemId, onSelectItem } = props;

  const { chargingStations, combustionVehicles, electricVehicles } =
    useViewData({ ...props, hideChargingVehicles: false });

  const chargeStationsError = useSelector(selectChargeStationsError);
  const vehiclesError = useSelector(selectVehiclesError);

  const tableData = dataMap({
    vehicles: [...combustionVehicles, ...electricVehicles],
    chargeStations: [...chargingStations],
  });

  const getDataDetails = (data: IDashboardTableData) => {
    let infoPanel = InfoPanelType.Station;
    if (data.type !== ChargeStation.Type) {
      infoPanel =
        data.type === "Electric"
          ? InfoPanelType.Vehicle
          : InfoPanelType.CombustionVehicle;
    }
    onSelectItem(data.id, infoPanel);
  };

  const [wrapRef, tableHeight] = useDashboardTableView();

  return (
    <div ref={wrapRef} className="p-dashboard__table-wrap">
      <div className="p-dashboard__table">
        <TableLocalSorting
          key={`key-${tableHeight}`}
          dataKey={"id"}
          title={`${tableData.length} ${pluralise(result, tableData.length)}`}
          defaultSortColumn={"name"}
          columns={dashboardTableConfig}
          data={tableData}
          isLoading={false}
          dataError={chargeStationsError || vehiclesError}
          selectedRowId={selectedItemId}
          onRowClick={getDataDetails}
          showTooltip={true}
          tableHeight={tableHeight}
          showExportDataCount={true}
        />
      </div>
    </div>
  );
};

export default TableComponent;
