import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./initialDashboardState";
import { IDashboardState } from "./types";

const dashboard = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setZoom(state: IDashboardState, { payload: zoom }: PayloadAction<number>) {
      state.mapSettings.zoom = zoom;
    },
  },
});

export const { setZoom } = dashboard.actions;
export default dashboard;

export const selectDashboardState = (state: RootState) => state.dashboard;
