import { ParamOptions } from "hooks/useQuery";
import { DashboardView, InfoPanelType } from "store/slices/dashboard/types";
import { parseArrayParam, parseBooleanParam } from "utils/queryUtils";
import { DashboardParamsType } from "./types";

export const flipArrayValue = <T>(arr: T[], v: T) =>
  arr.includes(v) ? arr.filter((x) => x !== v) : [...arr, v];

const panelTypeMapTo: any = {
  [InfoPanelType.Station]: "cs",
  [InfoPanelType.CombustionVehicle]: "cv",
  [InfoPanelType.Vehicle]: "ev",
};

const panelTypeMapFrom = Object.entries(panelTypeMapTo).reduce(
  (acc: any, curr) => {
    const [key, value] = curr;
    acc[value as string] = key;
    return acc;
  },
  {}
);

export const dashboardParser: ParamOptions<DashboardParamsType> = {
  view: {
    from: (x: string) =>
      x?.toLowerCase() === "map" ? DashboardView.Map : DashboardView.Table,
    to: (x) => (x === DashboardView.Map ? "map" : "table"),
  },
  panel: {
    from: (x: number) => {
      const r = panelTypeMapFrom[x];
      return r != null ? Number(r) : r;
    },
    to: (x: number) => panelTypeMapTo[x],
  },
  filters: parseArrayParam(String),
  ev: parseArrayParam(String),
  cv: parseArrayParam(String),
  cs: parseArrayParam(String),
  showNearest: parseBooleanParam,
};

export const defaultDashboard: DashboardParamsType = {
  view: DashboardView.Map,
  panel: null,
  itemId: null,
  filters: ["cs", "ev", "cv"],
  ev: [],
  cv: [],
  cs: [],
  showNearest: false,
};
