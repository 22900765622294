import axios from "axios";
import ApiError from "./common/ApiError";
import Http, { IHttpProtocol } from "./protocols/http";
import { IAxiosResponse, IDomainRequestDetails, IHttpResponse } from "./types";

export interface IHttpClient {
  request<T>(payload: IDomainRequestDetails): Promise<IHttpResponse<T>>;
}

export class HttpClient implements IHttpClient {
  private http: IHttpProtocol;

  constructor() {
    this.http = new Http(axios);
    this.triggerRequest = this.triggerRequest.bind(this);
    this.request = this.request.bind(this);
  }

  public async request<T>(
    payload: IDomainRequestDetails
  ): Promise<T | ApiError> {
    const response = await this.triggerRequest<T>(payload);
    if (response instanceof ApiError) return new ApiError(response);
    return response.data;
  }

  private async triggerRequest<T>(
    payload: IDomainRequestDetails
  ): Promise<IAxiosResponse<T>> {
    const response = await this.http.request<T>(payload);
    return response;
  }
}

export default new HttpClient();
