import { getYear } from "date-fns";
import React, { FunctionComponent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IDatePickerView } from "./types";

const DatePickerYearView: FunctionComponent<IDatePickerView> = ({
  selectedDate,
  onChange,
  mode,
  maxDate,
}) => {
  return (
    <div
      className="consumption-date-picker__year-view"
      data-testid="date-picker-year-view"
    >
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        onYearChange={onChange}
        showYearPicker
        dateFormat="yyyy"
        inline
        yearItemNumber={1}
        maxDate={maxDate}
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
          changeYear,
        }) => (
          <div className="react-datepicker__year-header--custom">
            {!prevYearButtonDisabled && (
              <button
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={() => {
                  decreaseYear();
                  changeYear(date.getFullYear() - 1);
                }}
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                  Previous Year
                </span>
              </button>
            )}
            {!nextYearButtonDisabled && (
              <button
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={() => {
                  increaseYear();
                  changeYear(date.getFullYear() + 1);
                }}
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                  Next Year
                </span>
              </button>
            )}
            <div className="react-datepicker__month-container">
              <div className="react-datepicker__header react-datepicker-year-header">
                {getYear(date)}
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default DatePickerYearView;
