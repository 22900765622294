import strings from "assets/strings";
import Loader from "components/Loader";
import PageError from "components/PageError";
import { Heading } from "drax-design-system";
import "./styles.scss";

interface ITablePlaceholders {
  error: boolean;
  loading: boolean;
  noData: boolean;
}

const TablePlaceholders: React.FC<ITablePlaceholders> = ({
  error,
  loading,
  noData,
}) => {
  if (error)
    return (
      <div className="p-table__plugs">
        <PageError withPanel={false} />
      </div>
    );
  if (loading) return <Loader className="p-table__plugs" />;
  if (noData)
    return (
      <Heading.h3
        textColor="tertiary"
        className="p-table__no-data"
        data-testid="p-table__no-data"
      >
        {strings.dashboard.table.noResults}
      </Heading.h3>
    );
  return <></>;
};

export default TablePlaceholders;
