import strings from "assets/strings";
import { ISelectOption } from "components/Select/Select";
import { DatePickerModes } from "./components/DatePicker";

export interface IFormatedSite extends ISelectOption {
  mpans: ISelectOption[];
  id: string;
}

export type Ilevel = {
  data: number[][];
  labels: string[];
  backString: string | null;
};

export type ILevels = {
  [key: string]: Ilevel;
};

export type Ilabel = {
  label: string;
  backString: string | null;
};

export type ILabels = {
  [key: string]: Ilabel;
};

export type IDatasetHidden = {
  [key: number]: boolean;
};

export enum dataTypes {
  evConsumption,
  siteConsumption,
  totalConsumtion,
  capacity,
}
export interface IChartDateFilter {
  startDate: Date;
  endDate: Date;
}

const { backToYear, backToMonth, backToWeek } =
  strings.reports.energyConsumption;

export const chartLabels: ILabels = {
  [DatePickerModes.YEAR]: {
    label: "Year",
    backString: null,
  },
  [DatePickerModes.MONTH]: {
    label: "Month",
    backString: backToYear,
  },
  [DatePickerModes.WEEK]: {
    label: "Week",
    backString: backToMonth,
  },
  [DatePickerModes.DAY]: {
    label: "Day",
    backString: backToWeek,
  },
};
