import React, { RefObject, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMoreNotifications,
  selectNotifications,
} from "store/slices/notifications";
import { NotificationTypes } from "store/slices/notifications/types";
import { NotificationsList } from "./NotificationsList";

export interface NotificationsListInfiniteLoaderProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const NotificationsListInfiniteLoader = (
  props: NotificationsListInfiniteLoaderProps
) => {
  const { containerRef } = props;
  const dispatch = useDispatch();
  const { notifications, total } = useSelector(selectNotifications);

  const loadMoreNotifications = useCallback(
    (
      skip: number,
      top: number,
      notificationTypes: Array<NotificationTypes>
    ) => {
      return dispatch(
        getMoreNotifications(skip, top, notificationTypes)
      ) as any as Promise<any>;
    },
    [dispatch]
  );

  return (
    <NotificationsList
      containerRef={containerRef}
      notifications={notifications}
      totalCount={total}
      loadMoreNotifications={loadMoreNotifications}
    />
  );
};
