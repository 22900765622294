import urls from "api/domains/urls";
import { Subscription } from "contexts/subscriptionContext";
import { Middleware } from "redux";
import Connector from "services/Connector";
import { RootState } from "store";
import { isSetUser } from "../slices/auth";
import { chargingStationsHandlersFactory } from "./realtimeHandlers/chargingStationsHandlers";
import { messagingHandlersFactory } from "./realtimeHandlers/messagingHandlers";

const checkSubscription = (
  sub: Subscription | Subscription[],
  subscriptions: Subscription[]
) => {
  const subs = Array.isArray(sub) ? sub : [sub];
  if (!Array.isArray(subscriptions)) {
    return false;
  }
  return subs.every((s) => subscriptions.includes(s));
};

const realtimeMiddleware: Middleware<{}, RootState> =
  (api) => (next) => async (action) => {
    if (!isSetUser(action)) {
      return next(action);
    }

    const subscriptions = action.payload.user?.subscriptions ?? [];
    const canChargingStation = checkSubscription(
      Subscription.ChargingStation,
      subscriptions
    );

    if (canChargingStation) {
      const notifications = new Connector(`${urls.hubs}/notifications`);
      notifications.handleConnection(messagingHandlersFactory(api));

      const chargeStations = new Connector(`${urls.hubs}/chargingstations`);
      chargeStations.handleConnection(chargingStationsHandlersFactory(api));
    }

    return next(action);
  };

export default realtimeMiddleware;
