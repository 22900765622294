import Domain from "api/common/Domain";
import { IODataQuery } from "api/protocols/OData/types";
import { IVehicleDetailView } from "../../../store/slices/vehicles/types";
import getAllVehiclesJson from "./dummy/getAllVehicles.json";
import getEVPerformanceMetricsJson from "./dummy/getEVPerformanceMetrics.json";
import getVehicleJson from "./dummy/getVehicle.json";
import getVehicleJourneysJson from "./dummy/getVehicleJourneys.json";
import getVehicleJourneysSummaryJson from "./dummy/getVehicleJourneysSummary.json";
import getVehicleSavingsJson from "./dummy/getVehicleSavings.json";
import { IVehicle } from "./types";
import { vehicleStatusMapper, vehicleTransfomer } from "./vehicleTransformer";

export class Vehicles extends Domain {
  constructor(httpClient: any) {
    super();
  }

  async getAllVehicles() {
    return vehicleTransfomer(getAllVehiclesJson.items as any);
  }

  async getVehicle(vehicleId: string) {
    //@ts-ignore
    return vehicleStatusMapper<IVehicleDetailView, IVehicle>({
      ...getVehicleJson,
      id: vehicleId,
      licensePlate: vehicleId,
    }) as IVehicleDetailView;
  }

  async getVehicleSavings() {
    return getVehicleSavingsJson;
  }

  async getVehicleJourneysSummary() {
    return getVehicleJourneysSummaryJson;
  }

  async getEVPerformanceMetrics() {
    return getEVPerformanceMetricsJson;
  }

  async queryVehicleJourneys(query: IODataQuery) {
    const { value: items, "@odata.count": total } = getVehicleJourneysJson;
    return { items, total };
  }
}
