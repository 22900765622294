import { addDays } from "date-fns";
import { FunctionComponent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getWeekStartDate } from "./helpers";
import { IDatePickerView } from "./types";

const DatePickerWeekView: FunctionComponent<IDatePickerView> = ({
  selectedDate,
  onChange,
  maxDate,
}) => {
  // here is picking the start of a week when switching from the day view where is selected another day
  const weekStartsOn = 1; // 0 = Sunday, 1 = Monday...
  const date = getWeekStartDate(selectedDate, weekStartsOn);

  const datesToHighlight = Array(7)
    .fill(null)
    .map((el, idx) => addDays(date, idx));

  const highlightWithRanges = [
    {
      "react-datepicker__week--highlight": datesToHighlight,
    },
  ];

  return (
    <div
      className="consumption-date-picker__week-view"
      data-testid="date-picker-week-view"
    >
      <DatePicker
        selected={new Date(date)}
        monthsShown={2}
        calendarStartDay={weekStartsOn}
        filterDate={(date) => date.getDay() === 1}
        onChange={onChange}
        inline
        maxDate={maxDate}
        highlightDates={highlightWithRanges}
      />
    </div>
  );
};

export default DatePickerWeekView;
