import { ILatLng } from "api/domains/chargingStations/types";
import { Map } from "components";
import config from "config/app";
import { useViewData } from "hooks/useViewData";
import { IViewProps } from "pages/Dashboard/types";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { selectVehicleByVehicleId } from "store/selectors/common";
import { selectDashboardState } from "store/slices/dashboard";
import { InfoPanelType } from "store/slices/dashboard/types";
import { VehicleFuelType } from "store/slices/vehicles/types";
import { generateRange } from "utils/distanceUtils";
import ChargeStationsMarkers from "./ChargeStationsMarkers";
import CombustionVehiclesMarkers from "./CombustionVehiclesMarkers";
import ElectricVehiclesMarkers from "./ElectricVehiclesMarkers";
import "./styles.scss";

export type ILowRangeVehicle = {
  range: number;
  coordinates: ILatLng;
};

const MapView: FunctionComponent<IViewProps> = (props) => {
  const {
    mapSettings: { zoom, coords },
  } = useSelector(selectDashboardState);
  const { onSelectItem, selectedItemId } = props;
  const [lowRangeVeh, setLowRangeVeh] = useState<ILowRangeVehicle | null>(null);
  const { data, chargingStations, combustionVehicles, electricVehicles } =
    useViewData({ ...props, hideChargingVehicles: true });

  const selectedVehicle = useSelector((state: RootState) =>
    selectVehicleByVehicleId(state, { vehicleId: selectedItemId })
  );

  useEffect(() => {
    if (
      selectedVehicle?.fuelType === VehicleFuelType.Electric &&
      selectedVehicle.range < config.app.vehicleLowRangeLevel
    ) {
      const calcRange = generateRange(selectedVehicle.range) || 0;
      setLowRangeVeh({
        range: calcRange,
        coordinates: selectedVehicle.coordinates,
      });
    } else {
      setLowRangeVeh(null);
    }
  }, [selectedVehicle]);

  const selectStationCb = useCallback(
    (id: string) => onSelectItem(id, InfoPanelType.Station),
    [onSelectItem]
  );

  const selectVehicleCb = useCallback(
    (id: string) => onSelectItem(id, InfoPanelType.Vehicle),
    [onSelectItem]
  );

  const selectCombustionCb = useCallback(
    (id: string) => onSelectItem(id, InfoPanelType.CombustionVehicle),
    [onSelectItem]
  );

  return (
    <section className="p-map-view__wrapper" data-testid="google-map">
      <Map
        zoom={zoom}
        coords={coords}
        pins={data}
        lowRangeVehicle={lowRangeVeh}
      >
        <>
          {chargingStations.length && (
            <ChargeStationsMarkers
              stations={chargingStations}
              stationId={selectedItemId}
              onSelectStation={selectStationCb}
            />
          )}
          {electricVehicles.length && (
            <ElectricVehiclesMarkers
              vehicles={electricVehicles}
              vehicleId={selectedItemId}
              onSelectVehicle={selectVehicleCb}
            />
          )}
          {combustionVehicles.length && (
            <CombustionVehiclesMarkers
              vehicleId={selectedItemId}
              vehicles={combustionVehicles}
              onSelectVehicle={selectCombustionCb}
            />
          )}
        </>
      </Map>
    </section>
  );
};

export default MapView;
