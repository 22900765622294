import { FinanceReportingData } from "api/domains/chargingStations/types";
import React from "react";
import { roundValueWithFraction } from "utils/formatTableData";
import "../styles.scss";
import { TableColumnConfig } from "../types";
import Tariff from "../Pages/FinanceReporting/Tariff";

export const financeReportingTableConfig: TableColumnConfig<FinanceReportingData> =
  [
    {
      title: "RFID Card",
      key: "authId",
      dataIndex: "authId",
      sortable: true,
      width: 180,
    },
    {
      title: "Premise",
      key: "premise",
      dataIndex: "premise",
      sortable: true,
      width: 140,
    },
    {
      title: "Charge Station",
      key: "chargingStationId",
      dataIndex: "chargingStationId",
      width: 200,
      sortable: true,
    },
    {
      title: "Tariff (p/kWh)",
      key: "tariff",
      dataIndex: "tariff",
      align: "right",
      sortable: true,
      render: (el: number, record: FinanceReportingData, index) => {
        return <Tariff index={index} record={record} />;
      },
      formatFunc: (v: number) => roundValueWithFraction(v, 2),
      width: 150,
    },
    {
      title: "Total volume (kWh)",
      key: "totalVolume",
      dataIndex: "totalVolume",
      align: "right",
      sortable: true,
      formatFunc: (v: number) => roundValueWithFraction(v, 2),
      width: 150,
    },
    {
      title: "Total amount (£)",
      key: "totalAmount",
      dataIndex: "totalAmount",
      align: "right",
      sortable: true,
      formatFunc: (v: number) => roundValueWithFraction(v, 2),
      width: 150,
    },
  ];
