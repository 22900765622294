import { ChartDataset } from "chart.js";
import { format } from "date-fns/esm";
import { IConsumption } from "../../../api/domains/consumption/types";
import strings from "../../../assets/strings";
import { ISelectOption } from "../../../components/Select/Select";
import { DefaultChartData } from "../chartConfig";
import { dataTypes } from "../types";
import { DatePickerModes } from "./DatePicker";

const defaultDataTypes = [
  dataTypes.evConsumption,
  dataTypes.siteConsumption,
  dataTypes.totalConsumtion,
];

const getLabels = (consumptions: IConsumption[]) =>
  consumptions.map((el) => el.label);

const filterConsumptionsForDatasets = (set: ChartDataset, consumptions: IConsumption[]) => {
  switch (set.label) {
    case strings.reports.energyConsumption.siteConsumption:
      set.data = consumptions.filter(c => c.siteConsumptionKwh > 0) as any;
      break;

    case strings.reports.energyConsumption.evConsumption:
      set.data = consumptions.filter(c => c.evConsumptionKwh > 0) as any;
      break;

    default:
      set.data = consumptions as any;
  }
}

const generateExportData = (
  mpan: ISelectOption | null,
  date: string,
  dataTypes: dataTypes[],
  consumptionData: any
) => {
  const {
    reports: {
      energyConsumption: {
        evConsumption,
        siteConsumption,
        totalConsumption,
        capacity,
        units: { kWh },
      },
    },
  } = strings;

  const dataTypeHeadings = [
    `${evConsumption} ${kWh}`,
    `${siteConsumption} ${kWh}`,
    `${totalConsumption} ${kWh}`,
    `${capacity} ${kWh}`,
  ];

  return dataTypes.map((dataType) => [
    mpan?.value,
    date,
    dataTypeHeadings[dataType],
    ...consumptionData[dataType],
  ]);
};

export const generateExportDataStructure = (
  mpan: ISelectOption | null,
  date: Date,
  consumptions: IConsumption[],
  level: DatePickerModes
) => {
  const getConsumptionsData = () => {
    const evConsumption: number[] = [];
    const siteConsumption: number[] = [];
    const totalConsumption: number[] = [];
    const capacity: number[] = [];

    consumptions?.forEach((el) => {
      evConsumption.push(el.evConsumptionKwh);
      siteConsumption.push(el.siteConsumptionKwh);
      totalConsumption.push(el.totalConsumptionKwh);
      capacity.push(el.capacityKva);
    });

    return [evConsumption, siteConsumption, totalConsumption, capacity];
  };

  const labels = getLabels(consumptions ?? []);
  switch (level) {
    case DatePickerModes.YEAR:
      const _dateYear = format(date, "yyyy");
      return [
        ["MPAN", "Year", "Type of consumption", ...labels],
        ...generateExportData(
          mpan,
          _dateYear,
          defaultDataTypes,
          getConsumptionsData()
        ),
      ];
    case DatePickerModes.MONTH:
      const _dateMonth = format(date, "MMMM yyyy");

      return [
        ["MPAN", "Month", "Type of consumption", ...labels],
        ...generateExportData(
          mpan,
          _dateMonth,
          defaultDataTypes,
          getConsumptionsData()
        ),
      ];
    case DatePickerModes.WEEK:
      const _dateWeek = format(date, "dd/MM/yyyy");
      return [
        ["MPAN", "Week commencing", "Type of consumption", ...labels],
        ...generateExportData(
          mpan,
          _dateWeek,
          defaultDataTypes,
          getConsumptionsData()
        ),
      ];
    case DatePickerModes.DAY:
      const _dateDay = format(date, "dd/MM/yyyy");
      return [
        ["MPAN", "Week commencing", "Type of consumption", ...labels],
        ...generateExportData(
          mpan,
          _dateDay,
          [...defaultDataTypes, dataTypes.capacity],
          getConsumptionsData()
        ),
      ];
  }
};

export const generateChartData = (
  dataHidden: any,
  aggregationLevel: number,
  consumptions?: IConsumption[] | null
) => {
  const chc = DefaultChartData(aggregationLevel);
  chc.datasets.forEach((set, index) => {
    set.hidden = dataHidden[index];
  });

  if (!Array.isArray(consumptions)) {
    return chc;
  }

  chc.labels = getLabels(consumptions);
  chc.datasets.forEach((set, index) => {
    filterConsumptionsForDatasets(set, consumptions);
    if (consumptions.length > 7) {
      (set as any).barThickness = 8;
    }
  });

  return chc;
};

export const createConsumptionsKey = (
  siteId: string,
  mpanId: string,
  startDate: string,
  endDate: string,
  level: string
) =>
  JSON.stringify({
    siteId,
    mpanId,
    startDate,
    endDate,
    level,
  });
