import { IReportsState } from "./types";

export const initialState: IReportsState = {
  usage: {
    totalEnergy: {
      data: null,
    },
    journeys: {
      data: {
        distanceMiles: null,
        durationHours: null,
      },
    },
    chargingStationsUptime: {
      data: {
        bestUptime: null,
        lastFaultTime: null,
      },
    },
    electricVehicleSavings: {
      data: {
        numberTrees: null,
        poundSterling: null,
        cO2Emissions: null,
      },
    },
  },
  chargeSessions: null,
  chargeSessionsCount: null,
  journeys: null,
  journeysCount: null,
  financesData: null,
  chargeSessionsError: false,
  journeysError: false,
  financeError: false,
  finances: {
    financialReporting: {
      homeChargesTotal: {
        data: null,
      },
      businessChargesTotal: {
        data: null,
      },
    },
  },
};
