import { createSelector } from "@reduxjs/toolkit";
import { selectVehicles } from "store/slices/vehicles";
import { RootState } from "../store";

export const selectVehicleByVehicleId = createSelector(
  (_state: RootState, props: { vehicleId: string | null }) => props.vehicleId,
  selectVehicles,
  (vehicleId, vehicles) => {
    return vehicles?.find((x) => x.id === vehicleId);
  }
);
