import { ChartData } from "chart.js";
import { Heading, Icons, Panel, Text } from "drax-design-system";
import React, { FunctionComponent } from "react";
import { DefaultChartOptions } from "../../../EnergyConsumption/chartConfig";
import Legend from "./components/Legend";
import { ReactChart } from "./ReactChart";
import { ReportChartContent } from "./ReportChartContent";
import "./styles.scss";
import { IChart } from "./types";

export namespace ReportReactChartJs {
  export interface Props extends IChart {
    chartData: ChartData;
    toggleDataset: (index: number) => void;
    onBarClick: (date: Date) => void;
  }
}

const options = DefaultChartOptions();

export const ReportChartContainer: FunctionComponent<ReportReactChartJs.Props> =
  (props) => {
    const { title, description, children, backLink, backLinkAction } = props;
    const { chartData } = props;
    const { hasFetchError, hasData, isLoading } = props;
    const { onBarClick, toggleDataset } = props;

    return (
      <Panel>
        <div className="p-chart" data-testid="chart-section">
          <ReportChartContent
            hasData={hasData}
            hasFetchError={hasFetchError}
            isLoading={isLoading}
          >
            <div className="p-chart-header" data-testid="chart-section-header">
              <div>
                <Heading.h3
                  textColor="tertiary"
                  className="p-chart-header__title"
                >
                  {title}
                </Heading.h3>
                <Text textColor="gray">{description}</Text>
              </div>
              {children}

              <Legend
                type={"bar"}
                datasets={chartData.datasets}
                labels={chartData.labels}
                disableIcons={hasFetchError || !hasData}
                toggleDataset={toggleDataset}
              />
            </div>
            <ReactChart
              chartType={"bar"}
              chartData={chartData}
              chartOptions={options}
              onBarClick={onBarClick}
            />
            {!!backLink && (
              <Text
                className="p-chart-back-link text-color-primary"
                onClick={backLinkAction}
                data-testid="back-button"
              >
                <Icons.IcChevronLeft height="30" />
                {backLink}
              </Text>
            )}
          </ReportChartContent>
        </div>
      </Panel>
    );
  };
