import React from "react";
import { CSVExportButtonContainer } from "./CSVExportButtonContainer";
import "./styles.scss";
import { ITable } from "./types";

export const ExportTableToolbar: React.FC<ITable<any>> = () => {
  return (
    <div className="p-table-toolbar">
      <div className="p-table-toolbar left"></div>
      <div className="p-table-toolbar right">
        <CSVExportButtonContainer />
      </div>
    </div>
  );
};
