import { ChargeStationStatus } from "store/slices/chargestations/types";
import { VehicleStatus } from "store/slices/vehicles/types";

const strings = {
  api: {
    errors: {
      chargeStations: {
        fetchChargeStations: "Error fetching charging stations",
        fetchChargeStation: "Error fetching single charge station",
        fetchChargeStationMetrics:
          "Error fetching charging station performance metrics",
        fetchChargeStationsSessions: "Error fetching charging station sessions",
        fetchChargeStationsSavings: "Error fetching charging station savings",
        fetchFinancialReporting: "Error fetching financial reporting",
      },
      vehicles: {
        fetchVehicles: "Error fetching vehicles",
        fetchVehicle: "Error fetching vehicle information",
        fetchVehiclesSavings: "Error fetching vehicle savings",
        fetchVehiclesMetrics: "Error fetching vehicles metrics",
        fetchVehiclesJournies: "Error fetching vehicle journies",
      },
      messaging: {
        fetchNotifications: "Error fetching notifications",
        setNotificationAsSeen: "Error setting notification as seen",
        deleteNotification: "Error deleting notifications",
        updateNotification: "Error updating notifications",
      },
      support: {
        makeEnquiry: "Error submitting enquiry",
      },
      pageError: {
        title: "Error with retrieving data",
        message: "Please reload the page or make another selection",
      },
      noData: "No data",
      generic: {
        unableToGet: "We are unable to get this information right now.",
      },
      energyConsumption: {
        noData:
          "The site and MPAN you have chosen has no current data available",
        error: "Please reload the page",
      },
    },
  },
  errors: {
    unknownError: "Oops, something's not working! \nPlease try again later.",
    internalServerError:
      "Oops, internal error occured. Please contact support!",
    notModified: "You have already requested this data.",
    noInternetConnection: "No internet connection",
    defaultRequestError: "Request could not be made.",
    genericError: {
      title: "Sorry",
      body: "We have encountered an error submitting your request. Please try again later.",
      close: "Close",
    },
    maintenanceError:
      "The portal's currently out of action while we do some upgrade work, so please contact us on 01473 632584 in the meantime. Sorry for any inconvenience.",
    technicalError:
      "We're having some technical issues but we're working to get the portal back online. Please contact us on 01473 632584 in the meantime, and sorry for any inconvenience.",
  },
  general: {
    chargeStationsTitle: "Charge Stations",
    electricVehiclesTitle: "Electric Vehicles",
    combustionVehiclesTitle: "Combustion Vehicles",
    back: "Back",
  },
  vehicleStatus: {
    [VehicleStatus.Stationary]: "Stationary",
    [VehicleStatus.InTransit]: "In Transit",
    [VehicleStatus.Charging]: "Charging",
    [VehicleStatus.Fault]: "Faulty / Unplugged",
    [VehicleStatus.UnderCharged]: "Under 30% charge",
    [VehicleStatus.Unknown]: "Awaiting Status", // There are no Vehicle Statuses for a vehicle
  },
  chargeStationStatus: {
    [ChargeStationStatus.AVAILABLE]: "Available",
    [ChargeStationStatus.BLOCKED]: "Plugged in, idle",
    [ChargeStationStatus.CHARGING]: "Charging",
    [ChargeStationStatus.INOPERATIVE]: "Inoperative",
    [ChargeStationStatus.OUTOFORDER]: "Faulty",
    [ChargeStationStatus.PLANNED]: "Planned",
    [ChargeStationStatus.REMOVED]: "Removed",
    [ChargeStationStatus.RESERVED]: "Reserved",
    [ChargeStationStatus.UNKNOWN]: "Watchlist",
  },
  dashboard: {
    title: "Dashboard",
    table: {
      result: "Result",
      noResults: "No results",
      tryAgain: "Try activating the filters to see",
      export: "Export",
      sortyBy: "Sort by",
      capacity: "capacity",
      low: "low",
      high: "high",
      type: "Type",
      networkStatus: "Network Status",
      name: "Name",
      address: "Address",
      town: "Town",
      postcode: "Postcode",
      fullDetails: "Full Details",
      vehicle: "Vehicle",
      chargeStation: "Charge Station",
      status: "Status",
      warranty: "Warranty",
    },
    notificationBox: {
      noFaults: "Today, there are no new issues to report",
      hi: "Hi ",
      newFaults: " new faults.",
      thereAre: "There are ",
      review: "Review",
    },
    infoPanel: {
      back: "Back",
      fitlerTitle: "Filter items that have a status of {{title}}",
      chargeStation: {
        id: "ID",
        sessionStart: "Session start",
        chargePorts: "Charge Ports",
        stationDetails: "Station Details",
        address: "Address",
        avUtil: "Av. Utilisation",
        loadingManagement: "Load management used",
        reports: "Station Reports",
        watchlistSince: "Watchlist since",
        totalOutputToday: "Total output today",
        lastCharged: "Last vehicle charged",
        energyDelivered: "Energy delivered",
        vehicleCharging: "Vehicle Charging",
        batteryLevel: "Battery Level",
        range: "Range",
        faultPresented: "Fault presented",
        lastUsed: "Unit last successfully used",
        unknownVehicle: "Unknown Vehicle",
        unknown: "Unknown",
        initiatedById: "Initiated by ID",
        initiatedBy: "Initiated by:",
        error:
          "We can't get hold of charge stations data at the moment but we're working to fix the problem. Please contact us on 01473 632584 in the meantime, and sorry for any inconvenience.",
      },
      vehiclePanel: {
        vehicleDetails: "Vehicle details",
        info: "Vehicle Info",
        chargeStation: "Charge Station",
        licencePlate: "Licence Plate",
        status: "Status",
        chargeLevel: "Charge Level",
        lowBattery: "Battery Below 30%",
        range: "Range",
        avUtil: "Av. Utilisation",
        safetyScore: "Av. Eco Score",
        averageMiles: "Av. miles per KWh",
        costPerMile: "Av. £ per mile",
        totalMileage: "Total Mileage",
        address: "Address",
        vrn: "VRN",
        miles: "mi",
        imei: "IMEI",
        mot: "MOT",
        tax: "Tax",
        service: "Service",
        reports: "Vehicle Reports",
        journeys: "Vehicle Journeys",
        thereAre: "There are",
        availableStns: "{{num}} available charging {{station}} ",
        withinRange: "within range",
        station: "station",
        showMe: "Show Me",
        showing: "Showing",
        undo: "Undo",
        error:
          "We can't get hold of vehicle data at the moment but we're working to fix the problem. Please contact us on 01473 632584 in the meantime, and sorry for any inconvenience.",
      },
    },
  },
  reports: {
    title: "Reports",
    insights: {
      insights: "Insights",
      whatSavings: "What savings have you made?",
      allTimeTotal: "All time total compared to Diesel fleet",
      projectedSavings: "Projected EV driver savings",
      allTime: "All time",
      costSavings: "Cost savings",
      moneySaved: "Money Saved",
      treesSaved: "CO2 saved (trees)",
      co2Saved: "CO2 saved (kg)",
      trees: {
        copse: "Copse",
        grove: "Grove",
        wood: "Wood",
        smallForest: "Small forest",
        mediumForest: "Medium forest",
        largeForest: "Large forest",
      },
    },
    trends: {
      trendingUsage: "Trending usage",
      usage: "Usage",
      how: "How are your operations performing?",
      description:
        "Here you can see the total energy consumption for all sites and EVs. As well as choosing one site and one MPAN in the table below, see more detailed information on the graph.",
      trend: "Trend",
      thisMonth: "This month",
      lastMonth: "Last Month",
      monthToDate: "Month to date",
      previousMonth: "Previous month",
      ConsumptionSite: "Sites Consumption",
      ConsumptionEV: "EVs Consumption",
      ecoScore: "Eco Score",
      journeys: "Journeys",
      allTime: "All time",
      distance: "Distance",
      chargeSessions: "Charge sessions",
      inTheGreen: "In the green",
      timeSinceLastFault: "Time since last fault",
      financialReporting: "Financial reporting",
      homeChargesTotalAmount: "Home chargers total amount",
      businessChargesTotalAmount: "Business chargers total amount",
    },
    allReports: {
      allReports: "All reports",
      chargingStations: "Charging Stations",
      allChargeStations: "All charge stations",
      chargeSessions: "Charge sessions",
      financeReporting: "Finance Reporting",
      vehicles: "Vehicles",
      allVehicles: "All vehicles",
      journeys: "Journeys",
    },
    table: {
      items: "items",
    },
    datePicker: {
      dataFrom: "Data from: ",
      chartDataFrom: "Data from ",
      thisMonth: "This month",
      thisYear: "This year",
      thisQuarter: "This quarter",
      year: "Year",
      month: "Month",
      week: "Week",
      day: "Day",
    },
    journeys: {
      title: "Journeys",
      start: "Start",
      end: "End",
      charge: "charge",
      select: "Select Vehicle",
    },
    utilisation: {
      title: "Utilisation",
      description: "A station is being utilised when it is charging",
      average: "Average for this period",
    },
    availability: {
      title: "Availability",
      description: "% of time the charge station is available for use",
      total: "Total for this period",
    },
    energyConsumption: {
      title: "Energy Usage",
      site: "Site",
      mpan: "MPAN",
      headerTitle: "Energy Management",
      description:
        "See your average energy consumption by month, week, days and even every half an hour.",
      evConsumption: "EV Consumption",
      siteConsumption: "Site Consumption",
      totalEnergyConsumption: "Total Energy Consumption",
      energySupplyCapacity: "Energy Supply Capacity",
      totalConsumption: "Total Consumption",
      capacity: "Capacity",
      backToYear: "Back to 12+ month",
      backToMonth: "Back to month in weeks",
      backToWeek: "Back to week in days",
      units: {
        kVA: "kVA",
        kWh: "kWh",
      },
    },
  },
  profile: {
    requestLineOne: "Need to change your email address? You can ",
    submitRequest: "submit a request ",
    requestLineTwo: "in the Help section.",
    changePassword: "Change password",
    logOut: "Log out",
  },
  support: {
    helpAndSupport: "Help & Support",
    title: "Support",
    contactPref: {
      label: "How do you wish to be contacted?",
      phone: "Phone",
      email: "Email",
    },
    intro:
      "Send us a message with your details and we’ll get back to you as soon as we are able to.",
    email: {
      placeholder: "Enter your email address",
      label: "Your email address",
    },
    phone: {
      placeholder: "Enter your phone number",
      label: "Your phone number",
      error: "Invalid format",
    },
    message: {
      placeholder: "",
      label: "How can we help? Provide as much detail as you can.",
      error: "Please enter at least 10 characters in the message field",
    },
    submit: "Submit",
    success: "Success",
    messageSent: "Your form has been sent",
    done: "Done",
  },
  notificationStrings: {
    title: "Notifications",
    seenAll: "Mark all as seen",
    deleteAll: "Delete all",
    seen: "Mark as seen",
    delete: "Delete",
    fault: "Faulty",
    cancel: "Cancel",
    cantUndo: "You can't undo this action",
    noNotifications: "There are no new notifications.",
    driverContacted: "Driver Contacted",
  },
  dates: {
    today: "Today",
    yesterday: "Yesterday",
  },
  form: {
    requiredField: "Required field",
    sent: "Your form has been sent",
  },
  export: {
    csv: "CSV",
  },
  validation: {
    containOneUpperCase: "contain 1 uppercase letter",
    containOneLowerCase: "contain 1 lowercase letter",
    containOneNumber: "contain 1 number",
    containOneSymbol: "contain 1 symbol",
    containTwelveLetters: "be at least 12 characters",
  },
};

export default strings;
