import {
  IChargingStation,
  IChargingStationFullDetails,
} from "api/domains/chargingStations/types";
import {
  ChargeStationStatus,
  ILiveCsUpdate,
  ILiveSessionUpdate,
} from "./types";

export const filterCsData = (
  filters: ChargeStationStatus[],
  stations: IChargingStation[]
): IChargingStation[] => {
  if (!filters.length) return stations;
  return stations.filter((station) =>
    station.ports.map((port) => port.status).find((f) => filters.includes(f))
  );
};

export const mergeLiveCsData = (
  data: ILiveCsUpdate[],
  chargeStations: IChargingStation[]
): IChargingStation[] => {
  return chargeStations.map((station) => {
    const match = data.find((d) => d.id === station.id);
    if (match) {
      // Merge the port information
      const ports = station.ports.map((port) => {
        const matchedPort = match.ports.find((f) => f.id === port.id);
        return matchedPort
          ? {
              ...port,
              status: matchedPort.status,
              lastUpdated: matchedPort.lastUpdated,
              lastStatusUpdate: matchedPort.lastStatusUpdate,
            }
          : port;
      });
      return { ...station, ports };
    }
    return station;
  });
};

export const mergeSessionUpdateData = (
  data: ILiveSessionUpdate,
  chargeStation: IChargingStationFullDetails
): IChargingStationFullDetails => {
  // Merge the port information
  const ports = chargeStation.ports.map((port) => {
    const matchedPort = data.evseId === port.id;
    return matchedPort
      ? {
          ...port,
          energyDelivered: data.kwh,
          lastSessionUpdated: data.lastUpdated,
        }
      : port;
  });
  return { ...chargeStation, ports };
};
