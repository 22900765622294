import paths from "appRoutes/paths";
import { SubReportingFinance, Subscription, SubscriptionTemplate } from "contexts/subscriptionContext";
import { FunctionComponent, useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ChargeSessions from "./Pages/ChargeSessions";
import FinanceReporting from "./Pages/FinanceReporting";
import Journeys from "./Pages/Journeys";
import Landing from "./Pages/Landing";
import "./styles.scss";


const Reports: FunctionComponent = () => {
  const RedirectReport = useCallback(() => <Navigate to={paths.reports} />, []);
  return <section className="p-page__panel" style={{ boxShadow: "none" }}>
    <Routes>
      <Route path={"charge-sessions"} element={
        <SubscriptionTemplate
          sub={Subscription.ChargingStation}
          ErrorComponent={RedirectReport}
        >
          <ChargeSessions />
        </SubscriptionTemplate>} />
      <Route path={"financial-reporting"} element={
        <SubscriptionTemplate
          sub={SubReportingFinance}
          ErrorComponent={RedirectReport}
        >
          <FinanceReporting />
        </SubscriptionTemplate>} />
      <Route path={"journeys"} element={
        <SubscriptionTemplate
          sub={Subscription.Vehicle}
          ErrorComponent={RedirectReport}
        >
          <Journeys />
        </SubscriptionTemplate>} />
      <Route path={"/"} element={<Landing />}></Route>
      <Route path="*" element={<Navigate to={"/404"} replace={true} />} />
    </Routes>
  </section>
}


export default Reports;
