import strings from "assets/strings";
import config from "config/app";
import { format, isToday, isYesterday } from "date-fns";
import { enGB } from "date-fns/locale";

const NoTimezoneDateLength = "2000-01-01T00:00:00".length;
export const convertToLocalTime = (datetime: Date | string) => {
  const date = new Date(datetime);

  if (Number.isNaN(date.getTime())) {
    return null;
  }

  if (typeof datetime == "string") {
    // remove .123 ms part
    datetime = datetime.replace(/(\.\d{3})/, "");
    // check for timezone Z or +XX:YY
    if (datetime.length !== NoTimezoneDateLength) {
      return date;
    }
  }

  // '2021-11-20T05:10:20' is treated by Date as localtime
  // we treat this date as UTC and convert UTC > Local

  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

export const formatTimeDate = (
  date: Date | string | null,
  dateFormat: string = "HH:mm dd MMM yyyy"
): string => {
  if (!date) {
    return "";
  }
  return format(new Date(date), dateFormat, {
    locale: enGB,
  });
};

export const dateString = (date: Date) => {
  return formatTimeDate(date, "dd.MM.yyyy");
};

export const sortByDate = <T>(data: T[], datePropertyName: keyof T) => {
  const sorted = data.sort((a: any, b: any) => {
    const bValue = convertToLocalTime(b[datePropertyName])?.getTime() ?? 0;
    const aValue = convertToLocalTime(a[datePropertyName])?.getTime() ?? 0;
    return bValue - aValue;
  });
  return sorted;
};

export const groupByDate = <T>(items: T[], datePropertyName: keyof T) => {
  const dateGroups = items.reduce((groups: any, item) => {
    const dateValue = (item as any)[datePropertyName];
    const dateObj = dateString(dateValue);

    if (!groups[dateObj]) {
      groups[dateObj] = [];
    }
    groups[dateObj].push(item);
    return groups;
  }, {});

  return dateGroups as { [index: string]: T[] };
};

export const dateTitle = (date: Date) => {
  const { today, yesterday } = strings.dates;
  const checkDate = new Date(date); //functions isToday/isYesterday accept next argument type: Date | number

  return (
    (isToday(checkDate) && today) ||
    (isYesterday(checkDate) && yesterday) ||
    dateString(date)
  );
};

// Determine if two date times are within the difference tolerance
export const datesWithinTolerance = (
  date1: Date | string,
  date2: Date | string
): boolean => {
  const time1 = new Date(date1).getTime();
  const time2 = new Date(date2).getTime();
  const diff = Math.abs(time1 - time2);
  return diff <= config.app.dateTimeTolerance;
};

export const secondsToMinutes = (secs: number): number => {
  return Math.round(secs / 60);
};
