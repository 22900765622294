import CellInput from "./components/CellInput";
import Input from "./components/Input";
import Password from "./components/Password";
import Radio from "./components/Radio";
import Textarea from "./components/Textarea";
import "./styles.scss";

const formExports = {
  Input,
  Textarea,
  Radio,
  Password,
  CellInput,
};

export default formExports;
