export const mergeArrays = <T>(
  existArray: Array<T>,
  newArray: Array<T>,
  startFrom?: number
) => {
  const first = startFrom ?? 0;
  const initialValue = first === 0 ? [] : [...existArray];
  return newArray.reduce((agg, curr, index) => {
    agg[first + index] = curr;
    return agg;
  }, initialValue);
};

export const sortBy = <TObj, TProp = any>(
  prop: keyof TObj,
  normalize: (v: TProp) => any = (x) => x
) => {
  return (l: TObj, r: TObj) => {
    const safeNormalize = normalize;

    const left = l[prop] as any;
    const right = r[prop] as any;

    const leftValue = safeNormalize(left);
    const rightValue = safeNormalize(right);

    if (leftValue > rightValue) {
      return 1;
    }

    if (leftValue < rightValue) {
      return -1;
    }

    return 0;
  };
};
