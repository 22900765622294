import { ElectricCar } from "assets/images/svgs";
import strings from "assets/strings";
import { Panel } from "drax-design-system";
import { IFilterProps } from "pages/Dashboard/types";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectElectricVehicles } from "store/selectors";
import { selectVehiclesError } from "store/slices/vehicles";
import { VehicleStatus } from "store/slices/vehicles/types";
import Filter from "../Filter";
import FilterError from "./FilterError";
import {
  enumEntries,
  genVehicleStats,
  vehicleFilterStates,
  vehicleStats,
} from "./generateStats";

const ElectricVehiclesFilter: FunctionComponent<IFilterProps> = ({
  enabled,
  filters,
  onToggle,
  onFilter,
}) => {
  const {
    general: { electricVehiclesTitle },
    dashboard: {
      infoPanel: {
        vehiclePanel: { error },
      },
    },
  } = strings;

  const electricVehicles = useSelector(selectElectricVehicles);
  const vehiclesError = useSelector(selectVehiclesError);

  const statuses = useMemo(
    () => genVehicleStats(electricVehicles, { ...vehicleStats }),
    [electricVehicles]
  );

  return (
    <Panel.Collapsible
      id="electric-vehicles-filter-menu"
      data-testid="electric-vehicles-filter-menu"
      title={`${electricVehicles.length} ${electricVehiclesTitle}`}
      icon={<ElectricCar className="small-icon" />}
      isEnabled={enabled}
      onToggle={(e) => e !== enabled && onToggle()}
    >
      <div>
        {enabled && (
          <div>
            {!vehiclesError &&
              enumEntries<VehicleStatus, number>(statuses).map(
                ([key, value]) => (
                  <Filter
                    key={key}
                    name={electricVehiclesTitle}
                    maxValue={electricVehicles.length}
                    currentValue={value}
                    title={strings.vehicleStatus[key]}
                    filter={() => onFilter(key)}
                    isSelected={filters.includes(key)}
                    state={vehicleFilterStates[key]}
                  />
                )
              )}
            {vehiclesError && <FilterError error={error} />}
          </div>
        )}
      </div>
    </Panel.Collapsible>
  );
};

export default ElectricVehiclesFilter;
