import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications } from "store/slices/notifications";
import { getUnreadNotificationsCount } from "store/slices/notifications/notificationsState";
import { NotificationUserState } from "store/slices/notifications/types";
import { useSubscriptionNotificationTypes } from "./useSubscriptionNotificationTypes";

export const useUnreadNotificationsCount = (
  refreshOnMount: boolean = false
) => {
  const dispatch = useDispatch();
  const { unreadNotifications } = useSelector(selectNotifications);
  const notificationTypes = useSubscriptionNotificationTypes();

  /* fallback functionality */
  const { notifications } = useSelector(selectNotifications);
  const localCount = useMemo(
    () =>
      notifications?.filter(
        (n) => n != null && n.userState !== NotificationUserState.Seen
      ).length ?? 0,
    [notifications]
  );
  /**/
  useEffect(() => {
    if (
      (unreadNotifications?.count != null || unreadNotifications?.isError) &&
      !refreshOnMount
    ) {
      return;
    }
    dispatch(getUnreadNotificationsCount(notificationTypes));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (unreadNotifications?.isRealoadNeeded === true) {
      dispatch(getUnreadNotificationsCount(notificationTypes));
    }
    // eslint-disable-next-line
  }, [unreadNotifications?.isRealoadNeeded]);

  useEffect(() => {
    dispatch(getUnreadNotificationsCount(notificationTypes));
    // eslint-disable-next-line
  }, [notificationTypes]);

  return unreadNotifications?.isError
    ? localCount
    : unreadNotifications?.count ?? 0;
};
