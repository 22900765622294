import React from "react";

const If: React.FC<{ value?: boolean }> = ({ value, children }) => {
  if (value === true) {
    return children as any;
  }
  return null;
};

export default If;
