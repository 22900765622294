import classnames from "classnames";
import React from "react";
import { IInputInterface } from "../types";

type ICellInputInterface = Omit<
  IInputInterface,
  "name" | "label" | "locked" | "isPassword"
>;

const CellInput = ({
  errorMessage,
  className,
  ...rest
}: ICellInputInterface) => {
  const cxInput = classnames(`c-form__input ${className}`, {
    "c-form__input--error": errorMessage,
  });
  const cxWrapper = classnames(`c-form__input-wrapper`, {
    "c-form__input-wrapper--error": errorMessage,
  });

  const updatedProps = { ...rest, className: cxInput };

  return (
    <>
      <div className={cxWrapper}>
        <input data-testid="input" {...updatedProps} />
      </div>
      {/* {errorMessage && (
        <Text.p className="c-form__error-text" textColor={"error"}>
          {errorMessage}
        </Text.p>
      )} */}
    </>
  );
};

export default CellInput;
