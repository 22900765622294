import { IVehicle } from "api/domains/vehicles/types";
import { VehicleFuelType } from "store/slices/vehicles/types";
import { formatTimeDate } from "./dateFormat";

export const formatTableDate = (date: any) =>
  formatTimeDate(date, "HH:mm dd/MM/yyyy");

export const roundValue = (data: number) => data?.toFixed(1) || 0;
export const roundValueWithFraction = (data: number, fraction?: number) =>
  data?.toFixed(fraction ?? 1) || 0;

export const showDashForCombustionVehicle = (
  el: any,
  data: Pick<IVehicle, "fuelType">
) => (data.fuelType === VehicleFuelType.Electric ? roundValue(el) : "—");
