import { Heading, Text } from "drax-design-system";
import "./styles.scss";

interface IError {
  title: string;
  message: string;
}

const Error: React.FC<IError> = ({ title, message }) => (
  <div className="p-error">
    <Heading.h3 textColor="tertiary">{title}</Heading.h3>
    <Text.p className="p-error-text">{message}</Text.p>
  </div>
);

export default Error;
