import {
  IInsight,
  InsightMetrics,
  InsightTrends,
} from "api/domains/consumption/types";
import {
  ChargeStation,
  DownwardTrend,
  ElectricCar,
  NoChangeTrend,
  UpwardTrend,
} from "assets/images/svgs";
import strings from "assets/strings";
import { Grid, Heading, Panel, Text } from "drax-design-system";
import { FunctionComponent } from "react";
import "./styles.scss";

const Trend: FunctionComponent<IInsight> = ({
  metric,
  unit,
  trend,
  monthToDate,
  lastMonth,
  previousMonth,
}) => {
  const isSiteConsumption = metric === InsightMetrics.ConsumptionSite;
  const {
    reports: { trends },
  } = strings;
  return (
    <Panel
      className="p-trend__panel"
      data-testid={`${
        isSiteConsumption
          ? "site-consumption-trend-section"
          : "ev-consumption-trend-section"
      }`}
    >
      <Grid template="50px 300px 1fr 1fr 1fr 1fr">
        {isSiteConsumption ? (
          <ChargeStation
            data-testid="sites-consumption-icon"
            className="large-icon"
          />
        ) : (
          <ElectricCar
            className="large-icon"
            data-testid="ev-consumption-icon"
          />
        )}

        <div className="p-trend__metric">
          <Heading.h3
            className="bold"
            textColor="tertiary"
            data-testid="trend-label"
          >
            {isSiteConsumption ? trends.ConsumptionSite : trends.ConsumptionEV}
          </Heading.h3>
        </div>
        <div className="text-center">
          {trend === InsightTrends.Upward && (
            <UpwardTrend data-testid="upward-icon" />
          )}
          {trend === InsightTrends.Downward && (
            <DownwardTrend data-testid="downward-icon" />
          )}
          {trend === InsightTrends.NoChange && (
            <NoChangeTrend data-testid="no-change-icon" />
          )}
        </div>
        <div className="text-center">
          <Text.span
            className="bold p-trend__values"
            textColor="tertiary"
            data-testid="month-to-date"
          >
            {`${monthToDate.toFixed(2)}${unit}`}
          </Text.span>
        </div>
        <div className="text-center">
          <Text.span
            className="bold p-trend__values"
            textColor="tertiary"
            data-testid="last-month"
          >
            {`${lastMonth.toFixed(2)}${unit}`}
          </Text.span>
        </div>
        <div className="text-center">
          <Text.span
            className="bold p-trend__values"
            textColor="tertiary"
            data-testid="previous-month"
          >
            {`${previousMonth.toFixed(2)}${unit}`}
          </Text.span>
        </div>
      </Grid>
    </Panel>
  );
};

export default Trend;
