import { IODataQuery, ODataCollectionResponse } from "./types";

export const isODataCollectionResponse = <T>(
  response: T | any
): response is ODataCollectionResponse<T> => {
  return (
    response.hasOwnProperty("value") &&
    response.hasOwnProperty("@odata.context")
  );
};

export const hasNextLink = (response: ODataCollectionResponse<any>) => {
  return (
    typeof response["@odata.nextLink"] == "string" &&
    response["@odata.nextLink"]?.length > 0
  );
};

export const stringifyODataQuery = (query: IODataQuery) =>
  Object.entries(query ?? {}).reduce((agg, [key, value]) => {
    agg = agg.length === 0 ? agg : agg + "&";
    agg += `${key}=${encodeURIComponent(value)}`;
    return agg;
  }, "");
