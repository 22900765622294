import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import realtimeMiddleware from "./middleware/realtimeMiddleware";
import {
  appState,
  auth,
  chargeStations,
  consumption,
  dashboard,
  modal,
  notifications,
  reports,
  vehicles,
} from "./slices";

const middleware = [
  ...getDefaultMiddleware({}),
  realtimeMiddleware,
  //loggerMiddleware(),
];

const rootReducer = combineReducers({
  app: appState.reducer,
  vehicles: vehicles.reducer,
  chargeStations: chargeStations.reducer,
  dashboard: dashboard.reducer,
  modal: modal.reducer,
  notifications: notifications.reducer,
  reports: reports.reducer,
  auth: auth.reducer,
  consumption: consumption.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

export type RootState = ReturnType<typeof rootReducer>;
export { store };
