import paths from "appRoutes/paths";
import { IconButton, If, Loader } from "components";
import { Icons, Panel } from "drax-design-system";
import { useVehicleDetails } from "hooks/useVehicleDetails";
import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import { CombustionVehicleInfoPanel } from "./CombustionVehicleInfoPanel";
import "./styles.scss";

interface CombustionVehicleInfoProps {
  vehicleId: string;
  onBack: () => void;
}

const CombustionVehicleInfo: FunctionComponent<CombustionVehicleInfoProps> = ({
  vehicleId,
  onBack,
}) => {
  const navigate = useNavigate();
  const selectedVehicle = useVehicleDetails(vehicleId);
  const openJourneys = () => navigate(paths.journeys);

  return (
    <Panel
      className={`${gtmTags.combustionVehiclePanel} p-info-panel p-combustion-panel`}
      data-testid="combustion-info-panel"
    >
      <IconButton
        text="Back"
        onClick={onBack}
        className={gtmTags.combustionVehiclePanelBack}
        icon={
          <Icons.IcChevronLeft
            height="25px"
            className={gtmTags.combustionVehiclePanelBack}
          />
        }
        data-testid="back-button"
      />
      <If value={selectedVehicle == null}>
        <div className=" p-info-panel__backdrop" />
        <Loader />
      </If>
      <If value={selectedVehicle != null}>
        <CombustionVehicleInfoPanel
          vehicleDetails={selectedVehicle!}
          openJourneys={openJourneys}
        />
      </If>
    </Panel>
  );
};

export default CombustionVehicleInfo;
