import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import {
  FinanceReportingData,
  IFinancialReportingSummaryResponse,
  Premise,
} from "api/domains/chargingStations/types";
import {
  IEVSavingsResponse,
  IJourneysSummaryResponse,
} from "api/domains/vehicles/types";
import { IODataQuery } from "api/protocols/OData/types";
import { RootState } from "store";
import { AppThunk } from "store/types";
import { mergeArrays } from "utils/arrayUtils";
import { initialState } from "./initialReportsState";
import { IReportFetchError, IReportsState } from "./types";

const reportsState = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setInsightsData(
      state: IReportsState,
      {
        payload: { cO2Emissions, numberTrees, poundSterling, hasError },
      }: PayloadAction<IEVSavingsResponse & { hasError: boolean }>
    ) {
      state.usage.electricVehicleSavings = {
        data: {
          cO2Emissions,
          numberTrees,
          poundSterling,
        },
        hasError,
      };
    },
    setVehicleUsageData(
      state: IReportsState,
      {
        payload: { durationHours, distanceMiles, hasError },
      }: PayloadAction<IJourneysSummaryResponse & { hasError: boolean }>
    ) {
      state.usage.journeys = {
        data: {
          durationHours,
          distanceMiles,
        },
        hasError,
      };
    },
    setHomeChargersTotal(
      state: IReportsState,
      {
        payload: { chargersTotalAmount, hasError },
      }: PayloadAction<
        IFinancialReportingSummaryResponse & { hasError: boolean }
      >
    ) {
      state.finances.financialReporting.homeChargesTotal = {
        data: chargersTotalAmount,
        hasError,
      };
    },
    setBusinessChargersTotal(
      state: IReportsState,
      {
        payload: { chargersTotalAmount, hasError },
      }: PayloadAction<
        IFinancialReportingSummaryResponse & { hasError: boolean }
      >
    ) {
      state.finances.financialReporting.businessChargesTotal = {
        data: chargersTotalAmount,
        hasError,
      };
    },
    setChargingStationsUsageData(
      state: IReportsState,
      {
        payload: { totalEnergy, hasError },
      }: PayloadAction<{ totalEnergy: number | null; hasError: boolean }>
    ) {
      state.usage.totalEnergy = {
        data: totalEnergy,
        hasError: hasError,
      };
    },
    setChargingStationsUptimeData(
      state: IReportsState,
      {
        payload: { lastFaultTime, bestUptime, hasError },
      }: PayloadAction<{
        lastFaultTime: number | null;
        bestUptime: number | null;
        hasError: boolean;
      }>
    ) {
      state.usage.chargingStationsUptime = {
        data: {
          lastFaultTime,
          bestUptime,
        },
        hasError,
      };
    },
    setFetchFailed(
      state: IReportsState,
      { payload: { error, key } }: PayloadAction<IReportFetchError>
    ) {
      state[key] = error;
    },
    setChargeSessionsData(
      state: IReportsState,
      { payload: { chargeSessions, total, skip } }
    ) {
      state.chargeSessions = mergeArrays(
        state.chargeSessions ?? [],
        chargeSessions,
        skip
      );
      state.chargeSessionsCount = total;
    },
    setVehicleJourneysData(
      state: IReportsState,
      { payload: { journeys, total, skip } }
    ) {
      state.journeys = mergeArrays(state.journeys ?? [], journeys, skip);
      state.journeysCount = total;
    },
    setFinanceReportingData(
      state: IReportsState,
      {
        payload: { finances },
      }: PayloadAction<{ finances: FinanceReportingData[] }>
    ) {
      state.financesData = finances;
    },
    setFinanceReportingTariff(
      state: IReportsState,
      {
        payload,
      }: PayloadAction<{
        authId: string;
        chargingStationId: string;
        tariff: number;
      }>
    ) {
      const { authId, chargingStationId, tariff } = payload;
      const index =
        state.financesData?.findIndex(
          (x) =>
            x.authId === authId && x.chargingStationId === chargingStationId
        ) ?? -1;

      if (index >= 0) {
        const totalVolume = state.financesData![index].totalVolume!;
        state.financesData![index].totalAmount = (tariff * totalVolume) / 100;
        state.financesData![index].tariff = tariff;
      }

      return state;
    },
    resetReports(state: IReportsState) {
      return initialState;
    },
  },
});

export const {
  setVehicleUsageData,
  setChargingStationsUptimeData,
  setChargingStationsUsageData,
  setInsightsData,
  setChargeSessionsData,
  setVehicleJourneysData,
  setFetchFailed,
  setFinanceReportingData,
  setHomeChargersTotal,
  setBusinessChargersTotal,
  setFinanceReportingTariff,
  resetReports,
} = reportsState.actions;
export default reportsState;

export const getVehicleInsightsData = (): AppThunk => async (dispatch) => {
  try {
    const data =
      (await api.domains.Vehicles.getVehicleSavings()) as IEVSavingsResponse;

    if (process.env.NODE_ENV !== "production") {
      (window as any).__setInsightsData__ = (s: any) =>
        dispatch(setInsightsData(s));
    }
    return dispatch(setInsightsData({ ...data, hasError: false }));
  } catch (e) {
    return dispatch(
      setInsightsData({
        cO2Emissions: null,
        numberTrees: null,
        poundSterling: null,
        hasError: true,
      })
    );
  }
};

export const getVehicleJourneysSummaryData =
  (): AppThunk => async (dispatch) => {
    try {
      const data =
        (await api.domains.Vehicles.getVehicleJourneysSummary()) as IJourneysSummaryResponse;

      if (process.env.NODE_ENV !== "production") {
        (window as any).__setVehicleUsageData__ = (
          hours: number,
          miles: number
        ) =>
          dispatch(
            setVehicleUsageData({
              distanceMiles: miles,
              durationHours: hours,
              hasError: false,
            })
          );
      }
      return dispatch(setVehicleUsageData({ ...data, hasError: false }));
    } catch (e) {
      return dispatch(
        setVehicleUsageData({
          distanceMiles: null,
          durationHours: null,
          hasError: true,
        })
      );
    }
  };

export const getChargeStationTotalEnergy = (): AppThunk => async (dispatch) => {
  try {
    const totalEnergy =
      await api.domains.ChargingStations.getChargeStationTotalEnergy();

    return dispatch(
      setChargingStationsUsageData({ totalEnergy, hasError: false })
    );
  } catch (e) {
    return dispatch(
      setChargingStationsUsageData({ totalEnergy: null, hasError: true })
    );
  }
};

export const getChargeStationUptime = (): AppThunk => async (dispatch) => {
  try {
    const { bestUptime, lastFaultTime } =
      await api.domains.ChargingStations.getChargeStationUptime();

    if (process.env.NODE_ENV !== "production") {
      (window as any).__setChargingStationsUptimeData__ = (
        last: number,
        best: number
      ) =>
        dispatch(
          setChargingStationsUptimeData({
            bestUptime: best,
            lastFaultTime: last,
            hasError: false,
          })
        );
    }

    return dispatch(
      setChargingStationsUptimeData({
        bestUptime,
        lastFaultTime,
        hasError: false,
      })
    );
  } catch (e) {
    return dispatch(
      setChargingStationsUptimeData({
        bestUptime: null,
        lastFaultTime: null,
        hasError: true,
      })
    );
  }
};

export const getChargeStationInsightsData =
  (): AppThunk => async (dispatch) => {
    try {
      const data =
        (await api.domains.ChargingStations.getChargeStationSavings()) as IEVSavingsResponse;
      return dispatch(setInsightsData({ ...data, hasError: false }));
    } catch (e) {
      return dispatch(
        setInsightsData({
          cO2Emissions: null,
          numberTrees: null,
          poundSterling: null,
          hasError: true,
        })
      );
    }
  };

export const getChargeSessionsData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setFetchFailed({ error: false, key: "chargeSessionsError" }));
    const data = await api.domains.ChargingStations.getChargingSessions();
    return dispatch(setChargeSessionsData({ chargeSessions: data }));
  } catch (e) {
    return dispatch(
      setFetchFailed({ error: true, key: "chargeSessionsError" })
    );
  }
};

export const queryChargeSessionsData =
  (query: IODataQuery): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFetchFailed({ error: false, key: "chargeSessionsError" }));
      const { items, total } =
        await api.domains.ChargingStations.queryChargingSessions(query);
      return dispatch(
        setChargeSessionsData({
          chargeSessions: items,
          total,
          skip: query.$skip ?? 0,
        })
      );
    } catch (e) {
      return dispatch(
        setFetchFailed({ error: true, key: "chargeSessionsError" })
      );
    }
  };

export const queryVehicleJourneysData =
  (query: IODataQuery): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFetchFailed({ error: false, key: "journeysError" }));
      const { items, total } = await api.domains.Vehicles.queryVehicleJourneys(
        query
      );

      return dispatch(
        setVehicleJourneysData({
          journeys: items,
          total,
          skip: query.$skip ?? 0,
        })
      );
    } catch (e) {
      return dispatch(setFetchFailed({ error: true, key: "journeysError" }));
    }
  };

export const getFinanceReportingData =
  (date: Date): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setFetchFailed({ error: false, key: "financeError" }));

      const startDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), 1)
      );

      const endDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, -1)
      );

      let data = await api.domains.ChargingStations.getFinancialReporting(
        startDate,
        endDate
      );

      data = data?.map((d) => ({
        ...d,
        startDate,
        endDate,
        id: `${d.authId}-${
          d.chargingStationId
        }-${date.getFullYear()}-${date.getMonth()}`,
      }));

      return dispatch(setFinanceReportingData({ finances: data }));
    } catch (e) {
      return dispatch(setFetchFailed({ error: true, key: "financeError" }));
    }
  };

export const getHomeChargersTotalAmountData =
  (): AppThunk => async (dispatch) => {
    try {
      const data =
        await api.domains.ChargingStations.getFinancialReportingChargersSummary(
          Premise.Home
        );

      return dispatch(setHomeChargersTotal({ ...data, hasError: false }));
    } catch (e) {
      return dispatch(
        setHomeChargersTotal({
          chargersTotalAmount: null,
          hasError: true,
        })
      );
    }
  };

export const getBusinessChargersTotalAmountData =
  (): AppThunk => async (dispatch) => {
    try {
      const data =
        await api.domains.ChargingStations.getFinancialReportingChargersSummary(
          Premise.Business
        );

      return dispatch(setBusinessChargersTotal({ ...data, hasError: false }));
    } catch (e) {
      return dispatch(
        setBusinessChargersTotal({
          chargersTotalAmount: null,
          hasError: true,
        })
      );
    }
  };

export const selectReportsState = (state: RootState) => state.reports;
