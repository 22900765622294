import strings from "assets/strings";
import { useState } from "react";
import { IInputInterface } from "../types";
import Input from "./Input";

/* 
    TODO: 
    - Call api on submit of the form with loader
    - Error handling for wrong current password
    - Success screen for changed 
    */

const Password = ({
  setIsValid,
  validate,
  onChange,
  onBlur,
  onFocus,
  ...rest
}: IInputInterface) => {
  const [isFocused, setIsFocused] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState({
    upper: false,
    lower: false,
    num: false,
    sym: false,
    len: false,
  });

  const {
    validation: {
      containOneLowerCase,
      containOneUpperCase,
      containOneNumber,
      containOneSymbol,
      containTwelveLetters,
    },
  } = strings;
  const checkPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    testPasswordValidator(e.target.value);
    if (setIsValid) {
      setIsValid(testFullPw(e.target.value));
    }
    onChange(e);
  };

  const testPasswordValidator = (password: string) => {
    const upper = /[A-Z]/.test(password);
    const lower = /[a-z]/.test(password);
    const num = /[0-9]/.test(password);
    // eslint-disable-next-line
    const sym = /[`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<>\.\?\/]{1,}/.test(
      password
    );
    const len = password.length >= 12;

    setPasswordValidator({
      upper: upper,
      lower: lower,
      num: num,
      sym: sym,
      len: len,
    });
  };

  const testFullPw = (password: string) => {
    const regex =
      // eslint-disable-next-line
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<>\.\?\/])[0-9a-zA-Z`!@#\$%\^&\*\(\)_\-\+={\[\}\]\|\\:;"'<>\.\?\/]{12,}$/;
    return regex.test(password);
  };

  return (
    <>
      <div>
        <div
          data-testid="validation-summary-popup"
          className={`c-pwv ${isFocused && validate ? "c-pwv--active" : ""}`}
        >
          <div className="c-pwv__wrapper">
            <h5>Password must</h5>
            <ul>
              <li
                className={`c-pwv__uppercase ${
                  passwordValidator.upper ? "c-pwv--validated" : ""
                }`}
              >
                <span className="c-pwv__circle"></span>
                {containOneUpperCase}
              </li>
              <li
                className={`c-pwv__lowercase ${
                  passwordValidator.lower ? "c-pwv--validated" : ""
                }`}
              >
                <span className="c-pwv__circle"></span>
                {containOneLowerCase}
              </li>
              <li
                className={`c-pwv__number ${
                  passwordValidator.num ? "c-pwv--validated" : ""
                }`}
              >
                <span className="c-pwv__circle"></span>
                {containOneNumber}
              </li>
              <li
                className={`c-pwv__symbol ${
                  passwordValidator.sym ? "c-pwv--validated" : ""
                }`}
              >
                <span className="c-pwv__circle"></span>
                {containOneSymbol}
              </li>
              <li
                className={`c-pwv__length ${
                  passwordValidator.len ? "c-pwv--validated" : ""
                }`}
              >
                <span className="c-pwv__circle"></span>
                {containTwelveLetters}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Input
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        onChange={checkPassword}
        {...rest}
      />
    </>
  );
};

export default Password;
