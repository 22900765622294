import { ContactPreference } from "api/domains/enquiry/types";
import strings from "assets/strings";
import { FormElement } from "components";
import {
  Button,
  Container,
  Grid,
  Heading,
  Loaders,
  Panel,
  Text
} from "drax-design-system";
import { useSupportFormValidation } from "hooks/useSupportFormValidation";
import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "store/slices/auth";
import { makeEnquiry } from "store/slices/enquiry";
import "./styles.scss";

const Support: FunctionComponent = () => {
  const { support } = strings;
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const [preference, setPreference] = useState<ContactPreference>(
    support.contactPref.email as ContactPreference
  );
  const [phone, setPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);

  const { phoneError, messageError, validateMessageInput, validatePhoneInput } =
    useSupportFormValidation();

  const clearFormFields = () => {
    setMessage("");
    setPhone("");
  };

  const handleEnquiry = async () => {
    setSending(true);

    await dispatch(
      makeEnquiry({
        contactPreference: preference,
        phoneNumber: phone,
        body: message,
      })
    );

    setSending(false);
    clearFormFields();
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (preference === "Email") {
      handleEnquiry();
    } else {
      validatePhoneInput(phone);
      if (phone && !phoneError) {
        handleEnquiry();
      }
    }
  };

  return (
    <section className="p-page__panel">
      <Container className="p-support__container">
        <Grid gap="var(--spacing-gap-m)" align="end" template="1fr">
          <Grid template="1fr var(--modal-width) 1fr" align="start">
            <Heading.h3 textColor="tertiary">
              {support.helpAndSupport}
            </Heading.h3>
            <Panel>
              <Heading.h3 textColor="tertiary">{support.title}</Heading.h3>
              <Text.p textColor="tertiary">{support.intro}</Text.p>

              <FormElement.Radio
                id="support-contact-preference"
                className="c-form__input"
                label={support.contactPref.label}
                name="contactPref"
                elements={[
                  support.contactPref.email,
                  support.contactPref.phone,
                ]}
                value={preference}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPreference(e.target.value as ContactPreference)
                }
              />

              <form onSubmit={handleSubmit}>
                <FormElement.Input
                  id="support-email-address"
                  placeholder={support.email.placeholder}
                  className="c-form__input"
                  label={support.email.label}
                  name="email"
                  type="email"
                  value={user?.email || ""}
                  onChange={() => { }}
                  errorMessage=""
                  disabled={true}
                  locked={true}
                />

                {preference === "Phone" && (
                  <FormElement.Input
                    id="support-phone-number"
                    placeholder={support.phone.placeholder}
                    className="c-form__input"
                    label={support.phone.label}
                    name="phone"
                    type="number"
                    value={phone}
                    errorMessage={phoneError}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setPhone(e.target.value);
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      validatePhoneInput(e.target.value)
                    }
                  />
                )}

                <FormElement.Textarea
                  id="support-user-message"
                  placeholder={support.message.label}
                  className="c-form__input"
                  label={support.message.label}
                  name="message"
                  maxlength={500}
                  value={message}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setMessage(e.target.value);
                  }}
                  onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
                    validateMessageInput(e.target.value);
                  }}
                  errorMessage={messageError}
                  rows={5}
                />
                <Button
                  disabled={message.length < 10 || sending}
                  className="p-support__submit"
                >
                  {sending ? (
                    <Loaders size={"1.5em"} type="spinner" />
                  ) : (
                    support.submit
                  )}
                </Button>
              </form>
            </Panel>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Support;
