import { CollapseButton } from "assets/images/svgs";
import classNames from "classnames";
import React, { FunctionComponent } from "react";

interface ICollapsibleButton {
  isOpen: boolean;
  onCollapse: () => void;
}

const CollapibleButton: FunctionComponent<ICollapsibleButton> = ({
  isOpen,
  onCollapse,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        "p-collapsible__button",
        {
          "is-open": isOpen,
        },
        "p-collapsible__button--center"
      )}
      type="button"
      onClick={onCollapse}
      {...rest}
    >
      <CollapseButton />
    </button>
  );
};

export default CollapibleButton;
