import { IconsPortal, Text } from "drax-design-system";
import React from "react";
import ReactSelect, { StylesConfig } from "react-select";
import "./style.scss";
import { defaultStyles } from "./styles";

type SelectValue = string | number;

export interface ISelectOption {
  value: SelectValue;
  label: string;
}

type SelectOptionsData = ISelectOption[];

interface ISelect {
  label?: string;
  options: SelectOptionsData;
  placeholder?: string;
  icon?: string;
  value: ISelectOption;
  showIcon: boolean;
  isClearable: boolean;
  onSelectChange(el: ISelectOption): void;
  styles?: StylesConfig<any, false, any>;
}

const Select: React.FC<ISelect> = ({
  value,
  options,
  label,
  placeholder,
  showIcon,
  isClearable,
  onSelectChange,
  styles,
  ...props
}) => (
  <div className="p-select" {...props}>
    {showIcon && (
      <IconsPortal.IcCar height="20" className="p-journeys__icon-car" />
    )}
    {!!label && (
      <Text textColor="tertiary" className="p-select__label">
        {label}
      </Text>
    )}
    <ReactSelect
      value={value}
      styles={styles ?? defaultStyles}
      options={options}
      placeholder={placeholder}
      autoFocus={false}
      onChange={onSelectChange as any}
      isClearable={isClearable}
      blurInputOnSelect={true}
    />
  </div>
);

export default Select;
