import { If, Loader } from "components";
import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { useDashboardNavigation } from "hooks/useDashboardNavigation";
import { usePreviousWhen } from "hooks/usePreviousWhen";
import { FunctionComponent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { To, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { getChargeStations } from "store/slices/chargestations";
import { DashboardView } from "store/slices/dashboard/types";
import { getVehicles } from "store/slices/vehicles";
import { FilterMenu, InfoPanel, MapView, TableView } from "./components";
import Toggler from "./components/Toggler/Toggler";
import "./styles.scss";

const Dashboard: FunctionComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateCb = useCallback(
    (to: To) => {
      navigate(to, { replace: true });
    },
    [navigate]
  );

  const [dashboard, changeDashboard] = useDashboardNavigation(navigateCb);
  const isMapView = dashboard.view === DashboardView.Map;

  const sub = useSubscription();
  const loaded = useSelector((state: RootState) => {
    const cs = state.chargeStations.wasInitialLoad ?? false;
    const vh = state.vehicles.wasInitialLoad ?? false;
    return { cs, vh };
  });

  const showSpinner =
    !sub.isInitialized ||
    (sub.checkSubscription(Subscription.ChargingStation) &&
      loaded.cs === false) ||
    (sub.checkSubscription(Subscription.Vehicle) && loaded.vh === false);

  useEffect(() => {
    if (sub.checkSubscription(Subscription.ChargingStation)) {
      dispatch(getChargeStations());
    }
    if (sub.checkSubscription(Subscription.Vehicle)) {
      dispatch(getVehicles());
    }
  }, [dispatch, sub]);

  const filters = usePreviousWhen({
    ev: dashboard.ev,
    cv: dashboard.cv,
    cs: dashboard.cs,
  });
  return (
    <>
      <section className="p-dashboard__wrapper" data-testid="dashboard-page">
        <div className="p-dashboard__left-panel">
          {dashboard.panel !== null ? (
            <InfoPanel
              type={dashboard.panel}
              itemId={dashboard.itemId!}
              onBack={() =>
                changeDashboard({
                  itemId: null,
                  panel: null,
                  showNearest: null,
                })
              }
            />
          ) : (
            <FilterMenu
              filters={filters}
              enabledPanels={dashboard.filters}
              onTogglePanels={(filters) => changeDashboard({ filters })}
              onFilter={(filter) => changeDashboard(filter)}
            />
          )}
        </div>
        <If value={showSpinner}>
          <Loader className="p-dashboard__spinner" />
        </If>
        <If value={!showSpinner}>
          <If value={isMapView}>
            <MapView
              filters={filters}
              enabledPanels={dashboard.filters}
              panelType={dashboard.panel}
              showNearestStations={dashboard.showNearest}
              selectedItemId={dashboard.itemId}
              onSelectItem={(itemId, panel) =>
                changeDashboard({ itemId, panel })
              }
            />
          </If>
          <If value={!isMapView}>
            <TableView
              filters={filters}
              enabledPanels={dashboard.filters}
              panelType={dashboard.panel}
              showNearestStations={dashboard.showNearest}
              selectedItemId={dashboard.itemId}
              onSelectItem={(itemId, panel) =>
                changeDashboard({ itemId, panel })
              }
            />
          </If>
        </If>
        <Toggler
          currentView={isMapView}
          onToggle={(view: DashboardView) => changeDashboard({ view })}
        />
      </section>
    </>
  );
};

export default Dashboard;
