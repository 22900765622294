import Domain from "api/common/Domain";
import { IHttpClient } from "api/httpClient";
import strings from "assets/strings";
import { INotification } from "store/slices/notifications/types";
import { IODataClient, ODataClient } from "../../ODataClient";
import { IODataQuery } from "../../protocols/OData/types";
import urls from "../urls";
const str = strings.api.errors.messaging;

export class MessagingData extends Domain {
  httpClient: IHttpClient;
  odataClient: IODataClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
    this.odataClient = new ODataClient(httpClient);
  }

  async getData(query?: IODataQuery) {
    const route = "/query";
    const resource = "/UserNotifications";

    const data = await this.odataClient.getODataCollection<INotification>({
      url: `${urls.notifications}${route}${resource}`,
      query,
    });

    if (this.isError(data)) {
      throw data;
    }

    if (!Array.isArray(data?.value)) {
      throw new Error(str.fetchNotifications);
    }

    const { value: items, "@odata.count": total } = data;

    return { items, total };
  }
}
