import React from "react";
import "./styles.scss";
import { ISortOptions, ITableColumn } from "./types";

export const sortableTitleRenderer = <T,>(
  el: ITableColumn<T>,
  setSortOption: Function
) => {
  return (
    <div
      ///@ts-ignore
      key={`${el.key}`}
      className="rc-title"
      onClick={() => {
        if (!el.sortable) {
          return null;
        }
        setSortOption((prev: ISortOptions<T>) => {
          const sortKey = el.key as string;
          if (sortKey !== prev?.sortKey) {
            return { sortKey, sortDir: "asc" };
          }
          const sortDir = prev?.sortDir === "asc" ? "desc" : "asc";
          return { sortKey, sortDir };
        });
      }}
    >
      {el.title}
    </div>
  );
};
