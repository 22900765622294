import strings from "assets/strings";
import { addDays, format } from "date-fns";
import { Text } from "drax-design-system";
import { FunctionComponent, memo, useCallback, useState } from "react";
import { DatePickerOverlay } from "./DatePickerOverlay";
import "./styles.scss";
import {
  DatePickerModes,
  IDatePicker,
  IMultiModeDatePicker,
  ISingleDatePicker,
} from "./types";

const defaultModeChange = () => void 0;
const DatePicker: FunctionComponent<IDatePicker> = memo((props) => {
  const { selectedDate, onFilterApply } = props;

  const mode =
    (props as IMultiModeDatePicker).mode ??
    (props as ISingleDatePicker).singleMode;
  const onModeChange =
    (props as IMultiModeDatePicker).onModeChange ?? defaultModeChange;

  const allowModeChange = (props as ISingleDatePicker).singleMode == null;

  const [isOpen, setIsOpen] = useState(false);
  const {
    reports: { datePicker },
  } = strings;

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const formatDate = useCallback((mode: DatePickerModes): string => {
    switch (mode) {
      case DatePickerModes.WEEK:
        return "d MMM";
      case DatePickerModes.MONTH:
        return "MMM yyyy";
      case DatePickerModes.YEAR:
        return "yyyy";
      default: // or DAY
        return "d MMM yyyy";
    }
  }, []);

  return (
    <>
      <div
        className="consumption-date-picker__selected-dates"
        onClick={handleOpen}
        data-testid="selected-date-range"
      >
        {datePicker.chartDataFrom}
        <Text
          textColor="primary"
          className="consumption-date-picker__selected-dates-text"
        >
          {format(selectedDate, formatDate(mode))}
        </Text>
        {mode === DatePickerModes.WEEK && (
          <>
            {" "}
            -{" "}
            <Text
              textColor="primary"
              className="consumption-date-picker__selected-dates-text"
            >
              {format(addDays(selectedDate, 6), "d MMM yyyy")}
            </Text>
          </>
        )}
      </div>
      {isOpen && (
        <DatePickerOverlay
          mode={mode}
          selectedDate={selectedDate}
          onClose={handleClose}
          allowModeChange={allowModeChange}
          onFilterApply={(date, mode) => {
            onModeChange(mode);
            onFilterApply(date, mode);
          }}
        />
      )}
    </>
  );
});

export default DatePicker;
