import { ModalTypes } from "store/slices/modal/types";
import GenericError from "./GenericError";
import NotificationsDeleteAll from "./NotificationsDeleteAll/NotificationsDeleteAll";
import NotificationsMarkAsSeen from "./NotificationsMarkAllAsSeen/NotificationsMarkAsSeen";
import Success from "./Success/Success";

export const modalsDefinition = new Map<ModalTypes, () => JSX.Element | null>();
modalsDefinition.set(ModalTypes.NotificationsDeleteAll, NotificationsDeleteAll);
modalsDefinition.set(ModalTypes.GenericError, GenericError);
modalsDefinition.set(
  ModalTypes.NotificationsMarkAllAsSeen,
  NotificationsMarkAsSeen
);
modalsDefinition.set(ModalTypes.Success, Success);
