import classNames from "classnames";
import { Panel } from "drax-design-system";
import React, { FunctionComponent, ReactNode, useState } from "react";
import CollapibleButton from "./CollapsibleButton";
import "./styles.scss";

interface ICollapsiblePanel {
  children: ReactNode;
  className?: string;
  panelHeader: ReactNode;
}

const CollapsiblePanel: FunctionComponent<ICollapsiblePanel> = ({
  children,
  className,
  panelHeader,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const handleToggle = () => {
    setOpen((open) => !open);
  };

  return (
    <Panel className={className} {...props}>
      {panelHeader}
      <div
        className={classNames("p-collapsible__body", {
          "p-collapsible__body--collapsed": !open,
        })}
      >
        {open && children}
      </div>
      <CollapibleButton
        isOpen={open}
        onCollapse={handleToggle}
        data-testid="collapsible-button"
      />
    </Panel>
  );
};

export default CollapsiblePanel;
