import { Badge } from "drax-design-system";
import React from "react";
import { VehicleStatus } from "store/slices/vehicles/types";
import { generateBadge } from "utils/vehicleBadgeHelper";
import "./styles.scss";

interface IVehicleStatusList {
  statuses: VehicleStatus[];
}

const VehicleStatusList: React.FC<IVehicleStatusList> = React.memo(
  ({ statuses }) => {
    const badges = statuses.map((s) => generateBadge(s));

    return (
      <>
        {badges.map((badge, idx) => {
          if (!badge) {
            return null;
          }

          return (
            <div className={"badge-right-wrap"} key={idx}>
              <Badge
                appearance="secondary"
                color={badge.color}
                title={badge.text}
                key={badge.text}
              />
            </div>
          );
        })}
      </>
    );
  }
);
export default VehicleStatusList;
