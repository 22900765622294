export interface IModal {
  type: ModalTypes | null;
  message?: string;
  shouldLogOut?: boolean;
  blocking?: boolean;
}

export enum ModalTypes {
  NotificationsDeleteAll = "NotificationsDeleteAll",
  NotificationsMarkAllAsSeen = "NotificationsMarkAllAsSeen",
  Success = "Success",
  GenericError = "GenericError",
}
