import strings from "assets/strings";
import { Icons, Text } from "drax-design-system";
import { Link } from "react-router-dom";
import "./styles.scss";

interface IBackLink {
  linkUrl: string;
}
const BackLink: React.FC<IBackLink> = ({ linkUrl }) => (
  <Link to={linkUrl}>
    <Text className="p-back-link text-color-primary">
      <Icons.IcChevronLeft height="25" />
      {strings.general.back}
    </Text>
  </Link>
);

export default BackLink;
