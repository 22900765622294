import { IChargeStationsState } from "./types";

export const initialState: IChargeStationsState = {
  wasInitialLoad: false,
  chargeStations: [],
  chargeStationDetails: {},
  csPerformance: {
    availability: {
      current: 0,
      previous: 0,
    },
    utilisation: {
      current: 0,
      previous: 0,
    },
  },
  chargeStationsError: false,
};
