import { ChargeStationStatus } from "store/slices/chargestations/types";

export interface IChargingStationBase {
  id: string;
  coordinates: ILatLng;
  name: string;
  address: IFullAddress;
  lastUpdated: string | null;
  warrantyExpiry: string | null;
  loadManagement: string;
}

export interface IChargingStation extends IChargingStationBase {
  totalPorts: number;
  ports: IChargingStationPortBase[];
}

export interface IChargingStationFullDetails extends IChargingStationBase {
  ports: IChargingStationPort[];
  averageUtilisation: number;
}

export interface IChargingStationResponse {
  items: IChargingStation[];
}

/**
 * GET ChargingStation by LocationId
 */
export interface IFullAddress {
  addressLine: string;
  city: string;
  postalCode: string;
  country: string;
}

export interface ILatLng {
  latitude: number;
  longitude: number;
}

export interface IChargingStationPortBase {
  id: string;
  status: ChargeStationStatus;
  lastUpdated: Date | string;
  lastStatusUpdate: Date; // string;
  connectors: IConnectors[];
}

export interface IChargingStationPort extends IChargingStationPortBase {
  totalOutputToday: number;
  energyDelivered: number;
  lastVehicleChargedDate: Date | null;
  lastSessionUpdated?: string | null;
  lastInitiatedById?: string | null;
  initiatedById?: string;
  sessionStart?: Date;
}

export interface IConnectors {
  amperage: number;
  format: string;
  id: number;
  powerType: string;
  standard: string;
  voltage: number;
}

export interface IUtilistion {
  current: number;
  previous: number;
}

export interface IAvailability {
  current: number;
  previous: number;
}

export interface IPerformancePayload {
  utilisation: IUtilistion;
  availability: IAvailability;
}

export interface IChargeSessionsPayload {
  chargeSessions: ChargeSession[];
}

export interface ChargeSession {
  id: string;
  chargingStationId: string;
  startTime: string;
  endTime: string;
  volume: number;
  initiatedBy: string;
  locationId: string;
  locationName: string;
}

export interface ChargeSessionLocation {
  locationName: string;
  locationId: string;
}

export interface ICSSavingsResponse {
  numberTrees: number | null;
  poundSterling: number | null;
  cO2Emissions: number | null;
}

export interface IUpTimeTrendsResponse {
  lastFaultTime: number | null;
  bestUptime: number | null;
}

export interface FinanceReportingData {
  id: string;
  authId: string;
  premise: Premise;
  chargingStationId: string;
  tariff?: number;
  totalVolume: number;
  totalAmount: number;
  startDate: Date;
  endDate: Date;
}

export type FinanceReportingResponse = FinanceReportingData[];

export interface TariffUpdateRequest {
  authId: string;
  chargingStationId: string;
  tariff: number;
  activeFrom: Date;
  activeTo: Date;
}

export interface IFinancialReportingSummaryResponse {
  chargersTotalAmount: number | null;
}

export enum Premise {
  Home = "Home",
  Business = "Business",
  Both = "Both",
}
