import { IVehicleDataState } from "./types";

export const initialState: IVehicleDataState = {
  wasInitialLoad: false,
  eVPerformance: {
    ecoSafetyScore: {
      current: 0,
      previous: 0,
    },
  },

  vehicles: [],
  vehiclesError: false,
  details: {},
};
