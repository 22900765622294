import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useEventListener } from "../hooks/useEventListener";

type Size = {
  height: number;
  width?: number;
};

export interface AutoExpanderProps {
  children: (size: Size, outerRef: React.Ref<any>) => JSX.Element;
  containerRef: RefObject<HTMLDivElement>;
  renderDeps?: Array<any>;
}

export const AutoExpander = (props: AutoExpanderProps) => {
  const { children, containerRef, renderDeps } = props;

  const [height, setHeight] = useState(1);
  const listRef = useRef<HTMLElement>(null);

  const calcChildrenHeight = useCallback(() => {
    if (!(containerRef.current && listRef.current)) {
      return;
    }
    const containerHeight = containerRef.current.clientHeight;
    const listOffset = listRef.current.offsetTop;
    const style = getComputedStyle(containerRef.current);
    const paddingBottom = parseInt(style.paddingBottom);
    const height = containerHeight - listOffset - paddingBottom;
    setHeight(height);
  }, [containerRef, listRef]);

  useEffect(() => {
    calcChildrenHeight();
    // eslint-disable-next-line
  }, renderDeps ?? []);

  useEventListener("resize", calcChildrenHeight, 250);

  return children({ height }, listRef);
};
