import strings from "assets/strings";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCSPerformanceMetrics,
  selectChargeStationsError,
  selectChargeStationsPerformance,
} from "store/slices/chargestations";
import { TrendTarget, TrendValue, TrendWrapper } from "../Trend/TrendWrapper";

export const ChargingStationsPerformanceTrends = () => {
  const {
    reports: {
      trends: { thisMonth },
      utilisation: { title: utilisation },
      availability: { title: availability },
    },
  } = strings;

  const dispatch = useDispatch();

  useEffect(() => {
    // CS: utilization and Availability
    dispatch(getCSPerformanceMetrics());
  }, [dispatch]);

  const csPerformance = useSelector(selectChargeStationsPerformance);
  const chargeStationsError = useSelector(selectChargeStationsError);

  return (
    <>
      <TrendWrapper
        testId="cs-utilisation-trend"
        title={utilisation}
        subTitle={thisMonth}
        prevValue={csPerformance?.utilisation?.previous}
        currValue={csPerformance?.utilisation?.current}
        error={chargeStationsError}
        errorTestId={"charge-station-data-error"}
        ValueComponent={TrendValue}
        TargetComponent={TrendTarget}
      />

      <TrendWrapper
        testId="cs-availability-trend"
        title={availability}
        subTitle={thisMonth}
        prevValue={csPerformance?.availability?.previous}
        currValue={csPerformance?.availability?.current}
        error={chargeStationsError}
        errorTestId={"charge-station-data-error"}
        ValueComponent={TrendValue}
        TargetComponent={TrendTarget}
      />
    </>
  );
};
