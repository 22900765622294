import strings from "assets/strings";
import InformationTooltip from "components/InformationTooltip";
import "./styles.scss";

export const TreesSavedTooltipEV = () => {
  const {
    reports: {
      insights: { treesSaved },
    },
  } = strings;
  return (
    <InformationTooltip
      title={treesSaved}
      description={
        <>
          <p>
            Based on the avoidance of CO2 emitted by diesel vehicles over the
            number of miles the EVs have travelled. CO2 saved in trees is a
            calculation of how much <b>CO2 a tree absorbs in a year.</b>
          </p>
          <p>
            We have introduced forest size, so it makes it easier to visualise
            the number of trees your carbon-friendly operations are saving.
          </p>
          <div className="explanation-list">
            Calculations based on:
            <ul>
              <li>
                An Oak tree of yield class 3, able to sequester 29 kg of carbon
                per year
              </li>
              <li>EV - Nissan eNV200, and as such do not emit any CO2</li>
              <li>Fossil fuel equivalent - Nissan NV200 Combi 1.5 Acenta</li>
              <li>
                Emissions value sourced from weblink{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/co2-and-vehicle-tax-tools"
                  rel="noreferrer"
                >
                  https://www.gov.uk/co2-and-vehicle-tax-tools
                </a>
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};

export const TreesSavedTooltipCS = () => {
  const {
    reports: {
      insights: { treesSaved },
    },
  } = strings;
  return (
    <InformationTooltip
      title={treesSaved}
      description={
        <>
          <p>
            Based on the avoidance of CO2 emitted by diesel vehicles over the
            number of miles the EVs have travelled. (Estimated using an average
            m/kWh based on your energy delivered) CO2 saved in trees is a
            calculation of how much <b>CO2 a tree absorbs in a year.</b>
          </p>
          <p>
            We have introduced forest size, so it makes it easier to visualise
            the number of trees your carbon-friendly operations are saving.
          </p>
          <div className="explanation-list">
            Calculations based on:
            <ul>
              <li>
                An Oak tree of yield class 3, able to sequester 29 kg of carbon
                per year
              </li>
              <li>EV - Nissan eNV200, and as such do not emit any CO2</li>
              <li>Fossil fuel equivalent - Nissan NV200 Combi 1.5 Acenta</li>

              <li>
                Emissions value sourced from weblink{" "}
                <a
                  target="_blank"
                  href="https://www.gov.uk/co2-and-vehicle-tax-tools"
                  rel="noreferrer"
                >
                  https://www.gov.uk/co2-and-vehicle-tax-tools
                </a>
              </li>
            </ul>
          </div>
        </>
      }
    />
  );
};
