import { FunctionComponent } from "react";
import { ChargeStation } from "store/slices/chargestations/types";
import { VehicleFuelType } from "store/slices/vehicles/types";
import ChargeStationsTooltip from "./ChargeStationsTooltip";
import CombustionToolTip from "./CombustionVehiclesTooltip";
import ElectricVehiclesTooltip from "./ElectricVehiclesTooltip";
import "./styles.scss";

interface IToolTip {
  type: VehicleFuelType | ChargeStation.Type;
  dataId: string;
}

const EntityToolTip: FunctionComponent<IToolTip> = ({ type, dataId }) => {
  switch (type) {
    case ChargeStation.Type: {
      return <ChargeStationsTooltip dataId={dataId} />;
    }
    case VehicleFuelType.Electric: {
      return <ElectricVehiclesTooltip dataId={dataId} />;
    }
    default: {
      return <CombustionToolTip dataId={dataId} />;
    }
  }
};
export default EntityToolTip;
