import { useEffect } from "react";
import { debounce } from "utils/debounce";

export const useEventListener = <K extends keyof WindowEventMap>(
  type: K,
  listener: (this: Window, ev: WindowEventMap[K]) => any,
  timeout?: number
) => {
  useEffect(() => {
    const debounceListener =
      typeof timeout === "number" && timeout > 0
        ? debounce(listener, timeout)
        : listener;
    window.addEventListener(type, debounceListener);
    return () => {
      window.removeEventListener(type, debounceListener);
    };
  }, [type, listener, timeout]);
};
