import strings from "assets/strings";
import Modal from "components/Modal";
import { Button, Grid, Heading } from "drax-design-system";
import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from "store/slices/modal";
import { setNotificationsAsRead } from "store/slices/notifications";

const NotificationsMarkAsSeen = () => {
  const dispatch = useDispatch();
  const { notificationStrings } = strings;

  return (
    <Modal data-testid="mark-as-seen-modal">
      <Grid template="1fr">
        <Heading.h3>{notificationStrings.seenAll}</Heading.h3>
        <Grid template="1fr 1fr">
          <Button
            onClick={() => dispatch(setModalType({ type: null }))}
            title={notificationStrings.cancel}
            appearance="secondary"
          ></Button>
          <Button
            onClick={() => {
              dispatch(setNotificationsAsRead());
              dispatch(setModalType({ type: null }));
            }}
            title={notificationStrings.seenAll}
            appearance="primary"
          ></Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NotificationsMarkAsSeen;
