import strings from "assets/strings";
import classNames from "classnames";
import {
  SubReportingFinance,
  Subscription,
  SubscriptionTemplate,
  useSubscription,
} from "contexts/subscriptionContext";
import { Container, Heading } from "drax-design-system";
import {
  ChargingStationsPerformanceTrends,
  ElectricVehiclePerformanceTrends,
} from "pages/Reports/components/TrendingUsage";
import {
  ChargingStationsTrends,
  FinancialReportingBusinessChargersTrends,
  FinancialReportingHomeChargersTrends,
  VehicleUsageTrends,
} from "pages/Reports/components/Usage";
import { FunctionComponent } from "react";
import { Insights } from "../../components";
import "./styles.scss";

const Landing: FunctionComponent = () => {
  const {
    reports: {
      insights: { projectedSavings },
      trends: { trendingUsage, usage },
    },
  } = strings;

  const sub = useSubscription();
  const hasCs = sub.checkSubscription(Subscription.ChargingStation);
  const hasVeh = sub.checkSubscription(Subscription.Vehicle);
  const hasFin = sub.checkSubscription(SubReportingFinance);

  const wrap =
    [hasCs ? 2 : 0, hasVeh ? 2 : 0, hasFin ? 2 : 0].reduce((v, a) => v + a, 0) >
    4;

  return (
    <Container layout="maxWidth">
      <Heading.h2 textColor="tertiary">Reports</Heading.h2>
      {/* Savings*/}
      <section className="trends-section" data-testid={"insights"}>
        <Heading.h4 textColor="tertiary">{projectedSavings}</Heading.h4>
        <div className="trends-group-wrappper">
          <Insights />
        </div>
      </section>
      <div
        className={classNames("trends-container-wrapper", {
          "fixed-width": hasVeh && !hasCs && !hasFin,
        })}
      >
        {/* Usage*/}
        <section className="trends-section" data-testid={"usage"}>
          <Heading.h4 textColor="tertiary">{usage}</Heading.h4>
          <div
            className={classNames("trends-group-wrappper", {
              "trends-wrap": wrap,
            })}
          >
            <SubscriptionTemplate sub={Subscription.ChargingStation}>
              <ChargingStationsTrends />
            </SubscriptionTemplate>
            <SubscriptionTemplate sub={Subscription.Vehicle}>
              <VehicleUsageTrends />
            </SubscriptionTemplate>
            <SubscriptionTemplate sub={SubReportingFinance}>
              <FinancialReportingHomeChargersTrends />
              <FinancialReportingBusinessChargersTrends />
            </SubscriptionTemplate>
          </div>
        </section>
        {/* Trending usage: performance */}
        <section className="trends-section" data-testid={"trends"}>
          <Heading.h4 textColor="tertiary">{trendingUsage}</Heading.h4>
          <div className="trends-group-wrappper">
            <SubscriptionTemplate sub={Subscription.ChargingStation}>
              <ChargingStationsPerformanceTrends />
            </SubscriptionTemplate>
            <SubscriptionTemplate sub={Subscription.Vehicle}>
              <ElectricVehiclePerformanceTrends />
            </SubscriptionTemplate>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default Landing;
