import { CombustionCar } from "assets/images/svgs";
import strings from "assets/strings";
import { Panel } from "drax-design-system";
import { IFilterProps } from "pages/Dashboard/types";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectCombustionVehicles } from "store/selectors";
import { selectVehiclesError } from "store/slices/vehicles";
import { VehicleStatus } from "store/slices/vehicles/types";
import Filter from "../Filter";
import FilterError from "./FilterError";
import {
  combustionVehicleStats,
  enumEntries,
  genVehicleStats,
  vehicleFilterStates,
} from "./generateStats";

const CombustionVehiclesFilter: FunctionComponent<IFilterProps> = ({
  enabled,
  onToggle,
  filters,
  onFilter,
}) => {
  const {
    general: { combustionVehiclesTitle },
    dashboard: {
      infoPanel: {
        vehiclePanel: { error },
      },
    },
  } = strings;

  const vehiclesError = useSelector(selectVehiclesError);
  const combustionVehicles = useSelector(selectCombustionVehicles);

  const statuses = useMemo(
    () =>
      genVehicleStats(combustionVehicles, {
        ...combustionVehicleStats,
      }),
    [combustionVehicles]
  );

  return (
    <Panel.Collapsible
      id="combustion-vehicles-filter-menu"
      data-testid="combustion-vehicles-filter-menu"
      title={`${combustionVehicles.length} ${combustionVehiclesTitle}`}
      icon={<CombustionCar className="small-icon" />}
      isEnabled={enabled}
      onToggle={(e) => e !== enabled && onToggle()}
    >
      <div>
        {enabled && (
          <div>
            {!vehiclesError &&
              enumEntries<VehicleStatus, number>(statuses).map(
                ([key, value]) => (
                  <Filter
                    key={key}
                    name={combustionVehiclesTitle}
                    maxValue={combustionVehicles.length}
                    currentValue={value}
                    title={strings.vehicleStatus[key]}
                    filter={() => onFilter(key)}
                    isSelected={filters.includes(key)}
                    state={vehicleFilterStates[key]}
                  />
                )
              )}
            {vehiclesError && <FilterError error={error} />}
          </div>
        )}
      </div>
    </Panel.Collapsible>
  );
};

export default CombustionVehiclesFilter;
