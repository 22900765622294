import classNames from "classnames";
import { Text } from "drax-design-system";
import React from "react";
import "./styles.scss";

interface IIconButton {
  text: string;
  icon?: JSX.Element;
  iconAlign?: string;
  className?: string;
  onClick?: () => void;
}

const IconButton: React.FC<IIconButton> = ({
  text,
  icon,
  iconAlign,
  className,
  onClick,
  ...props
}) => (
  <Text
    className={classNames(
      "p-icon-button text-color-primary bold",
      iconAlign === "right" && "p-icon-button--right-icon",
      className
    )}
    onClick={onClick}
    {...props}
  >
    {icon}
    <span>{text}</span>
  </Text>
);

export default IconButton;
