import { IChargingStation } from "api/domains/chargingStations/types";
import { IVehicle } from "api/domains/vehicles/types";
import { ProgressBarType } from "components/ProgressBar/ProgressBar";
import { ChargeStationStatus } from "store/slices/chargestations/types";
import {
  CombustionVehicleStatus,
  VehicleStatus,
} from "store/slices/vehicles/types";
import { unique } from "utils/stringHelpers";

type VehicleStats = {
  [key in VehicleStatus]: number;
};

type CombustionVehicleStats = {
  [key in CombustionVehicleStatus]: number;
};

type ChargeStationStats = {
  [key in ChargeStationStatus]: number;
};

type ChargeStationFilterStates = {
  [key in ChargeStationStatus]?: ProgressBarType;
};

export const enumEntries = <K, T>(obj: { [s: string]: T } | ArrayLike<T>) => {
  return Object.entries(obj) as any as [K, T][];
};

export const vehicleStats: VehicleStats = {
  [VehicleStatus.Stationary]: 0,
  [VehicleStatus.InTransit]: 0,
  [VehicleStatus.Charging]: 0,
  [VehicleStatus.UnderCharged]: 0,
  [VehicleStatus.Fault]: 0,
  [VehicleStatus.Unknown]: 0,
};

type VehicleFilterStates = {
  [key in VehicleStatus]: ProgressBarType;
};

export const vehicleFilterStates: VehicleFilterStates = {
  [VehicleStatus.Stationary]: "primary",
  [VehicleStatus.InTransit]: "primary",
  [VehicleStatus.Charging]: "secondary",
  [VehicleStatus.UnderCharged]: "warning",
  [VehicleStatus.Fault]: "error",
  [VehicleStatus.Unknown]: "error",
};

export const combustionVehicleStats: CombustionVehicleStats = {
  [CombustionVehicleStatus.Stationary]: 0,
  [CombustionVehicleStatus.InTransit]: 0,
  [CombustionVehicleStatus.Fault]: 0,
  [CombustionVehicleStatus.Unknown]: 0,
};

export const chargeStationFiltersStates: ChargeStationFilterStates = {
  [ChargeStationStatus.AVAILABLE]: "secondary",
  [ChargeStationStatus.BLOCKED]: "secondary",
  [ChargeStationStatus.CHARGING]: "secondary",
  [ChargeStationStatus.OUTOFORDER]: "error",
  [ChargeStationStatus.UNKNOWN]: "warning",
};

export const genVehicleStats = (
  data: IVehicle[],
  vehicleStats: VehicleStats | CombustionVehicleStats
): VehicleStats | CombustionVehicleStats => {
  const updatedStats = data.reduce((acc, curr) => {
    curr.statuses.forEach((s) => {
      // @ts-ignore
      if (acc[s] == null) return;
      // @ts-ignore
      acc[s] += 1;
    });
    return acc;
  }, vehicleStats);

  if (!vehicleStats[VehicleStatus.Unknown]) {
    // @ts-ignore
    delete vehicleStats[VehicleStatus.Unknown];
  }

  return updatedStats;
};

export const genChargeStationStats = (
  data: IChargingStation[]
): ChargeStationStats => {
  const chargeStationStats: ChargeStationStats = {
    [ChargeStationStatus.AVAILABLE]: 0,
    [ChargeStationStatus.BLOCKED]: 0,
    [ChargeStationStatus.CHARGING]: 0,
    [ChargeStationStatus.INOPERATIVE]: 0,
    [ChargeStationStatus.OUTOFORDER]: 0,
    [ChargeStationStatus.PLANNED]: 0,
    [ChargeStationStatus.REMOVED]: 0,
    [ChargeStationStatus.RESERVED]: 0,
    [ChargeStationStatus.UNKNOWN]: 0,
  };

  return data
    .map((it) => unique(it.ports, "status"))
    .reduce((a, e) => [...a, ...e], [])
    .reduce((acc, el) => {
      acc[el.status] += 1;
      return acc;
    }, chargeStationStats);
};
