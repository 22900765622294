import { Dispatch, MiddlewareAPI } from "redux";
import { IConnectionHandler } from "services/Connector";
import { RootState } from "store";
import {
  setAllChargingStations,
  updateChargeStationDetailsData,
} from "store/slices/chargestations";
import {
  mergeLiveCsData,
  mergeSessionUpdateData,
} from "store/slices/chargestations/helpers";
import {
  ChargeStationEvents,
  ILiveCsUpdate,
  ILiveSessionUpdate,
} from "store/slices/chargestations/types";

export const csUpdateHandler = (
  store: MiddlewareAPI<Dispatch<any>, RootState>,
  csUpdateData: ILiveCsUpdate[]
) => {
  const {
    chargeStations: { chargeStations },
  } = store.getState();

  if (!chargeStations.length) return chargeStations;

  return store.dispatch(
    setAllChargingStations({
      data: mergeLiveCsData(csUpdateData, chargeStations),
    })
  );
};

export const csSessionUpdateHandler = (
  store: MiddlewareAPI<Dispatch, RootState>,
  sessionUpdateData: ILiveSessionUpdate
) => {
  const {
    chargeStations: { chargeStationDetails },
  } = store.getState();
  const id = sessionUpdateData.chargingStationId;
  if (chargeStationDetails?.[id]?.data != null) {
    const station = chargeStationDetails[id].data;
    store.dispatch(
      updateChargeStationDetailsData({
        data: mergeSessionUpdateData(sessionUpdateData, station),
        locationId: id,
      })
    );
  }
};

export const chargingStationsHandlersFactory = (
  api: MiddlewareAPI<Dispatch, RootState>
): IConnectionHandler[] => {
  return [
    {
      connectionEvent: ChargeStationEvents.UpdateStatus,
      connectionHandler: (csData: ILiveCsUpdate[]) =>
        csUpdateHandler(api, csData),
    },
    {
      connectionEvent: ChargeStationEvents.UpdateSession,
      connectionHandler: (sessionUpdate: ILiveSessionUpdate) =>
        csSessionUpdateHandler(api, sessionUpdate),
    },
  ];
};
