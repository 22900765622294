export interface IChart {
  title: string;
  description?: string;
  backLink?: string | null;
  hasFetchError?: boolean;
  hasData?: boolean;
  isLoading?: boolean;
  backLinkAction?: () => void;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export const ChartTypesObj = {
  bar: "bar",
  line: "line",
  scatter: "scatter",
  bubble: "bubble",
  pie: "pie",
  doughnut: "doughnut",
  polarArea: "polarArea",
  radar: "radar",
};

export const PositionTypeObj = {
  left: "left",
  right: "right",
};

export type ChartTypeKeys = keyof typeof ChartTypesObj;
export type PositionTypeKeys = keyof typeof PositionTypeObj;
