import {
  Subscription,
  SubscriptionTemplate,
} from "contexts/subscriptionContext";
import { Container, Grid } from "drax-design-system";
import { flipArrayValue } from "pages/Dashboard/dashboardUtils";
import { FiltersType, PanelType } from "pages/Dashboard/types";
import React, { FunctionComponent, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectCombustionVehicles } from "store/selectors";
import {
  ChargeStationsFilter,
  CombustionVehiclesFilter,
  ElectricVehiclesFilter,
} from "./FilterSections";
import NotificationBox from "./NotificationBox";
import "./styles.scss";

interface FilterMenuProps {
  enabledPanels: Array<PanelType>;
  filters: FiltersType;
  onTogglePanels: (p: Array<PanelType>) => void;
  onFilter: (p: Partial<FiltersType>) => void;
}

const FilterMenu: FunctionComponent<FilterMenuProps> = ({
  enabledPanels,
  filters,
  onTogglePanels,
  onFilter,
}) => {
  const combustionVehicles = useSelector(selectCombustionVehicles);

  const flipEnabledPanelsCb = useCallback(
    (p: PanelType) => {
      onTogglePanels(flipArrayValue(enabledPanels, p));
    },
    [onTogglePanels, enabledPanels]
  );

  const onEvFilterCb = useCallback(
    (p: string) => {
      onFilter({ ev: flipArrayValue(filters.ev, p) });
    },
    [onFilter, filters.ev]
  );

  const onCvFilterCb = useCallback(
    (p: string) => {
      onFilter({ cv: flipArrayValue(filters.cv, p) });
    },
    [onFilter, filters.cv]
  );

  const onCsFilterCb = useCallback(
    (p: string) => {
      onFilter({ cs: flipArrayValue(filters.cs, p) });
    },
    [onFilter, filters.cs]
  );

  return (
    <Container className="p-filter-menu__container">
      <Grid template="1fr" gap="var(--spacing-gap-xxs)">
        <NotificationBox />
        <SubscriptionTemplate sub={Subscription.ChargingStation}>
          <ChargeStationsFilter
            filters={filters.cs}
            enabled={enabledPanels.includes("cs")}
            onToggle={() => flipEnabledPanelsCb("cs")}
            onFilter={onCsFilterCb}
          />
        </SubscriptionTemplate>
        <SubscriptionTemplate sub={Subscription.Vehicle}>
          <ElectricVehiclesFilter
            filters={filters.ev}
            enabled={enabledPanels.includes("ev")}
            onToggle={() => flipEnabledPanelsCb("ev")}
            onFilter={onEvFilterCb}
          />
        </SubscriptionTemplate>
        <SubscriptionTemplate sub={Subscription.Vehicle}>
          {!!combustionVehicles.length && (
            <CombustionVehiclesFilter
              filters={filters.cv}
              enabled={enabledPanels.includes("cv")}
              onToggle={() => flipEnabledPanelsCb("cv")}
              onFilter={onCvFilterCb}
            />
          )}
        </SubscriptionTemplate>
      </Grid>
    </Container>
  );
};

export default FilterMenu;
