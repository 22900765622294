import React from "react";
import { FunctionComponent } from "react";
import "./styles.scss";
import { Loader, PageError } from "../../../../components";
import strings from "../../../../assets/strings";

export const ReportChartContent: FunctionComponent<{
  hasFetchError?: boolean;
  hasData?: boolean;
  isLoading?: boolean;
}> = (props) => {
  const { isLoading, hasFetchError, hasData } = props;
  const { children } = props;
  const { noData, energyConsumption } = strings.api.errors;

  if (hasFetchError) {
    return <PageError withPanel={false} message={energyConsumption.error} />;
  }

  if (isLoading) {
    return <Loader className="p-chart__loader" />;
  }

  if (!hasData) {
    return (
      <PageError
        withPanel={false}
        title={noData}
        message={energyConsumption.noData}
      />
    );
  }
  return <>{children}</>;
};
