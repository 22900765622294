import Domain from "api/common/Domain";
import { IHttpClient } from "api/httpClient";
import { IDomainRequestDetails } from "api/types";
import strings from "assets/strings";
import urls from "../urls";
import { IActionResult, IEnquiry } from "./types";
const str = strings.api.errors.support;

export class Enquiry extends Domain {
  httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
  }

  async makeEnquiry(data: IEnquiry) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url: urls.enquiry,
      data,
    };

    const response = await this.httpClient.request<IActionResult>(request);

    if (this.isError(response)) {
      throw new Error(str.makeEnquiry);
    }

    return response;
  }
}
