import { Close, Tick } from "assets/images/svgs";
import { StatusIconType } from "store/slices/dashboard/types";

interface IStatusIcon {
  type: StatusIconType | undefined;
}

const StatusIcon: React.FC<IStatusIcon> = ({ type }) => {
  const colors = {
    normal: "#82E1D7",
    warning: "#F2A521",
    error: "#F2216E",
  };

  const generateIcon = () => {
    if (!type) return "";

    switch (type) {
      case "error": {
        return <Close color={colors[type]} />;
      }
      case "warning": {
        return <Tick color={colors[type]} />;
      }

      default: {
        return <Tick color={colors.normal} />;
      }
    }
  };
  return <>{generateIcon()}</>;
};

export default StatusIcon;
