import {
  ChargeStation as ChargeStationIcon,
  CombustionCar,
  CombustionCarFaulty,
  ElectricCar,
  ElectricCarFaulty,
} from "assets/images/svgs";
import { FunctionComponent } from "react";
import { ChargeStation } from "store/slices/chargestations/types";
import { VehicleFuelType } from "store/slices/vehicles/types";

interface ITableIcon {
  type: VehicleFuelType | ChargeStation.Type;
  isFaulty: boolean | undefined;
}

const TableIcon: FunctionComponent<ITableIcon> = ({ type, isFaulty }) => {
  const generateIcon = () => {
    switch (type) {
      case VehicleFuelType.Electric: {
        return isFaulty ? (
          <ElectricCarFaulty className="small-icon" />
        ) : (
          <ElectricCar
            data-testid="electric-vehicle-icon"
            className="small-icon"
          />
        );
      }
      case ChargeStation.Type: {
        return (
          <ChargeStationIcon
            data-testid="charge-station-icon"
            className="small-icon"
          />
        );
      }
      default: {
        return isFaulty ? (
          <CombustionCarFaulty className="small-icon" />
        ) : (
          <CombustionCar
            data-testid="combustion-vehicle-icon"
            className="small-icon"
          />
        );
      }
    }
  };
  return <>{generateIcon()}</>;
};

export default TableIcon;
