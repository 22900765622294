import appConfig from "config/app";
import { IDashboardState } from "./types";

const {
  defaultCoords,
  zoom: { defaultZoom },
} = appConfig.app.dashboard.map;

export const initialState: IDashboardState = {
  mapSettings: {
    coords: defaultCoords,
    zoom: defaultZoom,
  },
};
