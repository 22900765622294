import { ChargingStations } from "./chargingStations";
import { Consumption } from "./consumption";
import { Enquiry } from "./enquiry";
import { Messaging } from "./messaging";
import { MessagingData } from "./messaging/messagingDataDomain";
import { Organisations } from "./organisations";
import { Vehicles } from "./vehicles";

export type DraxDomains = {
  ChargingStations: InstanceType<typeof ChargingStations>;
  Vehicles: InstanceType<typeof Vehicles>;
  Messaging: InstanceType<typeof Messaging>;
  MessagingData: InstanceType<typeof MessagingData>;
  Enquiry: InstanceType<typeof Enquiry>;
  Consumption: InstanceType<typeof Consumption>;
  Organisations: InstanceType<typeof Organisations>;
};

export type DomainTypes = {
  ChargingStations: typeof ChargingStations;
  Vehicles: typeof Vehicles;
  Messaging: typeof Messaging;
  Enquiry: typeof Enquiry;
  Consumption: typeof Consumption;
  MessagingData: typeof MessagingData;
  Organisations: typeof Organisations;
};

const domains: DomainTypes = {
  ChargingStations,
  Vehicles,
  Messaging,
  Enquiry,
  Consumption,
  MessagingData,
  Organisations,
};

export default domains;
