import config from "config/app";

const urls = {
  chargeStations: config.api.domain.chargeStations,
  chargeStationsFinance: config.api.domain.chargeStationsFinance,
  enquiry: config.api.domain.enquiry,
  notifications: config.api.domain.messaging,
  user: config.api.domain.users,
  vehicles: config.api.domain.vehicles,
  hubs: config.api.domain.hubs,
  consumption: config.api.domain.consumption,
  organisations: config.api.domain.organisations,
};

export default urls;
