/* istanbul ignore file */
import { StylesConfig } from "react-select";

const fontStyles = {
  color: "#216EF2",
  fontWeight: 600,
  fontSize: "16px",
};

export const stylesFactory = (p: {
  singleValueWidth?: string;
  menuWidth?: string;
}) => {
  const styles: StylesConfig<any, false> = {
    control(base, props) {
      const { isFocused } = props;
      let additionalStyles = {};

      if (isFocused) {
        additionalStyles = {
          border: "none",
          boxShadow: "none",
        };
      }

      return {
        ...base,
        cursor: "pointer",
        width: p.singleValueWidth ?? "170px",
        backgroundColor: "transparent",
        border: "none",
        textOverflow: "ellipsis",
        ...additionalStyles,
      };
    },
    dropdownIndicator(base) {
      return {
        ...base,
        display: "none",
      };
    },
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    singleValue: (base) => ({
      ...base,
      ...fontStyles,
    }),
    placeholder: (base) => ({
      ...base,
      textOverflow: "ellipsis",
      whiteSpace: "pre",
      overflow: "hidden",
      maxWidth: "calc(100% - 16px)",
      width: "100%",
      ...fontStyles,
    }),
    menu(base) {
      return {
        ...base,
        borderRadius: "8px",
        zIndex: 10,
        marginTop: "0px",
        width: p.menuWidth ?? "170px",
        left: "-35px",
      };
    },
    menuList(base) {
      return {
        ...base,
        padding: 0,
      };
    },
    option(base, props) {
      const { isFocused } = props;
      let additionalStyles = {};

      if (isFocused) {
        additionalStyles = {
          borderColor: "red",
          backgroundColor: "#F3F5F9",
        };
      }

      return {
        ...base,
        ...fontStyles,
        cursor: "pointer",
        textTransform: "capitalize",
        backgroundColor: "transparent",

        "&:hover": {
          backgroundColor: "#F3F5F9",
        },

        "&:first-of-type": {
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        },
        "&:last-child": {
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
        },
        ...additionalStyles,
      };
    },
  };
  return styles;
};

export const defaultStyles = stylesFactory({});
