import { GoogleMap } from "@react-google-maps/api";
import { IChargingStation, ILatLng } from "api/domains/chargingStations/types";
import { IVehicle } from "api/domains/vehicles/types";
import { default as config } from "config/app";
import { ILowRangeVehicle } from "pages/Dashboard/components/MapView/MapView";
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { mapContainerStyle, mapOptions } from "./mapConfig";
import ZoomControls from "./ZoomControls";

interface ICoords {
  lat: number;
  lng: number;
}

interface IMapProps {
  children?: React.ReactNode;
  coords?: ICoords;
  zoom?: number;
  containerStyle?: {
    width: string;
    height: string;
  };
  pins?: Array<IChargingStation | IVehicle>;
  lowRangeVehicle?: ILowRangeVehicle | null;
}

const {
  zoom: { defaultZoom },
} = config.app.dashboard.map;

const MapComponent: FunctionComponent<IMapProps> = ({
  children,
  zoom = defaultZoom,
  coords,
  containerStyle,
  pins,
  lowRangeVehicle,
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const headingsClockwise = [0, 90, 180, 270];
  const prevLowRangeVeh = useRef<ILowRangeVehicle>();

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(undefined);
  }, []);

  const generatePoints = ({ latitude, longitude }: ILatLng, range: number) => {
    const fromCoords = new google.maps.LatLng(latitude, longitude);
    return headingsClockwise.map((h) =>
      google.maps.geometry.spherical.computeOffset(fromCoords, range, h)
    );
  };

  const setBounds = (bounds: google.maps.LatLngBounds) => {
    if (!map) return;

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (!map) return;
    const bounds = new google.maps.LatLngBounds();

    if (!lowRangeVehicle && prevLowRangeVeh.current) {
      prevLowRangeVeh.current = undefined;
      return;
    }

    if (lowRangeVehicle) {
      const { range, coordinates } = lowRangeVehicle;
      prevLowRangeVeh.current = lowRangeVehicle;

      if (range > 0) {
        const points = generatePoints(coordinates, range);
        points.forEach((p) => bounds.extend(p));
        setBounds(bounds);
      }
    } else if (pins?.length) {
      pins.forEach((el: any) =>
        bounds.extend({
          lat: el.coordinates.latitude,
          lng: el.coordinates.longitude,
        })
      );

      setBounds(bounds);
    }
    // eslint-disable-next-line
  }, [map, pins, lowRangeVehicle]);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle || mapContainerStyle}
      zoom={zoom}
      center={coords}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{ ...mapOptions }}
    >
      {map && (
        <>
          {children}
          <ZoomControls map={map} />
        </>
      )}
    </GoogleMap>
  );
};

export default MapComponent;
