import strings from "assets/strings";
import { useState } from "react";

export const useSupportFormValidation = () => {
  const { support, form } = strings;
  const [phoneError, setPhoneError] = useState<string>("");
  const [messageError, setMessageError] = useState<string>("");

  const validatePhoneInput = (phone: string) => {
    if (!phone.length) {
      setPhoneError(form.requiredField);
    } else if (!/^(?:\W*\d){11}\W*$/.test(phone)) {
      setPhoneError(support.phone.error);
    } else {
      setPhoneError("");
    }
  };

  const validateMessageInput = (msg: string) => {
    if (!msg.length) {
      setMessageError(form.requiredField);
    } else if (msg.length < 10) {
      setMessageError(support.message.error);
    } else {
      setMessageError("");
    }
  };

  return {
    phoneError,
    messageError,
    validatePhoneInput,
    validateMessageInput,
  };
};
