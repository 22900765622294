import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { RootState } from "store";
import { initialState } from "./initialAuthState";
import { IAuthState, IUserPayload } from "./types";

const authState = createSlice({
  name: "authState",
  initialState: initialState,
  reducers: {
    setUser(
      state: IAuthState,
      { payload: { user } }: PayloadAction<IUserPayload>
    ) {
      state.user = user;
    },
  },
});

export const { setUser } = authState.actions;
export default authState;

export const isSetUser = (
  action: Action
): action is PayloadAction<IUserPayload> => {
  return (
    action.type === "authState/setUser" &&
    (action as any)?.payload?.hasOwnProperty?.("user")
  );
};

export const selectUserInfo = (state: RootState) => state.auth.user;
