import strings from "assets/strings";
import config from "config/app";
import {
  Grid,
  Heading,
  Loaders,
  ProgressBar,
  TextItem,
} from "drax-design-system";
import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectElectricVehicles } from "store/selectors";
import VehicleStatusList from "./VehicleStatusList";

interface IEvToolTips {
  dataId: string;
}

const EvToolTip: FunctionComponent<IEvToolTips> = ({ dataId }) => {
  const electricVehicles = useSelector(selectElectricVehicles);

  const {
    dashboard: {
      infoPanel: { vehiclePanel },
    },
  } = strings;

  const vehicle = electricVehicles.find((f) => f.id === dataId)!;

  if (!vehicle) {
    return (
      <div className="p-map-tooltips">
        <Loaders data-testid="loading-spinner" size={"1.5em"} type="spinner" />
      </div>
    );
  }

  const lowCharge = vehicle.stateOfCharge <= config.app.vehicleLowChargeLevel;

  return (
    <Grid className="p-map-tooltips">
      <Grid template="1fr 1fr">
        <Heading.h5 className="p-map-tooltips__vrn">{vehicle.id}</Heading.h5>
        <Grid template="1fr">
          <VehicleStatusList statuses={vehicle.statuses} />
        </Grid>
      </Grid>
      <Grid template="1fr">
        <div>
          <TextItem
            title={vehiclePanel.chargeLevel}
            value={`${Math.round(vehicle.stateOfCharge)}%`}
            className={`p-info-panel__charge-level${
              lowCharge ? "--low-charge" : ""
            }`}
            progress={
              <ProgressBar
                state={lowCharge ? "error" : "primary"}
                value={vehicle.stateOfCharge / 100}
              />
            }
          />
        </div>
        <div>
          <TextItem
            title={vehiclePanel.range}
            value={`${vehicle.range?.toFixed() || 0} ${vehiclePanel.miles}`}
          />
          <TextItem
            title={vehiclePanel.totalMileage}
            value={`${vehicle?.totalMileage?.toFixed() || 0} ${
              vehiclePanel.miles
            }`}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EvToolTip;
