import { Heading } from "drax-design-system";
import React from "react";
import { dateTitle } from "utils/dateFormat";
import Notification from "./Notification";
import "./styles.scss";
import { INotification } from "../../store/slices/notifications/types";
import { ListChildComponentProps } from "react-window";
import { Loader } from "../../components";

export const PADDING_SIZE = 4;
export const NOTIFICATION_ITEM_SIZE = 83;
export const DATE_ITEM_SIZE = 34;
const width = `calc(100% - 8px)`;
const margin = "0 2px";

type NotificationGroupItem = { notificationGroupDate: Date };

const isNotificationGroupItem = (item: any): item is NotificationGroupItem => {
  return item?.hasOwnProperty("notificationGroupDate");
};

export const getItemSizeFactory = (data: Array<any>) => (index: number) => {
  const item = data[index];
  if (isNotificationGroupItem(item)) {
    return DATE_ITEM_SIZE + 2 * PADDING_SIZE;
  }
  return NOTIFICATION_ITEM_SIZE + 2 * PADDING_SIZE;
};

export const NotificationsListItem = (
  props: ListChildComponentProps<{ items: INotification[] }>
) => {
  const { index, style, data } = props;
  const item = data.items[index];
  const styleHeight = parseFloat(style.height as string);

  if (item == null && data.items[index - 1] != null) {
    const height = styleHeight * 3;
    const nextStyle = { ...style, height, width, margin };
    return (
      <div style={nextStyle}>
        <Loader />
      </div>
    );
  }

  if (item == null) {
    return <div />;
  }

  const height = `${styleHeight - PADDING_SIZE * 2}px`;

  if (isNotificationGroupItem(item)) {
    const top = `${parseFloat(style.top as string) + PADDING_SIZE * 4}px`;
    const nextStyle = { ...style, top, height, width, margin };
    return (
      <div style={nextStyle} data-testid="notification-group-date">
        <Heading.h5 textColor="tertiary" textAlign="right">
          {dateTitle(item.notificationGroupDate)}
        </Heading.h5>
      </div>
    );
  }

  const top = `${parseFloat(style.top as string) + PADDING_SIZE}px`;
  const nextStyle = { ...style, top, height, width, margin };

  return <Notification style={nextStyle} {...item} />;
};
