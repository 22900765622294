import paths from "appRoutes/paths";
import strings from "assets/strings";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { getBusinessChargersTotalAmountData } from "store/slices/reports";
import { Scales } from "../Insights/reportSavingsData";
import { TrendError } from "../Trend";
import { FinancialTrend } from "../Trend/FinancialTrend";

export const FinancialReportingBusinessChargersTrends = () => {
  const {
    reports: {
      trends: { financialReporting, businessChargesTotalAmount, allTime },
    },
  } = strings;

  const businessChargesTotal = useSelector(
    (state: RootState) =>
      state.reports.finances.financialReporting.businessChargesTotal
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getBusinessChargersTotalAmountData());
  }, [dispatch]);

  const onForward = useCallback(() => {
    navigate(paths.financialReporting + "?premise=Business");
  }, [navigate]);

  if (businessChargesTotal.hasError) {
    return <TrendError />;
  }

  return (
    <>
      <FinancialTrend
        testId="ev-financial-reporting-business"
        value={businessChargesTotal.data}
        onForward={onForward}
        title={financialReporting}
        subTitle={businessChargesTotalAmount}
        valueTemplate="£{0}{1}"
        targetComponent={allTime}
        scales={Scales.Number}
      />
    </>
  );
};
