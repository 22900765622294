import { createSelector } from "@reduxjs/toolkit";
import { IVehicle } from "api/domains/vehicles/types";
import appConfig from "config/app";
import { getDistance } from "geolib";
import { selectChargingStations } from "store/selectors";
import { selectVehicles } from "store/slices/vehicles";
import { VehicleFuelType, VehicleStatus } from "store/slices/vehicles/types";
import { datesWithinTolerance } from "utils/dateFormat";
import { RootState } from "../store";

type IChargeStation = {
  stationId: string;
  portId: string;
};

export const selectElectricVehicles = createSelector(
  [selectVehicles],
  (vehicles) => {
    return vehicles.filter((veh: IVehicle) => {
      return veh.fuelType === VehicleFuelType.Electric;
    });
  }
);

export const selectCombustionVehicles = createSelector(
  [selectVehicles],
  (vehicles) => {
    return vehicles.filter((veh: IVehicle) => {
      return veh.fuelType === VehicleFuelType.Combustion;
    });
  }
);

export const selectFilteredElectricVehicles = createSelector(
  selectElectricVehicles,
  (_state: RootState, props: { filter: string[] }) => props.filter,
  (electricVehicles, electricFilters) => {
    if (!electricFilters.length) return electricVehicles;

    return electricVehicles.filter((v) =>
      electricFilters.some((f) => v.statuses?.includes(f as VehicleStatus))
    );
  }
);

export const selectFilteredCombustionVehicles = createSelector(
  selectCombustionVehicles,
  (_state: RootState, props: { filter: string[] }) => props.filter,
  (combustionVehicles, combustionFilters) => {
    if (!combustionFilters.length) return combustionVehicles;

    return combustionVehicles.filter((v) =>
      combustionFilters.some((f) => v.statuses?.includes(f as VehicleStatus))
    );
  }
);

export const selectChargingVehicles = createSelector(
  [selectVehicles],
  (vehicles) => {
    return vehicles.filter(
      (vehicle) => vehicle.status === VehicleStatus.Charging
    );
  }
);

/*
  Determines which vehicles are charging and at which stations.
  Gets a list of charging vehicles and stations that have ports with the charging status
  Determines the closest distance station to the vehicle and returns a list of vehicle ids and the closest station id
*/

export const selectChargingVehiclesAtStations = createSelector(
  selectChargingVehicles,
  selectChargingStations,
  (_state: RootState, props: { radius?: number }) =>
    props.radius ?? appConfig.app.vehicleToStationProximity,
  (chargingVehicles, chargingStations, radius) => {
    if (!chargingVehicles.length || !chargingStations.length) {
      return;
    }

    const vehiclesAtStation = chargingVehicles
      .map((vehicle) => {
        let chargingDetails: any = null;

        chargingStations.forEach((station) => {
          const distance = getDistance(
            vehicle.coordinates,
            station.coordinates
          );

          const distanceTolerance = distance <= radius;

          if (distanceTolerance) {
            station.ports.forEach((port) => {
              const timeTolerance = datesWithinTolerance(
                port.lastUpdated,
                vehicle.statusLastChangedDate
              );

              if (timeTolerance) {
                chargingDetails = {
                  stationId: station.id,
                  portId: port.id,
                };
              }
            });
          }
        });

        if (chargingDetails == null) {
          return null;
        }

        return {
          ...vehicle,
          stationId: chargingDetails.stationId,
          portId: chargingDetails.portId,
        };
      })
      .filter((x) => x != null) as Array<
      IVehicle & { stationId: string; portId: string }
    >;

    return vehiclesAtStation;
  }
);

/* 
  Hide the vehicles pins for vehicles that are currently charging at a charge station
  The charge station pin will be updated to show the vehicles charging at it
*/
export const selectMapElectricVehicles = createSelector(
  selectFilteredElectricVehicles,
  selectChargingVehiclesAtStations,
  (
    _: RootState,
    props: { csPanelVisible: boolean; hideChargingVehicles: boolean }
  ) => props.csPanelVisible,
  (
    _: RootState,
    props: { csPanelVisible: boolean; hideChargingVehicles: boolean }
  ) => props.hideChargingVehicles,
  (
    filteredVehicles,
    chargingVehicles,
    csPanelVisible,
    hideChargingVehicles
  ) => {
    let vehiclesToDisplay: Array<IVehicle> = [...filteredVehicles];

    if (csPanelVisible && hideChargingVehicles) {
      vehiclesToDisplay = filteredVehicles.filter((veh) => {
        const chargingVehicle = chargingVehicles?.find(
          (chVeh) => chVeh.id === veh.id
        );
        return !chargingVehicle;
      });
    }
    return vehiclesToDisplay;
  }
);

export const selectVehicleDetails = createSelector(
  (state: RootState, props: { vehicleId: string | null }) => props.vehicleId,
  (state: RootState) => state.vehicles.details,
  (vehicleId, details) => {
    return typeof vehicleId == "string" ? details[vehicleId] : null;
  }
);
