import { ElectricCar, ElectricCarFaulty } from "assets/images/svgs";
import strings from "assets/strings";
import { If } from "components";
import IconButton from "components/IconButton/IconButton";
import config from "config/app";
import {
  Badge,
  Grid,
  Icons,
  ProgressBar,
  Text,
  TextItem,
} from "drax-design-system";
import React from "react";
import gtmTags from "services/Analytics/GtmTags";
import { IVehicleDetailView, VehicleStatus } from "store/slices/vehicles/types";
import { generateBadge } from "utils/vehicleBadgeHelper";
import StationsInRange from "./StationsInRange";
import "./styles.scss";

export interface VehicleInfoPanelProps {
  vehicleDetails: IVehicleDetailView;
  showNearest: boolean;
  toggleShowNearest: () => void;
  openJourneys: () => void;
}

export const VehicleInfoPanel: React.FC<VehicleInfoPanelProps> = ({
  vehicleDetails,
  showNearest,
  toggleShowNearest,
  openJourneys,
}) => {
  const {
    dashboard: {
      infoPanel: { vehiclePanel },
    },
  } = strings;

  const {
    licensePlate,
    status,
    statuses,
    chargeLevel,
    range,
    ecoSafetyScore,
    totalMileage = 0,
    imei,
    averageUtilisation,
    averageMiles,
  } = vehicleDetails;

  const lowCharge = chargeLevel <= config.app.vehicleLowChargeLevel;
  const lowRange = range <= config.app.vehicleLowRangeLevel;
  const badge = generateBadge(status);

  return (
    <Grid template="1fr" gap="var(--spacing-gap-m)">
      <div>
        <div className="p-info-panel__title">
          {statuses.includes(VehicleStatus.Fault) ? (
            <ElectricCarFaulty className="p-info-panel__title-icon" />
          ) : (
            <ElectricCar className="p-info-panel__title-icon" />
          )}
          <Text size="h3" textColor="tertiary">
            {licensePlate}
          </Text>
        </div>
        <If value={badge !== null}>
          <Badge
            title={badge!.text}
            appearance="secondary"
            color={badge!.color}
          />
        </If>
      </div>
      <Grid template="1fr">
        <div data-testid="charge-level">
          <TextItem
            title="Charge level"
            value={`${Math.round(chargeLevel)}%`}
            className={`p-info-panel__charge-level${
              lowCharge ? "--low-charge" : ""
            }`}
            progress={
              <ProgressBar
                state={lowCharge ? "error" : "primary"}
                value={chargeLevel / 100}
              />
            }
          />
        </div>
        <div data-testid="range">
          <TextItem
            title={vehiclePanel.range}
            value={`${range?.toFixed() || 0} ${vehiclePanel.miles}`}
          ></TextItem>
        </div>
        <If value={lowRange}>
          <StationsInRange
            vehicleId={vehicleDetails.id /* vehicleId*/}
            show={showNearest}
            onToggleShow={toggleShowNearest}
          />
        </If>
      </Grid>

      <Grid template="1fr">
        <div data-testid="safety-score">
          <TextItem
            title={vehiclePanel.safetyScore}
            value={`${ecoSafetyScore?.toFixed(1) || 0}`}
          />
        </div>
        <div data-testid="av-utilisation">
          <TextItem
            title={vehiclePanel.avUtil}
            value={`${Math.round(averageUtilisation)}%`}
          />
        </div>
        <div data-testid="average-miles">
          <TextItem
            title={vehiclePanel.averageMiles}
            value={`${averageMiles?.toFixed() || 0}`}
          />
        </div>
        <div data-testid="total-mileage">
          <TextItem
            title={vehiclePanel.totalMileage}
            value={`${totalMileage?.toFixed() || 0} ${vehiclePanel.miles}`}
          />
        </div>
        <div data-testid="imei">
          <TextItem title={vehiclePanel.imei} value={imei} />
        </div>
        <div data-testid="vrn">
          <TextItem title={vehiclePanel.vrn} value={licensePlate} />
        </div>
      </Grid>
      <IconButton
        text={vehiclePanel.journeys}
        onClick={openJourneys}
        className={gtmTags.vehicleJourniesButton}
        data-testid="vehicle-journeys-button"
        icon={
          <Icons.IcChevronRight
            height="25px"
            className={gtmTags.vehicleJourniesReport}
          />
        }
        iconAlign="right"
      />
    </Grid>
  );
};
