import { ConverFromToType } from "hooks/useQuery";

const toUtcDateOnlyString = (x: Date) => {
  const utcDate = new Date(
    Date.UTC(x.getFullYear(), x.getMonth(), x.getDate())
  );
  const stringDate = utcDate.toJSON();
  return stringDate.substring(0, 10);
};

export const dateOnlyParameter: ConverFromToType = {
  from: (x: string) => {
    const d = new Date(x + "T00:00:00.000Z");
    return Number.isNaN(d.getDate()) ? undefined : d;
  },
  to: toUtcDateOnlyString,
};

export const dateEndDayParameter: ConverFromToType = {
  from: (x: string) => {
    const d = new Date(x + "T00:00:00.000Z");
    d.setHours(23, 59, 59);
    return Number.isNaN(d.getDate()) ? undefined : d;
  },
  to: toUtcDateOnlyString,
};

export const parseArrayParam = (convert: (v: string) => any) => (p: string) => {
  if (!(typeof p === "string" && p.trim().length === 0)) {
    return p.split(",").map(convert);
  }

  return [];
};

export const parseBooleanParam = (p: string) => {
  if (typeof p === "string") {
    const v = p.trim().toLowerCase();
    return ["true", "yes"].includes(v);
  }

  return false;
};
