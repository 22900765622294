import paths from "appRoutes/paths";
import { Performance } from "assets/images/svgs";
import strings from "assets/strings";
import { IconsPortal } from "drax-design-system";
import {
  EnergyNavigationItem,
  NotificationsNavigationItem
} from "./NavigationItem";
import { NavItem } from "./types";

const { dashboard, reports, support, notificationStrings } = strings;

export const energyNavItem: NavItem = {
  label: reports.energyConsumption.headerTitle,
  icon: Performance,
  route: paths.consumption,
  component: EnergyNavigationItem,
};

export const dashboardNavItem: NavItem = {
  label: dashboard.title,
  icon: IconsPortal.IcDashboard,
  route: paths.home,
};

export const notificationNavItem: NavItem = {
  label: notificationStrings.title,
  icon: IconsPortal.IcNotifications,
  route: paths.notifications,
  component: NotificationsNavigationItem,
};

export const navigationItems: NavItem[] = [
  {
    label: reports.title,
    icon: IconsPortal.IcReports,
    route: paths.reports,
  },
  {
    label: support.title,
    icon: IconsPortal.IcSupport,
    route: paths.support,
  },
];
