import strings from "assets/strings";
import { Grid, Icons, Text } from "drax-design-system";
import { FunctionComponent } from "react";
import "./styles.scss";

const ErrorBox: FunctionComponent = () => (
  <div className="p-error-box">
    <Grid template="30px 1fr">
      <Icons.IcDanger height="30" data-testid="danger-icon" />
      <Text.p>{strings.api.errors.generic.unableToGet}</Text.p>
    </Grid>
  </div>
);

export default ErrorBox;
