import strings from "assets/strings";
import { VehicleStatus, VehicleStatusIdle } from "store/slices/vehicles/types";

type IBadgeColor = "blue" | "red" | "green" | "yellow" | "gray" | "purple";

export type IBadge = {
  color: IBadgeColor;
  text: string;
};

export const generateBadge = (
  status: VehicleStatus | VehicleStatusIdle
): IBadge | null => {
  if (status === VehicleStatus.Charging)
    return {
      color: "blue",
      text: strings.vehicleStatus[VehicleStatus.Charging],
    };
  if (status === VehicleStatus.Fault)
    return {
      color: "red",
      text: strings.vehicleStatus[VehicleStatus.Fault],
    };
  if (status === VehicleStatus.InTransit)
    return {
      color: "green",
      text: strings.vehicleStatus[VehicleStatus.InTransit],
    };
  if (status === VehicleStatus.Stationary)
    return {
      color: "yellow",
      text: strings.vehicleStatus[VehicleStatus.Stationary],
    };
  if (status === VehicleStatus.UnderCharged)
    return {
      color: "purple",
      text: strings.dashboard.infoPanel.vehiclePanel.lowBattery,
    };
  if (status === VehicleStatus.Unknown)
    return {
      color: "blue",
      text: strings.vehicleStatus[VehicleStatus.Unknown],
    };
  return null;
};
