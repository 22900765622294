import strings from "assets/strings";
import classNames from "classnames";
import config from "config/app";
import { Button } from "drax-design-system";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React, { FunctionComponent, RefObject, useEffect } from "react";
import { CSVLink } from "react-csv";
import gtmTags from "services/Analytics/GtmTags";
import { pluralise } from "utils/stringHelpers";
import "./styles.scss";

interface IExportButton {
  headers?: Array<{ key: string; label: string }>;
  data: string | object[];
  showDataCount?: boolean;
  refer?: RefObject<any>;
  setIsPdf?: React.Dispatch<boolean>;
  isPdf?: boolean;
}

const ExportButton: FunctionComponent<IExportButton> = ({
  data,
  showDataCount,
  refer,
  setIsPdf = () => false,
  isPdf,
  headers,
}) => {
  const {
    dashboard: { table },
  } = strings;
  const {
    app: { exportFileName },
  } = config;

  useEffect(() => {
    if (isPdf && refer) {
      const componentWidth = refer.current.clientWidth;
      const componentHeight = refer.current.clientHeight;
      const margin = 15;
      const pdfWidth = componentWidth + margin * 2;
      const pdfHeight = pdfWidth * 1.5 + margin * 2;

      const totalPDFPages = Math.ceil(componentHeight / pdfHeight) - 1;

      html2canvas(refer.current).then(function (canvas) {
        const imgData = canvas.toDataURL("image/jpeg", 1.0);
        const pdf = new jsPDF("p", "pt", [pdfWidth, pdfHeight]);
        pdf.addImage(
          imgData,
          "JPG",
          margin,
          margin,
          componentWidth,
          componentHeight
        );
        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage();
          pdf.setPage(i + 1);
          pdf.addImage(
            imgData,
            "JPG",
            margin,
            -(pdfHeight * i) + margin * 2,
            componentWidth,
            componentHeight
          );
        }
        pdf.save(`${exportFileName}.pdf`);
      });
      setIsPdf(false);
    }
  });

  const generateDataCount = () =>
    `${
      data.length > 0 ? `${data.length} ${pluralise("row", data.length)}` : ""
    }`;

  return (
    <div
      className="text-color-primary p-export-button"
      data-testid="export-button"
    >
      <CSVLink
        className={classNames(`p-export-button__link ${gtmTags.exportButton}`, {
          "p-export-button__link--disabled": !data.length,
        })}
        headers={headers}
        filename={`${exportFileName}.csv`}
        data={data}
      >
        <Button
          title={`${table.export} ${showDataCount ? generateDataCount() : ""}`}
          disabled={!data.length}
        />
      </CSVLink>

      {/* Hide PDF functiobality for Release 1.2 */}
      {/* {isHovered && (
        <Panel
          className="p-export-button__dropdown"
          data-testid="export-button-dropdown"
        >
          <Grid gap="0px" template="1fr">
            <CSVLink
              className={`p-export-button__link ${gtmTags.exportButton}`}
              filename={`${exportFileName}.csv`}
              data={data}
            >
              <div
                className={`text-color-primary ${gtmTags.exportButton}`}
                data-testid="csv-export-button"
              >
                {csv}
              </div>
            </CSVLink>
            
            <div
              onClick={() => downloadPdf()}
              className={`p-export-button__link ${gtmTags.exportButton}`}
              data-testid="pdf-export-button"
            >
              PDF
            </div>
          </Grid>
        </Panel>
      )} */}
    </div>
  );
};

export default ExportButton;
