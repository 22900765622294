import { IApiError } from "../types";

export default class ApiError {
  constructor(props: IApiError) {
    this.status = props.status;
    this.statusMessage = props.statusMessage;
    this.data = props.data;
  }

  status?: IApiError["status"];
  statusMessage: IApiError["statusMessage"];
  data: IApiError["data"];
}
