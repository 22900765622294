import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { selectVehicleByVehicleId } from "store/selectors/common";
import { selectVehicleDetails } from "store/selectors/vehicles";
import { getVehicleDetails } from "store/slices/vehicles";

export const useVehicleDetails = (vehicleId: string) => {
  const dispatch = useDispatch();

  const vehicleDetails = useSelector((state: RootState) =>
    selectVehicleDetails(state, { vehicleId })
  );
  const vehicle = useSelector((state: RootState) =>
    selectVehicleByVehicleId(state, { vehicleId })
  );

  useEffect(() => {
    if (vehicleId != null) {
      dispatch(getVehicleDetails(vehicleId));
    }
  }, [dispatch, vehicleId, vehicle?.lastUpdated]);

  return vehicleDetails;
};
