import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IDatePickerView } from "./types";

const DatePickerMonthView: FunctionComponent<IDatePickerView> = ({
  selectedDate,
  onChange,
  mode,
  maxDate,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [yearDate, setYearDate] = useState(selectedDate);

  useEffect(() => {
    const showSeleted = selectedDate.getFullYear() === yearDate.getFullYear();

    if (divRef.current && !showSeleted) {
      const selectedElement1 = Array.from(
        divRef.current.querySelectorAll(".react-datepicker__month--selected")
      );
      const selectedElement2 = Array.from(
        divRef.current.querySelectorAll(
          ".react-datepicker__month-text--keyboard-selected"
        )
      );
      [...selectedElement1, ...selectedElement2].forEach((el) => {
        el?.classList.remove(
          "react-datepicker__month--selected",
          "react-datepicker__month-text--keyboard-selected"
        );
      });
    }
  }, [divRef, selectedDate, yearDate]);

  return (
    <div
      className="consumption-date-picker__month-view"
      data-testid="date-picker-month-view"
      ref={divRef}
    >
      <DatePicker
        selected={selectedDate}
        onChange={onChange}
        inline
        dateFormat="MM/yyyy"
        onYearChange={setYearDate}
        maxDate={maxDate}
        showMonthYearPicker
        showFullMonthYearPicker
        showFourColumnMonthYearPicker
      />
    </div>
  );
};

export default DatePickerMonthView;
