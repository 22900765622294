import { VehicleJourney } from "api/domains/vehicles/types";
import { buildDataQuery } from "api/protocols/OData/dataQueryBuilder";
import paths from "appRoutes/paths";
import strings from "assets/strings";
import { ReportsControls } from "components";
import { TableRemoteSorting } from "components/Table";
import { ISortOptions } from "components/Table/types";
import { Container } from "drax-design-system";
import { useStartEndDateFilterNavigation } from "hooks/useStartEndDateFilterNavigation";
import { journeysTableConfig } from "pages/Reports/reportsTables";
import { journeysCSVTableConfig } from "pages/Reports/tablesCSVConfig";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  queryVehicleJourneysData,
  selectReportsState
} from "store/slices/reports";
import "./styles.scss";

interface JourneysFilter {
  startDate: Date;
  endDate: Date;
}

const Journeys: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { journeys, journeysCount, journeysError } =
    useSelector(selectReportsState);

  const [{ startDate, endDate }, setDates] =
    useStartEndDateFilterNavigation<JourneysFilter>();

  const [orderBy, setOrderBy] = useState<ISortOptions<VehicleJourney>>({
    sortKey: "vrn",
    sortDir: "asc",
  });

  const [isLoading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(
    async (startIndex: number, endIndex: number) => {
      const nextEndDate = new Date(endDate);
      nextEndDate.setDate(nextEndDate.getDate() + 1);
      const query = buildDataQuery<VehicleJourney>((b) =>
        b
          .skip(startIndex)
          .top(endIndex - startIndex)
          .filter("startTime", "ge", startDate)
          .filter("endTime", "lt", nextEndDate)
          .orderBy(orderBy.sortKey, orderBy.sortDir)
          .count()
      );
      await dispatch(queryVehicleJourneysData(query));
    },
    [dispatch, orderBy, startDate, endDate]
  );

  useEffect(() => {
    setLoading(true);
    loadData(0, 100).finally(() => {
      setLoading(false);
    });
  }, [dispatch, loadData, setLoading]);

  return (
    <Container data-testid="journeys-page">
      <ReportsControls
        linkUrl={paths.reports}
        startDate={startDate}
        endDate={endDate}
        ///@ts-ignore
        setDates={setDates}
      ></ReportsControls>
      <TableRemoteSorting
        dataKey="id"
        title={strings.reports.journeys.title}
        data={journeys ?? []}
        columns={journeysTableConfig}
        tableCSVConfig={journeysCSVTableConfig}
        dataError={journeysError}
        isLoading={isLoading}
        showTooltip={false}
        tableHeight={420}
        showExportDataCount={true}
        loadMoreData={loadData}
        totalCount={journeysCount}
        sortOptions={orderBy}
        setSortOptions={setOrderBy as any}
      />
    </Container>
  );
};

export default Journeys;
