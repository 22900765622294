import { DottedLine, EyeStrikeGray, LegendLine } from "assets/images/svgs";
import strings from "assets/strings";
import { ChartData, ChartOptions, TooltipItem } from "chart.js";
import { Icons } from "drax-design-system";
import {
  ChartTypeKeys,
  PositionTypeKeys
} from "pages/Reports/components/Charts/types";
import defaultTooltipConfig from "../Reports/components/Charts/defaultTooltipConfig";
import { DatePickerModes } from "./components/DatePicker";

type iTooltipData = {
  evConsumptionKwh: number;
  siteConsumptionKwh: number;
  capacityKva: number;
};

const tooltipConfig = defaultTooltipConfig("");
tooltipConfig.tooltip.interaction = {
  mode: "nearest",
};
tooltipConfig.tooltip.callbacks = {
  title: () => "",
  labelTextColor: () => {
    return "#216EF2";
  },
  label: (context: TooltipItem<ChartTypeKeys>) => {
    const {
      evConsumption,
      siteConsumption,
      energySupplyCapacity,
      units: { kWh, kVA },
    } = strings.reports.energyConsumption;
    const {
      dataset,
      dataset: { label },
      dataIndex,
    } = context;
    const { evConsumptionKwh, siteConsumptionKwh, capacityKva }: iTooltipData =
      dataset.data[dataIndex] as any;
    let formattedLabel: string[] = [
      `${evConsumption}: ${evConsumptionKwh.toFixed(2)}${kWh}`,
      `${siteConsumption}: ${siteConsumptionKwh.toFixed(2)}${kWh}`,
    ];

    if (label === energySupplyCapacity) {
      formattedLabel = [`${capacityKva.toFixed(2)}${kVA}`];
    }

    return formattedLabel;
  },
};

export const DefaultChartOptions = () => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 500,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      ...tooltipConfig,
    },
    scales: {
      y: {
        stacked: true,
        baginAtZero: true,
        position: "left" as PositionTypeKeys,
        ticks: {
          color: "#216EF2",
        },
        grid: {
          drawBorder: false,
        },
      },
      x: {
        stacked: true,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  } as any as ChartOptions;
};

export const DefaultChartData = (aggregationLevel: DatePickerModes) => {
  return {
    labels: [] as string[],
    datasets: [
      {
        label: strings.reports.energyConsumption.evConsumption,
        data: [] as number[],
        borderColor: "#82E1D7",
        backgroundColor: "#82E1D7",
        borderRadius: 2,
        barThickness: 22,
        order: 2,
        showLabel: true,
        hideIcon: EyeStrikeGray,
        showIcon: Icons.IcEye,
        hidden: false,
        stack: "Consumption",
        parsing: {
          xAxisKey: "label",
          yAxisKey: "evConsumptionKwh",
        },
        minBarLength: 4,
        base: 0.1
      },
      {
        label: strings.reports.energyConsumption.siteConsumption,
        data: [] as number[],
        backgroundColor: "#5591F5",
        borderColor: "#5591F5",
        borderRadius: 2,
        barThickness: 22,
        order: 1,
        showLabel: true,
        hideIcon: EyeStrikeGray,
        showIcon: Icons.IcEye,
        hidden: false,
        stack: "Consumption",
        parsing: {
          xAxisKey: "label",
          yAxisKey: "siteConsumptionKwh",
        },
        minBarLength: 4,
        base: 0.1
      },
      {
        label: strings.reports.energyConsumption.totalEnergyConsumption,
        data: [] as number[],
        type: "line" as ChartTypeKeys,
        borderColor: "#31D380",
        showLabel: true,
        legendIcon: LegendLine,
        hideIcon: EyeStrikeGray,
        showIcon: Icons.IcEye,
        hidden: false,
        parsing: {
          xAxisKey: "label",
          yAxisKey: "totalConsumptionKwh",
        },
      },
      {
        label: strings.reports.energyConsumption.energySupplyCapacity,
        data: [] as number[],
        type: "line" as ChartTypeKeys,
        fill: false,
        borderColor: "#0A1464",
        borderDash: [1, 3],
        hidden: true,
        showLabel: aggregationLevel === DatePickerModes.DAY,
        legendIcon: DottedLine,
        hideIcon: EyeStrikeGray,
        showIcon: Icons.IcEye,
        stack: "Supply",
        parsing: {
          xAxisKey: "label",
          yAxisKey: "capacityKva",
        },
      },
    ],
  } as any as ChartData;
};
