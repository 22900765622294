export interface IUnreadNotifications {
  count?: number;
  isLoading: boolean;
  isError: boolean;
  isRealoadNeeded: boolean;
}

export interface INotificationState {
  notifications: INotification[] | null;
  notificationsError: boolean;
  total: number | null;
  unreadNotifications: IUnreadNotifications | null;
}

export interface IManyNotificationsPayload {
  notifications: INotification[];
}

export interface INotificationsDataPayload {
  items: INotification[];
  total: number;
  skip: number;
  top?: number;
}

export interface IDeleteNotificationsPayload {
  ids: string[];
}

export interface IMarkNotificationSeenPayload {
  ids: string[];
}

export interface INotificationsErrorPayload {
  notificationsError: boolean;
}

export interface INotificationSetStatusPayload {
  notificationId: string;
  status: NotificationStatus;
}

export enum NotificationTypes {
  ChargingStation = "ChargingStation",
  ElectricVehicle = "ElectricVehicle",
  CombustionVehicle = "CombustionVehicle",
}
export interface INotification {
  notificationId: string;
  version: number;
  originationEventId: string;
  dateCreatedUtc: Date;
  dateChangedUtc: Date;
  state: NotificationState;
  userState: NotificationUserState;
  notificationType: NotificationTypes;
  status: NotificationStatus;
  title: string;
  description: string;
  itemId: string;
}

export interface INotificationGroup {
  title: string;
  date: string;
  notifications: INotification[];
}

export enum NotificationStatus {
  DriverNotified = "Driver Notified",
  ContactDriver = "Contact Driver",
  DraxNotified = "Drax Notified",
}

export enum NotificationState {
  New = "New",
  Update = "Update",
}

export enum NotificationUserState {
  New = "New",
  Seen = "Seen",
  Deleted = "Deleted",
}

export enum NotificationEvents {
  NewStatus = "UpdateNotificationStatus",
  NotificationReceived = "NotificationReceived",
}
