import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export type ConverFromToType = { from?: (v: any) => any; to?: (v: any) => any };
export type ConverStringType = (v: string) => any;

export type ParamOptions<T extends { [k: string]: any }> = {
  [k in keyof T]?: ConverFromToType | ConverStringType;
};

export const stringifyQuery = <T extends { [k: string]: any }>(
  obj: T,
  paramOptions?: ParamOptions<T>
) =>
  Object.keys(obj)
    .flatMap((key) => {
      let value = obj[key];
      if (value == null) {
        return [];
      }
      const options = paramOptions?.[key];
      // @ts-ignore
      if (typeof options?.to == "function") {
        // @ts-ignore
        value = options.to(value);
      }
      return [encodeURIComponent(key) + "=" + encodeURIComponent(value)];
    })
    .join("&");

export const parseQuery = <T extends { [k: string]: any }>(
  searchObj: T,
  defaultParams: T,
  paramOptions?: ParamOptions<T>
) =>
  Object.entries(searchObj).reduce(
    (agg: any, [key, value]) => {
      // @ts-ignore
      const options = paramOptions?.[key];
      let v;
      try {
        if (typeof options == "function") {
          v = options(value as string);
        } else if (typeof options?.from == "function") {
          v = options.from(value);
        } else {
          const number = Number(value);
          v = Number.isNaN(number) ? value : number;
        }
      } catch (e) {
        console.warn(`Parse ${key}='${value}' error`);
      }

      if (v != null) {
        agg[key] = v;
      }
      return agg;
    },
    { ...defaultParams }
  );

export const useQuery = <T>(
  defaultParams?: Partial<T>,
  ///@ts-ignore
  paramOptions?: ParamOptions<T>
): [T, (params: Partial<T>) => string] => {
  const [defParams] = useState(defaultParams);
  const { search } = useLocation();

  const query = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    let searchObj: any = {};
    searchParams.forEach((value: string, key: string) => {
      searchObj[key] = value;
    });

    return parseQuery(searchObj, defParams, paramOptions) as T;
  }, [paramOptions, search, defParams]);

  const buildQuery = useCallback(
    (params: Partial<T>) => {
      const obj: any = { ...query, ...params };
      return stringifyQuery(obj, paramOptions);
    },
    [paramOptions, query]
  );

  return [query, buildQuery];
};
