import { IAxiosResponse, IHttpRequest } from "api/types";
import { AxiosInstance } from "axios";
import errorHandler from "../../errorHandler/errorHandler";

export const defaultHeaders = {
  "Content-Type": "application/json",
};

export interface IHttpProtocol {
  request<T>(apiCall: IHttpRequest): Promise<IAxiosResponse<T>>;
}

class Http implements IHttpProtocol {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async request<T>(payload: IHttpRequest): Promise<IAxiosResponse<T>> {
    try {
      const response = await this.api.request<T>({
        ...payload,
        url: payload.url,
        headers: {
          ...defaultHeaders,
          ...(payload.headers || {}),
        },
      });

      return response;
    } catch (error) {
      ///@ts-ignore
      return errorHandler.handleErrors(error);
    }
  }
}

export default Http;
