import paths from "appRoutes/paths";
import strings from "assets/strings";
import classnames from "classnames";
import { useSubscription } from "contexts/subscriptionContext";
import {
  Button,
  Container,
  Grid,
  Heading,
  Panel,
  Text
} from "drax-design-system";
import { useUnreadNotificationsCount } from "hooks/useUnreadNotificationsCount";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import { selectUserInfo } from "store/slices/auth";
import "./styles.scss";

const UserNotificationBox: FunctionComponent<{ firstName: string }> = ({
  firstName,
}) => {
  const navigate = useNavigate();
  const {
    dashboard: { notificationBox },
  } = strings;
  const unreadNotificationsCount = useUnreadNotificationsCount(true);

  const hasUnreadNotifications = unreadNotificationsCount > 0;
  const panelCx = classnames("p-notification-box", {
    "p-notification-box--unread": hasUnreadNotifications,
    "background-Primary": !hasUnreadNotifications,
  });

  return (
    <Panel className={panelCx} data-testid="notification-section">
      <Container>
        <Heading className="p-notification-box__heading" as="h2">
          {notificationBox.hi} {firstName}
        </Heading>
        {hasUnreadNotifications ? (
          <Grid template="1fr">
            <Text className="p-notification-box__info">
              {notificationBox.thereAre}
              <Text as="span" textColor="error">
                {unreadNotificationsCount}
                {notificationBox.newFaults}
              </Text>
            </Text>
            <Button
              className={gtmTags.reviewNotifications}
              onClick={() => navigate(paths.notifications)}
              data-testid="review-button"
            >
              {notificationBox.review}
            </Button>
          </Grid>
        ) : (
          <Text className="p-notification-box__info">
            {notificationBox.noFaults}
          </Text>
        )}
      </Container>
    </Panel>
  );
};

const AdminNotificationBox: FunctionComponent<{ firstName: string }> = ({
  firstName,
}) => {
  const {
    dashboard: { notificationBox },
  } = strings;

  return (
    <Panel
      className="p-notification-box background-Primary"
      data-testid="notification-section"
    >
      <Container>
        <Heading className="p-notification-box__heading" as="h2">
          {notificationBox.hi} {firstName}
        </Heading>
      </Container>
    </Panel>
  );
};

export const NotificationBox = () => {
  const user = useSelector(selectUserInfo);
  const { orgId } = useSubscription();

  if (user?.orgId !== orgId) {
    return <AdminNotificationBox firstName={user?.firstName ?? ""} />;
  }
  return <UserNotificationBox firstName={user?.firstName ?? ""} />;
};

export default NotificationBox;
