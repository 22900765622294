import { Container } from "drax-design-system";
import { FunctionComponent } from "react";
import { InfoPanelType } from "store/slices/dashboard/types";
import { CombustionInfo, StationInfo, VehicleInfo } from "./components";
import "./styles.scss";

interface IInfoPanelProps {
  type: InfoPanelType;
  itemId: string;
  onBack: () => void;
}

const InfoPanel: FunctionComponent<IInfoPanelProps> = ({
  type,
  itemId,
  onBack,
}) => (
  <Container
    className="p-info-panel__container"
    data-testid="info-panel-container"
  >
    {type === InfoPanelType.Station ? (
      <StationInfo stationId={itemId} onBack={onBack} />
    ) : type === InfoPanelType.Vehicle ? (
      <VehicleInfo vehicleId={itemId} onBack={onBack} />
    ) : (
      <CombustionInfo vehicleId={itemId} onBack={onBack} />
    )}
  </Container>
);

export default InfoPanel;
