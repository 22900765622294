import api from "api";
import { Select } from "components";
import { ISelectOption } from "components/Select/Select";
import { stylesFactory } from "components/Select/styles";
import { ITable } from "components/Table";
import { CSVExportButtonContainer } from "components/Table/CSVExportButtonContainer";
import { useEffect, useMemo, useState } from "react";
import { sortBy } from "utils/arrayUtils";
import { ChargeSessionsFilter } from "./types";

export const LOCATION_ALL = "__all__";

const ALL_OPTION = { value: LOCATION_ALL, label: "All" };
const locationStyles = stylesFactory({
  menuWidth: "280px",
  singleValueWidth: "280px",
});

export const useChargeSessionsToolbar = (
  filter: ChargeSessionsFilter,
  setFilter: (filter: Partial<ChargeSessionsFilter>) => void
) => {
  const [locationOptions, setLocationOptions] = useState<ISelectOption[]>([
    ALL_OPTION,
  ]);

  const { startDate, endDate } = filter;

  useEffect(() => {
    const getLocations = async () => {
      const data =
        await api.domains.ChargingStations.queryChargingSessionLocation(
          startDate,
          endDate
        );
      setLocationOptions(() => {
        const options = data
          .sort(sortBy("locationName", (x: string) => x.toLowerCase()))
          .map(({ locationId, locationName }) => ({
            value: locationId,
            label: locationName,
          }));
        options.unshift(ALL_OPTION);
        return options;
      });
    };
    getLocations();
  }, [startDate.toISOString(), endDate.toISOString()]);

  const ToolbarFactory = useMemo(() => {
    const Toolbar: React.FC<ITable<any>> = () => {
      const value =
        locationOptions.find((o) => o.value === filter.locationId) ??
        ALL_OPTION;

      return (
        <div className="p-table-toolbar">
          <div className="p-table-toolbar left">
            <Select
              options={locationOptions}
              value={value}
              styles={locationStyles}
              showIcon={false}
              label={"Location"}
              placeholder=""
              onSelectChange={(el) => {
                const locationId = (
                  el.value === LOCATION_ALL ? null : el.value
                ) as string;
                setFilter({ locationId });
              }}
              isClearable={false}
              data-testid="sites-dropdown"
            />
          </div>
          <div className="p-table-toolbar right">
            <CSVExportButtonContainer />
          </div>
        </div>
      );
    };

    return Toolbar;
  }, [locationOptions, filter, setFilter]);

  return ToolbarFactory;
};

export default useChargeSessionsToolbar;
