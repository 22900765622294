import { BinIcon } from "assets/images/svgs";
import strings from "assets/strings";
import classNames from "classnames";
import { Container, Grid, Heading, Icons, Text } from "drax-design-system";
import React, { FunctionComponent, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import gtmTags from "services/Analytics/GtmTags";
import { setModalType } from "store/slices/modal";
import { ModalTypes } from "store/slices/modal/types";
import { selectNotifications } from "store/slices/notifications";
import { NotificationUserState } from "../../store/slices/notifications/types";
import { NotificationsListInfiniteLoader } from "./NotificationsListInfiniteLoader";
import "./styles.scss";

const NotificationsPage: FunctionComponent = () => {
  const { notificationStrings } = strings;
  const { notifications } = useSelector(selectNotifications);
  const dispatch = useDispatch();

  const checkIsAllNotificationSeen = () =>
    notifications?.every((el) => el?.userState === NotificationUserState.Seen);

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <section
      ref={containerRef}
      className="p-page__panel p-notification-page__panel"
    >
      <Container layout="maxWidth" className="p-notifications">
        <Grid align="center" gap="var(--spacing-gap-s)" template="1fr">
          <Grid template="1fr 260px" className="p-notifications__header">
            <Heading.h3 textColor="tertiary">
              {notificationStrings.title}
            </Heading.h3>
            {
              <Grid
                className={classNames("p-notifications__controls", {
                  "p-notifications__controls--disabled": !notifications?.length,
                })}
                align="end"
                template="1fr auto"
              >
                <Text
                  onClick={() =>
                    !notifications?.length || checkIsAllNotificationSeen()
                      ? () => {}
                      : dispatch(
                          setModalType({
                            type: ModalTypes.NotificationsMarkAllAsSeen,
                          })
                        )
                  }
                  textColor="primary"
                  textAlign="right"
                  className={classNames(gtmTags.markAllAsSeen, {
                    "p-notifications__controls--disabled":
                      checkIsAllNotificationSeen(),
                  })}
                >
                  <Icons.IcEye
                    className={`p-notifications__icon ${gtmTags.markAllAsSeen}`}
                  />
                  {notificationStrings.seenAll}
                </Text>
                <Text
                  onClick={() =>
                    !notifications?.length
                      ? () => {}
                      : dispatch(
                          setModalType({
                            type: ModalTypes.NotificationsDeleteAll,
                          })
                        )
                  }
                  textColor="primary"
                  textAlign="right"
                  className={gtmTags.deleteAll}
                >
                  <BinIcon
                    className={`p-notifications__icon ${gtmTags.deleteAll}`}
                  />
                  {notificationStrings.deleteAll}
                </Text>
              </Grid>
            }
          </Grid>
          <NotificationsListInfiniteLoader containerRef={containerRef} />
        </Grid>
      </Container>
    </section>
  );
};

export default NotificationsPage;
