import { ChargeStation, CombustionCar, ElectricCar } from "assets/images/svgs";
import React, { FunctionComponent } from "react";
import { NotificationTypes } from "store/slices/notifications/types";

interface INotificationIcon {
  notificationType: string;
}

const NotificationIcon: FunctionComponent<INotificationIcon> = ({
  notificationType,
}) => {
  const iconSize = 40;

  switch (notificationType) {
    case NotificationTypes.ChargingStation: {
      return <ChargeStation width={iconSize} height={iconSize} />;
    }
    case NotificationTypes.CombustionVehicle: {
      return <CombustionCar width={iconSize} height={iconSize} />;
    }
    default: {
      return <ElectricCar width={iconSize} height={iconSize} />;
    }
  }
};

export default NotificationIcon;
