import strings from "assets/strings";
import Modal from "components/Modal";
import { Button, Grid, Heading, Text } from "drax-design-system";
import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from "store/slices/modal";
import { setDeleteNotifications } from "store/slices/notifications";

const NotificationsDeleteAll = () => {
  const { notificationStrings } = strings;
  const dispatch = useDispatch();

  return (
    <Modal>
      <Grid data-testid="delete-all-modal" template="1fr">
        <Heading.h3>{notificationStrings.deleteAll}</Heading.h3>
        <Text.p>{notificationStrings.cantUndo}</Text.p>
        <Grid template="1fr 1fr">
          <Button
            onClick={() => dispatch(setModalType({ type: null }))}
            title={notificationStrings.cancel}
            appearance="secondary"
          ></Button>
          <Button
            data-testid="delete-all-button"
            onClick={() => {
              dispatch(setDeleteNotifications());
              dispatch(setModalType({ type: null }));
            }}
            title={notificationStrings.deleteAll}
            appearance="primary"
          ></Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NotificationsDeleteAll;
