import { ICSVConfig } from "components/Table/types";

export const formatCSVData = <T extends { [k: string]: any }>(
  data: Array<T>,
  config: Array<ICSVConfig<T>>
) =>
  data.map((el: T) => {
    const row: any = {};

    Object.keys(el).forEach((key) => {
      const elConfig = config.find((c) => c.key === key);
      if (!elConfig) {
        return;
      }

      if (!elConfig.exclude) {
        let formattedValue = el[key];

        try {
          if (typeof elConfig!.formatFunc === "function") {
            formattedValue = elConfig!.formatFunc(el[key], el);
          }
        } catch (error) {
          console.log(
            `An error occurred with formatting '${key}' value: `,
            error
          );
        }
        row[key] = formattedValue;
      }
    });

    return row;
  });
