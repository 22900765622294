import strings from "assets/strings";
import Modal from "components/Modal";
import { Button, Grid, Heading, Text } from "drax-design-system";
import { useDispatch, useSelector } from "react-redux";
import { selectModalInfo, setModalType } from "store/slices/modal";

const GenericError = () => {
  const {
    errors: { genericError },
  } = strings;
  const dispatch = useDispatch();
  const { message } = useSelector(selectModalInfo);

  return (
    <Modal data-testid="generic-error-modal">
      <Grid template="1fr">
        <Heading.h3>{genericError.title}</Heading.h3>
        <Text.p>{message || genericError.body}</Text.p>
        <Grid template="1fr">
          <Button
            onClick={() => {
              dispatch(setModalType({ type: null }));
            }}
            title={genericError.close}
            appearance="primary"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default GenericError;
