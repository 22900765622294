import { BinIcon } from "assets/images/svgs";
import strings from "assets/strings";
import { Grid, Heading, Icons, Panel, Text } from "drax-design-system";
import { CSSProperties, FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import gtmTags from "services/Analytics/GtmTags";
import {
  setDeleteNotifications,
  setNotificationsAsRead,
  setNotificationStatus,
} from "store/slices/notifications";
import {
  INotification,
  NotificationStatus,
  NotificationUserState,
} from "store/slices/notifications/types";
import { convertToLocalTime, formatTimeDate } from "utils/dateFormat";
import NotificationIcon from "./NotificationIcon";

const Notification: FunctionComponent<
  INotification & { style: CSSProperties }
> = (props) => {
  const {
    notificationType,
    title,
    status,
    userState,
    notificationId,
    dateCreatedUtc,
    style,
  } = props;
  const { notificationStrings } = strings;
  const dispatch = useDispatch();

  const localTime = convertToLocalTime(dateCreatedUtc);
  const time = formatTimeDate(localTime, "HH:mm");

  return (
    <Panel
      style={style}
      className={`p-notification__panel ${
        userState !== NotificationUserState.Seen
          ? "p-notification__panel--unseen"
          : ""
      }`}
      data-testid="notification-panel"
    >
      <Grid
        className="p-notification__controls"
        template="40px 1fr 1fr 150px 100px"
      >
        <NotificationIcon notificationType={notificationType} />
        <Heading.h5 className="bold" textColor="tertiary">
          {title}
        </Heading.h5>
        <div>
          {status === NotificationStatus.ContactDriver && (
            <Text
              textAlign="right"
              textColor="primary"
              className="p-notification__contact-driver"
              data-testid="contact-driver"
              onClick={() =>
                dispatch(
                  setNotificationStatus({
                    notificationId,
                    status: NotificationStatus.DriverNotified,
                  })
                )
              }
            >
              <Icons.IcCheck className="p-notification__icon" />
              {notificationStrings.driverContacted}
            </Text>
          )}
        </div>
        <div>
          {!(userState === NotificationUserState.Seen) && (
            <Text
              className={`p-notification__seen-icon ${gtmTags.seen}`}
              onClick={() => dispatch(setNotificationsAsRead(notificationId))}
              textColor="primary"
              textAlign="right"
            >
              <Icons.IcEye className={`p-notification__icon ${gtmTags.seen}`} />
              {notificationStrings.seen}
            </Text>
          )}
        </div>
        <Text
          className={`p-notification__delete ${gtmTags.delete}`}
          onClick={() => dispatch(setDeleteNotifications(notificationId))}
          textColor="primary"
          textAlign="right"
        >
          <BinIcon className={`p-notification__icon ${gtmTags.delete}`} />
          {notificationStrings.delete}
        </Text>
      </Grid>
      <Grid
        className="p-notification__details"
        template="40px 1fr 1fr 1fr 100px"
      >
        <NotificationIcon notificationType={notificationType} />
        <Heading.h5 className="bold" textColor="tertiary">
          {title}
        </Heading.h5>
        <Text>{notificationStrings.fault}</Text>
        <Heading.h5 className="bold" textColor="primary">
          {status}
        </Heading.h5>
        <Text textColor="gray" textAlign="right">
          {!(userState === NotificationUserState.Seen) && (
            <div
              data-testid="notification-not-seen"
              className="p-notification__seen"
            />
          )}
          {time}
        </Text>
      </Grid>
    </Panel>
  );
};

export default Notification;
