import React, { useMemo } from "react";
import { sortableTitleRenderer } from "./sortableTitleRenderer";
import "./styles.scss";
import { ISortOptions, ITable, ITableColumn } from "./types";

export interface WithSortingHandlersProps<T> {
  sortOptions?: ISortOptions<T>;
  setSortOptions: React.Dispatch<ISortOptions<T>>;
}
export const withSortingHandlers =
  <T,>(Component: React.ComponentType<ITable<T>>) =>
  (props: ITable<T> & WithSortingHandlersProps<T>) => {
    const { columns: prevColumns } = props;

    const { /* sortOptions,*/ setSortOptions } = props;

    const columns = useMemo(() => {
      return prevColumns.map((col: ITableColumn<T>) => {
        return {
          ...col,
          children: Array.isArray(col.children)
            ? col.children.map((child: ITableColumn<T>) => {
                return {
                  ...child,
                  title: sortableTitleRenderer(child, setSortOptions),
                };
              })
            : undefined,
          title: sortableTitleRenderer(col, setSortOptions),
        };
      });
    }, [prevColumns, setSortOptions]);

    return <Component {...props} columns={columns} />;
  };
