import strings from "assets/strings";
import { Button, Grid, Icons, Panel, Text } from "drax-design-system";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  getThisMonthDates,
  getThisQuarterDates,
  getThisYearDates,
} from "./helpers";
import "./styles.scss";

export interface IDatePicker {
  startDate: Date;
  setStartDate?: React.Dispatch<Date>;
  endDate: Date | null;
  setEndDate?: React.Dispatch<Date>;
  setDates?: (d: { startDate: Date; endDate: Date | null }) => void;
}

const ReportsDatePicker: FunctionComponent<IDatePicker> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setDates: setDatesProp,
}) => {
  const {
    reports: { datePicker },
  } = strings;

  const [isOpen, setIsOpen] = useState(false);
  const [dates, setDates] = useState<[Date, Date | null]>([startDate, endDate]);

  const prevStartDate = useRef(startDate);
  const prevEndDate = useRef(endDate);

  useEffect(() => {
    if (isOpen) {
      return;
    }
    const [start, end] = dates;

    setStartDate?.(start);
    setEndDate?.(end!);

    if (
      prevStartDate.current?.getTime() === start?.getTime() &&
      prevEndDate.current?.getTime() === end?.getTime()
    ) {
      return;
    }

    setDatesProp?.({ startDate: start, endDate: end });
  }, [isOpen]);

  const [start, end] = dates;

  return (
    <>
      <div
        data-testid="date-picker-display"
        onClick={() => setIsOpen(!isOpen)}
        className="p-date-display"
      >
        <Text textColor="tertiary" className="p-date-display__text">
          {datePicker.dataFrom}
          <Text textColor="primary" className="p-date-display__text">
            {start.toDateString()}
          </Text>{" "}
          -{" "}
          <Text textColor="primary" className="p-date-display__text">
            {end && end.toDateString()}
          </Text>{" "}
        </Text>
      </div>
      <div
        onClick={() => setIsOpen(false)}
        className={`p-date-picker__backdrop${!isOpen ? "--hidden" : ""}`}
      ></div>
      <Panel className={`p-date-picker${!isOpen ? "--hidden" : ""}`}>
        <div className="p-date-picker__content">
          <Grid
            className="p-date-picker__buttons"
            template="20px 1fr 1fr 1fr 20px"
          >
            <div></div>
            <Button
              onClick={() => setDates(getThisMonthDates())}
              appearance="secondary"
              title={datePicker.thisMonth}
            ></Button>
            <Button
              onClick={() => setDates(getThisQuarterDates())}
              appearance="secondary"
              title={datePicker.thisQuarter}
            ></Button>
            <Button
              onClick={() => setDates(getThisYearDates())}
              appearance="secondary"
              title={datePicker.thisYear}
            ></Button>
            <div
              onClick={() => setIsOpen(false)}
              className="p-date-picker__close"
            >
              <Icons.IcClose color="white" />
            </div>
          </Grid>
          <DatePicker
            startDate={start}
            endDate={end}
            monthsShown={2}
            selectsRange
            onChange={(d: [Date, Date]) => setDates(d)}
            inline
            maxDate={new Date()}
          ></DatePicker>
        </div>
      </Panel>
    </>
  );
};

export default ReportsDatePicker;
