import { IHttpResponse } from "api/types";
import ApiError from "./ApiError";

class ErrorValidator {
  constructor() {
    this.isError = this.isError.bind(this);
    this.isSuccess = this.isSuccess.bind(this);
  }

  public isError<T>(result: IHttpResponse<T>): result is ApiError {
    return result instanceof ApiError || result === null;
  }
  public isEmptyObj(response: any) {
    return !(typeof response == "object" && Object.keys(response).length);
  }

  public isSuccess<T>(result: IHttpResponse<T>): result is T {
    return !this.isError(result);
  }
}
export default ErrorValidator;
