import loader from "assets/images/small_lightning.gif";
import "./styles.scss";

interface ILoader {
  className?: string;
}

const Loader: React.FC<ILoader> = ({ className }) => (
  <div className={`${className || ""}`}>
    <img src={loader} className="p-loader" alt="loading..." />
  </div>
);

export default Loader;
