import paths from "appRoutes/paths";
import strings from "assets/strings";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { getVehicleJourneysSummaryData } from "store/slices/reports";
import { roundHour, Scales } from "../Insights/reportSavingsData";
import { GeneralTrend, TrendError } from "../Trend";

export const VehicleUsageTrends = () => {
  const {
    reports: {
      trends: { journeys, distance, allTime },
    },
  } = strings;

  const usage = useSelector((state: RootState) => state.reports.usage.journeys);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getVehicleJourneysSummaryData());
  }, [dispatch]);

  const onForward = useCallback(() => {
    navigate(paths.journeys);
  }, [navigate]);

  if (usage.hasError) {
    return (
      <>
        <TrendError />
        <TrendError />
      </>
    );
  }

  return (
    <>
      <GeneralTrend
        testId="ev-journey-duration"
        value={roundHour(usage.data.durationHours)}
        onForward={onForward}
        title={journeys}
        subTitle={allTime}
        valueTemplate="{0}{1} hrs"
        targetTemplate="target {0}{1} hrs"
        scales={Scales.Number}
      />
      <GeneralTrend
        testId="ev-journey-distance"
        value={usage.data.distanceMiles}
        title={distance}
        subTitle={allTime}
        valueTemplate="{0}{1} miles"
        targetTemplate="target {0}{1} miles"
        scales={Scales.Number}
      />
    </>
  );
};
