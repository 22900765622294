import paths from "appRoutes/paths";
import strings from "assets/strings";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import {
  getChargeStationTotalEnergy,
  getChargeStationUptime
} from "store/slices/reports";
import { Scales } from "../Insights/reportSavingsData";
import { GeneralTrend, TrendError } from "../Trend";
import { CharginsStationsUptimeTrend } from "../Trend/CharginsStationsUptimeTrend";

export const ChargingStationsTrends = () => {
  const {
    reports: {
      trends: { chargeSessions, allTime },
    },
  } = strings;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onForward = useCallback(() => {
    navigate(paths.chargeSessions);
  }, [navigate]);

  const totalEnergy = useSelector(
    (state: RootState) => state.reports.usage.totalEnergy
  );
  const uptime = useSelector(
    (state: RootState) => state.reports.usage.chargingStationsUptime
  );

  useEffect(() => {
    dispatch(getChargeStationTotalEnergy());
    dispatch(getChargeStationUptime());
  }, [dispatch]);

  return (
    <>
      {uptime.hasError ? (
        <TrendError />
      ) : (
        <CharginsStationsUptimeTrend
          bestUptime={uptime.data.bestUptime}
          lastFaultTime={uptime.data.lastFaultTime}
        />
      )}
      {totalEnergy.hasError ? (
        <TrendError />
      ) : (
        <GeneralTrend
          testId="cs-total-energy"
          value={totalEnergy.data}
          onForward={onForward}
          title={chargeSessions}
          subTitle={allTime}
          valueTemplate="{0} {1}"
          targetTemplate="target {0} {1}"
          scales={Scales.Energy}
        />
      )}
    </>
  );
};
