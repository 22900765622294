import axios from "axios";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetChargeStations } from "store/slices/chargestations";
import { resetConsumptions } from "store/slices/consumption";
import { resetNotifications } from "store/slices/notifications";
import { resetReports } from "store/slices/reports";
import { resetVehicles } from "store/slices/vehicles";

export const useOrgIdInterceptor = (orgId: string) => {
  const dispatch = useDispatch();
  const orgIdRef = React.useRef(orgId);

  useEffect(() => {
    orgIdRef.current = orgId;

    const id = axios.interceptors.request.use((req) => {
      if (req.headers != null) {
        req.headers["x-drax-org-id"] = `${orgIdRef.current}`;
        req.headers["X-CSRF"] = "1";
      }
      return req;
    });

    dispatch(resetChargeStations());
    dispatch(resetVehicles());
    dispatch(resetNotifications());
    dispatch(resetReports());
    dispatch(resetConsumptions());

    return () => {
      axios.interceptors.request.eject(id);
    };
  }, [dispatch, orgId]);
};
