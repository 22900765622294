import ExportButton from "components/ExportButton";
import { useExportContext } from "contexts/ExportContext";
import React, { useMemo } from "react";
import { formatCSVData } from "utils/formatCSVData";
import "./styles.scss";

export const CSVExportButtonContainer = () => {
  const { data, dataKey, csvConfig, selectedIds, showDataCount } =
    useExportContext();

  const { exportData, headers } = useMemo(() => {
    const exportData = formatCSVData(
      data.filter((item: any) => selectedIds?.includes(item[dataKey])),
      csvConfig
    );

    const headers = csvConfig
      .map((config) => {
        if (config.exclude) {
          return null;
        }

        return { key: config.key, label: config.label };
      })
      .filter((x) => x != null) as Array<{ key: string; label: string }>;

    return { exportData, headers };
  }, [data, csvConfig, selectedIds, dataKey]);

  return (
    <ExportButton
      data={exportData}
      headers={headers}
      showDataCount={showDataCount}
    />
  );
};
