import React, { useMemo, useState } from "react";
import { sortableTitleRenderer } from "./sortableTitleRenderer";
import "./styles.scss";
import { ISortOptions, ITable, ITableColumn } from "./types";

const sortTableData = <T,>(data: T[], key: keyof T, dir: "asc" | "desc") => {
  if (data == null) {
    return data;
  }

  let sortingData: T[] = [...data];

  switch (dir) {
    case "asc":
      sortingData.sort((a, b) => {
        if (a[key] == null) {
          return 1;
        }

        if (b[key] == null) {
          return -1;
        }

        if (a[key] === b[key]) {
          return 0;
        }

        return a[key] > b[key] ? 1 : -1;
      });
      break;
    case "desc":
      sortingData.sort((a, b) => {
        if (a[key] == null) {
          return -1;
        }

        if (b[key] == null) {
          return 1;
        }

        if (a[key] === b[key]) {
          return 0;
        }

        return a[key] < b[key] ? 1 : -1;
      });
      break;
  }

  return sortingData;
};

export interface WithSortingDataProps<T> {
  defaultSortColumn?: keyof T;
  defaultSortDirection?: "asc" | "desc";
}

export const withSortingData =
  <T extends object>(Component: React.ComponentType<ITable<T>>) =>
  (props: ITable<T> & WithSortingDataProps<T>) => {
    const { columns: prevColumns, data: prevData } = props;
    const { defaultSortColumn, defaultSortDirection } = props;

    const [sortOption, setSortOption] = useState<ISortOptions<T>>();

    const data = useMemo(() => {
      const key = sortOption?.sortKey ?? defaultSortColumn;
      const dir = sortOption?.sortDir ?? defaultSortDirection;
      if (key && dir) {
        return sortTableData(prevData, key, dir);
      }
      return prevData;
    }, [prevData, sortOption, defaultSortColumn, defaultSortDirection]);

    const columns = useMemo(() => {
      return prevColumns.map((col: ITableColumn<T>) => {
        return {
          ...col,
          children: Array.isArray(col.children)
            ? col.children.map((child: ITableColumn<T>) => {
                return {
                  ...child,
                  title: sortableTitleRenderer(child, setSortOption),
                };
              })
            : undefined,
          title: sortableTitleRenderer(col, setSortOption),
        };
      });
    }, [prevColumns, setSortOption]);

    return <Component {...props} columns={columns} data={data} />;
  };
