export const pluralise = (str: string, num: number): string => {
  return num !== 1 ? `${str}s` : str;
};

export const unique = <T>(array: T[], p?: keyof T): T[] => {
  if (typeof p == "string") {
    return array.filter(
      (value, index, arr) => arr.findIndex((x) => x[p] === value[p]) === index
    );
  }

  return array.filter((value, index, arr) => arr.indexOf(value) === index);
};

export const fillArrayTemplate = (
  template: string | undefined,
  params: Array<string | number | object>,
  addMissingParams: boolean = true
) => {
  if (
    typeof template !== "string" ||
    !(Array.isArray(params) && params?.length > 0)
  ) {
    return template;
  }

  let message = params.reduce((agg: string, _curr, index, arr) => {
    const regExp = new RegExp("\\{" + index + "\\}", "g");
    const replace = String(arr[index]);
    return agg.replace(regExp, replace);
  }, template);

  const placeholders = unique(template.match(/\{\d+\}/g) ?? []).map((x) => {
    const pl = x.replace("{", "").replace("}", "");
    return Number(pl);
  });

  if (placeholders.length < params.length && addMissingParams) {
    const missingParams = params
      .map((x, index) => (placeholders.includes(index) ? undefined : x))
      .filter((x) => x)
      .join(",");
    message += ` (${missingParams})`;
  }

  return message;
};
