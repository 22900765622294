import React, { useCallback, useState } from "react";
import {
  FinanceReportingData,
  TariffUpdateRequest,
} from "../../../../api/domains/chargingStations/types";
import { roundValueWithFraction } from "../../../../utils/formatTableData";
import { useDispatch } from "react-redux";
import api from "../../../../api/api";
import { setFinanceReportingTariff } from "../../../../store/slices/reports";
import FormElement from "../../../../components/FormElement/FormElement";

export const Tariff: React.FC<{
  record: FinanceReportingData;
  index: number;
}> = ({ index, record }) => {
  const initValue =
    record.tariff != null
      ? (roundValueWithFraction(record.tariff, 1) as string)
      : null;

  const [value, setValue] = useState(initValue);
  const [errorMessage, setErrorMessage] = useState<string>();

  const dispatch = useDispatch();

  const submitTariffCd = useCallback(
    async (v: string) => {
      if (v === initValue) {
        return;
      }

      const { authId, chargingStationId, startDate, endDate } = record;

      const tariff = Number(v);

      if (Number.isNaN(tariff)) {
        setErrorMessage("Invalid input format");
        return;
      }

      const request: TariffUpdateRequest = {
        authId,
        chargingStationId,
        tariff,
        activeFrom: startDate,
        activeTo: endDate,
      };
      try {
        await api.domains.ChargingStations.submitTariff(request);
        setErrorMessage(undefined);
        dispatch(
          setFinanceReportingTariff({ authId, chargingStationId, tariff })
        );
      } catch (e) {
        setErrorMessage("Error with saving data");
      }
    },
    [record, dispatch, initValue]
  );

  return (
    <FormElement.CellInput
      id={`tariff.edit.${index}`}
      placeholder={"Edit"}
      className="c-form__input cell-input"
      type="number"
      value={value as any}
      errorMessage={errorMessage}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      }}
      onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
        submitTariffCd(e.target.value);
      }}
    />
  );
};

export default Tariff;
