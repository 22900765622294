import strings from "assets/strings";
import { Loader, PageError } from "components";
import { AutoExpander } from "components/AutoExpander";
import { Heading } from "drax-design-system";
import { useSubscriptionNotificationTypes } from "hooks/useSubscriptionNotificationTypes";
import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { VariableSizeList as List } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { selectNotifications } from "store/slices/notifications";
import {
  INotification,
  NotificationTypes,
} from "store/slices/notifications/types";
import {
  getItemSizeFactory,
  NotificationsListItem,
} from "./NotificationsListItem";
import { mapNotificationsToViewItems } from "./notificationUtils";
import "./styles.scss";

/* minimum number of rows to be loaded at a time */
const MINIMUM_BATCH_SIZE = 50;
/* data will start loading when a user scrolls within {threshold | 15} rows */
const THRESHOLD = Math.round(MINIMUM_BATCH_SIZE * 0.33);

export const NotificationsList = (props: {
  notifications: INotification[] | null;
  totalCount: number | null;
  containerRef: RefObject<HTMLDivElement>;
  loadMoreNotifications: (
    skip: number,
    top: number,
    notificationTypes: Array<NotificationTypes>
  ) => Promise<void> | void;
}) => {
  const { notificationStrings } = strings;
  const { notifications, totalCount, containerRef, loadMoreNotifications } =
    props;
  const infiniteLoaderRef = useRef<InfiniteLoader>(null);
  const { notificationsError } = useSelector(selectNotifications);
  const notificationTypes = useSubscriptionNotificationTypes();
  const [initialScrollOffset, setInitialScrollOffset] = useState(0);

  useEffect(() => {
    // notificationsError - only on mount
    if (notifications == null || notificationsError) {
      loadMoreNotifications(0, MINIMUM_BATCH_SIZE, notificationTypes);
    }

    if (
      notifications?.length === 0 &&
      typeof totalCount == "number" &&
      totalCount !== 0
    ) {
      loadMoreNotifications(0, MINIMUM_BATCH_SIZE, notificationTypes);
    }
    // eslint-disable-next-line
  }, [notifications, totalCount]);

  useEffect(() => {
    loadMoreNotifications(0, MINIMUM_BATCH_SIZE, notificationTypes);
    // eslint-disable-next-line
  }, [notificationTypes]);

  const viewItems = useMemo(() => {
    const items =
      notifications?.filter(
        (n) => n != null && notificationTypes.includes(n.notificationType)
      ) ?? [];
    return mapNotificationsToViewItems(items);
  }, [notifications, notificationTypes]);

  if (notificationsError) {
    return (
      <div data-testid="notifications-error" className="p-notifications__error">
        <PageError withPanel={true} />
      </div>
    );
  }

  if (!notifications) {
    return <Loader />;
  }

  if (!notifications?.length) {
    return <Heading.h3>{notificationStrings.noNotifications}</Heading.h3>;
  }

  const getItemSize = getItemSizeFactory(viewItems);

  const realItemsCount = notifications.length;
  const realRenderCount = viewItems.length;

  let itemCount = 0;
  if (totalCount === realItemsCount) {
    // reached the end of the list
    itemCount = realRenderCount;
  } else if (realItemsCount) {
    // still has data to load
    itemCount = realRenderCount + THRESHOLD;
  }

  const loadMoreItems = (startIndex: number, stopIndex: number) => {
    const delta = realRenderCount - realItemsCount;
    const skip = startIndex - delta;
    let top =
      typeof totalCount === "number"
        ? Math.min(totalCount - skip, MINIMUM_BATCH_SIZE)
        : stopIndex - startIndex;

    top = top < 0 ? MINIMUM_BATCH_SIZE : top;
    return loadMoreNotifications(skip, top, notificationTypes);
  };

  return (
    <AutoExpander containerRef={containerRef} renderDeps={[notifications]}>
      {({ height }, outerRef) => {
        return (
          <InfiniteLoader
            isItemLoaded={(index) => viewItems[index] != null}
            ref={infiniteLoaderRef}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
            minimumBatchSize={MINIMUM_BATCH_SIZE}
            threshold={THRESHOLD}
          >
            {({ onItemsRendered, ref }) => {
              return (
                <List
                  key={`key-${height}-${totalCount}`}
                  itemKey={(index: number, data) => {
                    const item = data.items[index];
                    const k1 = (item as any)?.notificationGroupDate;
                    return k1 ?? item?.notificationId;
                  }}
                  outerRef={outerRef}
                  className={"p-notifications__scroll-content"}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  height={height}
                  itemCount={itemCount}
                  initialScrollOffset={initialScrollOffset}
                  onScroll={({ scrollOffset }) => {
                    setInitialScrollOffset(scrollOffset);
                  }}
                  itemSize={getItemSize}
                  width={"100%"}
                  itemData={{ items: viewItems }}
                >
                  {NotificationsListItem}
                </List>
              );
            }}
          </InfiniteLoader>
        );
      }}
    </AutoExpander>
  );
};
