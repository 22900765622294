import ApiError from "api/common/ApiError";
import strings from "assets/strings";
import { AxiosError, AxiosResponse } from "axios";
// import ErrorResponseObserver from "observables/ErrorResponse";
// import ErrorTracker from 'services/ErrorTracker/ErrorTracker';
import { ApiErrorDetails, ApiErrorTypes, IApiError } from "../types";

export class ErrorHandler {
  /**
   * Define all status codes which
   * will trigger side effect like logout, display modals...
   */
  static get statusCodesWithSideEffect(): number[] {
    return [500, 401];
  }

  static getUnauthResponse(data?: ApiErrorDetails) {
    return new ApiError({
      status: 401,
      statusMessage: "Unauthorised",
      data: null,
    });
  }

  private buildResponseError(
    error: AxiosError<ApiErrorDetails, any>
  ): ApiError {
    const { status, data } = error.response as AxiosResponse<ApiErrorDetails>;

    let statusMessage: string;
    switch (status) {
      case 401: {
        const unauthError = ErrorHandler.getUnauthResponse(data || null);
        // ErrorTracker.recordError(new Error(unauthError.statusMessage));
        return unauthError;
      }
      case 304:
        statusMessage = strings.errors.notModified;
        break;
      case 500: {
        // ErrorTracker.recordError(new Error(data.error));
        statusMessage = strings.errors.internalServerError;
        break;
      }
      default:
        statusMessage = data.error;
    }
    return new ApiError({ status, statusMessage, data });
  }

  private buildRequestError(error: AxiosError): ApiError {
    return new ApiError({
      status: ApiErrorTypes.AxiosRequestError,
      statusMessage: error.message || strings.errors.defaultRequestError,
      data: null,
    });
  }

  private buildInternalError(error: AxiosError): ApiError {
    return new ApiError({
      status: ApiErrorTypes.ApiInternalError,
      statusMessage: error.message,
      data: null,
    });
  }

  handleErrors(error: AxiosError<ApiErrorDetails, any>) {
    if (error && error.response) {
      return this.buildResponseError(error);
    }
    if (error && error.request) {
      return this.buildRequestError(error);
    }
    return this.buildInternalError(error);
  }

  handleSideEffects(error: IApiError): IApiError {
    return error;
  }
}

const errorHandler = new ErrorHandler();
export default errorHandler;
