import { ChartConfiguration, TooltipItem } from "chart.js";
import { ChartTypeKeys, ChartTypesObj } from "./types";

const defaultTooltipConfig = (sign: string) => ({
  tooltip: {
    titleColor: "#0A1464",
    backgroundColor: "#fff",
    borderColor: "rgba(221, 228, 233, 1)",
    padding: 16,
    borderWidth: 1.5,
    bodyFont: {
      size: 16,
      lineHeight: "1.4",
    },
    cornerRadius: 8,
    caretSize: 12,
    displayColors: false,
    interaction: {
      mode: "point",
    },
    callbacks: {
      labelTextColor: () => {
        return "#0A1464";
      },
      label: (context: TooltipItem<ChartTypeKeys>): string | string[] => {
        let dataLabel;
        const config = context.chart.config as ChartConfiguration;

        if (config.type === ChartTypesObj.bar) {
          dataLabel = context.dataset.label;
        }
        if (config.type === ChartTypesObj.doughnut) {
          dataLabel = context.label;
        }
        const label = `${dataLabel} ${context.raw}` || "";
        return `${label}${sign}`;
      },
      title: () => "",
    },
  },
});

export default defaultTooltipConfig;
