import classnames from "classnames";
import { Text } from "drax-design-system";
import { useUnreadNotificationsCount } from "hooks/useUnreadNotificationsCount";
import { default as React } from "react";
import { Link } from "react-router-dom";
import gtmTags from "services/Analytics/GtmTags";
import "./styles.scss";
import { NavigationItemProps } from "./types";

export const NavigationItem = (props: NavigationItemProps) => {
  const { isActive, data, itemClassnames, iconClassnames } = props;
  const listCx = classnames(
    `l-header__nav-list-item ${gtmTags.navItem}`,
    {
      "l-header__nav-list-item--active": isActive,
    },
    itemClassnames
  );

  const iconCx = classnames(
    `l-header__nav-icon ${gtmTags.navItem}`,
    {
      "l-header__nav-icon--active": isActive,
    },
    iconClassnames
  );

  return (
    <Link to={data.route} key={data.label}>
      <li className={listCx}>
        <div className={iconCx}>
          <data.icon />
        </div>
        <Text.span
          className={
            isActive ? `${gtmTags.navItem} bold` : `${gtmTags.navItem}`
          }
        >
          {data.label}
        </Text.span>
      </li>
    </Link>
  );
};

export const NotificationsNavigationItem = (props: NavigationItemProps) => {
  const unreadNotificationsCount = useUnreadNotificationsCount();
  return (
    <NavigationItem
      {...props}
      iconClassnames={"l-header__nav-icon--notifications"}
      itemClassnames={classnames("l-header__nav-list-item--notifications", {
        "l-header__nav-list-item--notifications-unread":
          unreadNotificationsCount > 0,
      })}
    />
  );
};

export const EnergyNavigationItem = (props: NavigationItemProps) => {
  return (
    <NavigationItem
      {...props}
      iconClassnames={"l-header__nav-icon--notifications"}
      itemClassnames={classnames("l-header__nav-list-item-energy", {
        "l-header__nav-list-item-energy--active": props.isActive,
      })}
    />
  );
};
