import { IChargingStation } from "api/domains/chargingStations/types";
import { ChargeStation } from "assets/images/svgs";
import strings from "assets/strings";
import InformationTooltip from "components/InformationTooltip";
import { Panel } from "drax-design-system";
import { IFilterProps } from "pages/Dashboard/types";
import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectChargeStations,
  selectChargeStationsError,
} from "store/slices/chargestations";
import { ChargeStationStatus } from "store/slices/chargestations/types";
import Filter from "../Filter";
import FilterError from "./FilterError";
import {
  chargeStationFiltersStates,
  enumEntries,
  genChargeStationStats,
} from "./generateStats";

const WatchlistTooltip = () => {
  return (
    <InformationTooltip
      stopPropagation
      title={"Watchlist explained"}
      wider={false}
      description={
        <>
          When a charger falls into this status it could mean possible network
          issues or the charger hasn't communicated.
          <p />
          <p />
          This is to do with the 'heartbeat' on the unit communicating at the
          expected interval.
        </>
      }
    />
  );
};

const ChargeStationsFilter: FunctionComponent<IFilterProps> = ({
  enabled,
  onToggle,
  filters,
  onFilter,
}) => {
  const {
    general: { chargeStationsTitle },
    dashboard: {
      infoPanel: {
        chargeStation: { error },
      },
    },
  } = strings;

  const chargeStations = useSelector(selectChargeStations);
  const chargeStationsError = useSelector(selectChargeStationsError);

  const statuses = useMemo(
    () => genChargeStationStats(chargeStations as IChargingStation[]),
    [chargeStations]
  );

  return (
    <Panel.Collapsible
      id="charging-stations-filter-menu"
      data-testid="charging-stations-filter-menu"
      title={`${chargeStations.length} ${chargeStationsTitle}`}
      icon={<ChargeStation className="small-icon" />}
      isEnabled={enabled}
      onToggle={(e) => e !== enabled && onToggle()}
    >
      <div>
        {enabled && (
          <div>
            {!chargeStationsError &&
              enumEntries<ChargeStationStatus, number>(statuses).map(
                ([key, value]) => {
                  // Temporary. Will be removed when these values are
                  // removed from the back end
                  if (
                    key === ChargeStationStatus.INOPERATIVE ||
                    key === ChargeStationStatus.PLANNED ||
                    key === ChargeStationStatus.REMOVED ||
                    key === ChargeStationStatus.RESERVED
                  )
                    return null;

                  let title: string | React.ReactElement =
                    strings.chargeStationStatus[key];
                  if (key === ChargeStationStatus.UNKNOWN) {
                    title = (
                      <>
                        {title} <WatchlistTooltip />
                      </>
                    );
                  }

                  return (
                    <Filter
                      key={key}
                      name={chargeStationsTitle}
                      maxValue={chargeStations.length}
                      currentValue={value}
                      title={title}
                      filter={() => onFilter(key)}
                      isSelected={filters.includes(key)}
                      state={chargeStationFiltersStates[key]}
                    />
                  );
                }
              )}
            {chargeStationsError && <FilterError error={error} />}
          </div>
        )}
      </div>
    </Panel.Collapsible>
  );
};

export default ChargeStationsFilter;
