import { ICSVConfig } from "components/Table/types";
import React, { useContext } from "react";

export interface ExportContextType {
  selectedIds: string[];
  dataKey: string;
  csvConfig: ICSVConfig<any>[];
  data: any[];
  showDataCount?: boolean;
}

const ExportContext = React.createContext<ExportContextType | null>(null);

export const ExportContextProvider: React.FC<ExportContextType> = ({
  children,
  ...value
}) => {
  return (
    <ExportContext.Provider value={value}>{children}</ExportContext.Provider>
  );
};

export const useExportContext = () => {
  const data = useContext(ExportContext);

  if (data == null) {
    throw new Error("Use ExportContextProvider as parent component");
  }

  return data;
};
