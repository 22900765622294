import { IInsight } from "api/domains/consumption/types";
import { Performance } from "assets/images/svgs";
import strings from "assets/strings";
import { Loader } from "components";
import { Grid, Heading, Text } from "drax-design-system";
import { ErrorBox } from "pages/Reports/components";
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { selectConsumptionsData } from "store/slices/consumption";
import CollapsiblePanel from "../CollapsiblePanel";
import Trend from "../Trend";
import "./styles.scss";

const Insights: FunctionComponent = () => {
  const { insights, insightsError } = useSelector(selectConsumptionsData);

  const {
    reports: {
      trends: {
        how,
        description,
        trend,
        monthToDate,
        lastMonth,
        previousMonth,
      },
    },
  } = strings;

  const panelHeader = (
    <div className="consumption-insights__header">
      <Heading.h2
        textColor="tertiary"
        className="consumption-insights__title consumption-insights--paddings"
      >
        <Performance className="consumption-insights__icon" />
        {how}
      </Heading.h2>
    </div>
  );

  return (
    <CollapsiblePanel
      panelHeader={panelHeader}
      className="consumption-insights"
      data-testid="insights-section"
    >
      <div className="consumption-insights-body">
        <Text.p
          textColor="tertiary"
          className="consumption-insights__description consumption-insights--paddings"
        >
          {description}
        </Text.p>
        <div className="consumption-trends__heading">
          <Grid template="50px 300px 1fr 1fr 1fr 1fr">
            <div></div>
            <div></div>
            <div className="text-center">{trend}</div>
            <div className="text-center">{monthToDate}</div>
            <div className="text-center">{lastMonth}</div>
            <div className="text-center">{previousMonth}</div>
          </Grid>
        </div>
        {!insights && !insightsError && (
          <div className="consumption-insights__loader">
            <Loader />
          </div>
        )}
        {insightsError && (
          <>
            <ErrorBox data-testid="consumption-insights-data-error" />
            <ErrorBox data-testid="consumption-insights-data-error" />
          </>
        )}
        {insights?.map((insight: IInsight) => (
          <Trend key={insight.metric} {...insight} />
        ))}
      </div>
    </CollapsiblePanel>
  );
};

export default Insights;
