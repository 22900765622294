import { IChargingStation } from "api/domains/chargingStations/types";
import { IVehicle, ILatLng } from "api/domains/vehicles/types";
import { vehicleStatusMapper } from "api/domains/vehicles/vehicleTransformer";
import { isPointWithinRadius } from "geolib";
import { IVehicleStatusUpdate, VehicleStatus } from "./types";

export const filterVehicles = (
  filters: VehicleStatus[],
  vehicles: IVehicle[]
): IVehicle[] => {
  if (!filters.length) return vehicles;

  return vehicles.filter((v) => filters.some((f) => v.statuses.includes(f)));
};

export const mergeLiveData = (
  data: IVehicleStatusUpdate[],
  vehicles: IVehicle[]
): IVehicle[] => {
  return vehicles.map((veh) => {
    const match = data.find((f) => f.id === veh.id);

    return match
      ? {
          ...veh,
          ...vehicleStatusMapper<IVehicleStatusUpdate, IVehicle>(match),
          coordinates: {
            latitude: match?.coordinates.latitude,
            longitude: match?.coordinates.longitude,
          },
        }
      : veh;
  });
};

export const getCsInRange = (
  coordinates: ILatLng,
  chargeStations: IChargingStation[],
  range: number // Range in meters
): IChargingStation[] =>
  chargeStations.filter((f) => {
    return isPointWithinRadius(coordinates, f.coordinates, range);
  });
