import {
  IUserInfo,
  useAuthenticationContext,
} from "contexts/AuthenticationContext";
import { ReactNode } from "react";

export enum Role {
  User = "ES_EV_User",
  Admin = "EVAdmin",
}

export const isEsEvUser = (user?: IUserInfo | null) =>
  (user?.roles ?? []).some((role) => role === Role.User);

export const isEvAdmin = (user?: IUserInfo | null) =>
  (user?.roles ?? []).some((role) => role === Role.Admin);

export interface AuthorizeRole {
  role: Role | Role[];
  fallback?: ReactNode;
}

export interface AuthorizePolicy {
  policy: string;
  fallback?: ReactNode;
}

const isAuthorizeRole = (obj: any): obj is AuthorizeRole => {
  return obj != null && "role" in obj;
};

const isAuthorizePolicy = (obj: any): obj is AuthorizePolicy => {
  return obj != null && "policy" in obj;
};

export const Authorization: React.FC<AuthorizeRole | AuthorizePolicy> = (
  props
) => {
  const { user } = useAuthenticationContext();
  const { fallback, children } = props;
  let hasAccess = false;

  if (user == null) {
    return <>{fallback}</>;
  }

  if (isAuthorizeRole(props)) {
    const { role } = props;
    const roles = Array.isArray(role) ? role : [role];
    hasAccess =
      Array.isArray(user.roles) && roles.every((s) => user.roles.includes(s));
  }

  if (isAuthorizePolicy(props)) {
    const { policy } = props;
    return <>{`Not implemented ${policy}`}</>;
  }

  if (!hasAccess) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
};
