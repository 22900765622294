import strings from "assets/strings";
import Modal from "components/Modal";
import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { Button, Heading, Text } from "drax-design-system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectModalInfo, setModalType } from "store/slices/modal";
import "./Success.scss";

const Success = () => {
  const { support } = strings;
  const dispatch = useDispatch();
  const { message, shouldLogOut, blocking } = useSelector(selectModalInfo);
  const { logout } = useAuthenticationContext();

  return (
    <Modal data-testid="message-sent-modal" blocking={blocking}>
      <div className="p-success-modal">
        <Heading.h3 textColor="tertiary">{support.success}</Heading.h3>
        <Text.p>{message}</Text.p>
        <Button
          onClick={(e: React.SyntheticEvent) => {
            dispatch(setModalType({ type: null }));
            shouldLogOut && logout();
          }}
          title={support.done}
          appearance="primary"
        ></Button>
      </div>
    </Modal>
  );
};

export default Success;
