import { IChargingStationPort } from "api/domains/chargingStations/types";
import strings from "assets/strings";
import {
  Accordion,
  AccordionPanel,
  Badge,
  Box,
  Grid,
  Icons,
  Text,
  TextItem,
} from "drax-design-system";
import React, { FunctionComponent } from "react";
import gtmTags from "services/Analytics/GtmTags";
import { ChargeStationStatus } from "store/slices/chargestations/types";
import { genChargeStationColor } from "utils/colors";
import { formatTimeDate } from "utils/dateFormat";
import "./styles.scss";

interface IPortInfo {
  port: IChargingStationPort;
  index: number;
}

const formatDateTime = (date: Date | string | null) =>
  formatTimeDate(date, "HH:mm dd/MM/yyyy");

const PortInfo: FunctionComponent<IPortInfo> = ({ port, index }) => {
  const {
    chargeStationStatus,
    dashboard: {
      infoPanel: { chargeStation },
    },
  } = strings;

  return (
    <Box level={1} className="p-port-info__accordian" id={port.id}>
      <Accordion>
        <AccordionPanel
          label={
            <div data-testid="port-status">
              <TextItem
                className={gtmTags.portInfoButton}
                title={`Port ${index + 1}`}
                subtitle={port.connectors[0].powerType}
                value={
                  <Grid align="end" template="1fr 20px">
                    <Badge
                      className={`${
                        port.status === ChargeStationStatus.UNKNOWN &&
                        "port-status--watchlist-override"
                      }`}
                      color={genChargeStationColor(port.status)}
                      title={chargeStationStatus[port.status].toUpperCase()}
                      data-testid="port-status-block"
                    />
                    <Icons.IcBack className="p-port-info__chevron" width="23" />
                  </Grid>
                }
              />
            </div>
          }
          icon={<></>}
          disabled={false}
        >
          <Grid className={gtmTags.portInfoExpanded} template="1fr">
            {port.status === ChargeStationStatus.CHARGING && (
              <div data-testid="port-energy-delivered">
                <TextItem
                  title={chargeStation.energyDelivered}
                  value={`${Math.round(port.energyDelivered)} kWh`}
                />
              </div>
            )}
            {![
              ChargeStationStatus.OUTOFORDER,
              ChargeStationStatus.INOPERATIVE,
            ].includes(port.status) && (
              <div data-testid="port-output-today">
                <TextItem
                  title={chargeStation.totalOutputToday}
                  value={`${port.totalOutputToday?.toFixed(1) || 0} kWh`}
                />
              </div>
            )}
            {port.status === ChargeStationStatus.UNKNOWN && (
              <Box className="p-port-info__watchlist-since-box" level={2}>
                <div data-testid="port-watchlist-since">
                  <TextItem
                    appearance="multi-value"
                    title={chargeStation.watchlistSince}
                    value={
                      <Text textColor="primary">
                        {formatDateTime(port.lastStatusUpdate)}
                      </Text>
                    }
                  />
                </div>
              </Box>
            )}
            {[
              ChargeStationStatus.CHARGING,
              ChargeStationStatus.BLOCKED,
            ].includes(port.status) &&
              port.sessionStart && (
                <div data-testid="port-current-initiated-by-id">
                  <Text textColor="tertiary">{chargeStation.initiatedBy}</Text>
                  <div className="p-port-initiated-by">
                    <TextItem
                      title={chargeStation.id}
                      value={[port.initiatedById ?? chargeStation.unknown]}
                    />
                    <div />
                    <TextItem
                      title={chargeStation.sessionStart}
                      value={[formatDateTime(port.sessionStart)]}
                    />
                    <div />
                  </div>
                </div>
              )}
            {[
              ChargeStationStatus.OUTOFORDER,
              ChargeStationStatus.INOPERATIVE,
            ].includes(port.status) && (
              <div className="port-fault">
                <div data-testid="port-fault-presented">
                  <TextItem
                    title={chargeStation.faultPresented}
                    value={
                      <Text textColor="primary">
                        {formatDateTime(port.lastUpdated)}
                      </Text>
                    }
                  />
                </div>
                <div data-testid="port-last-used" className="g-missing-data">
                  <TextItem
                    title={chargeStation.lastUsed}
                    value={formatDateTime(port.lastVehicleChargedDate) || "-"}
                  />
                </div>
              </div>
            )}
            {port.lastVehicleChargedDate && (
              <>
                <div
                  data-testid="port-vehicle-last-charged"
                  className="g-missing-data"
                >
                  <TextItem
                    appearance="multi-value"
                    title={chargeStation.lastCharged}
                    value={[formatDateTime(port.lastVehicleChargedDate)]}
                  />
                </div>
                <div
                  data-testid="port-last-initiated-by-id"
                  className="g-missing-data"
                >
                  <TextItem
                    appearance="multi-value"
                    title={chargeStation.initiatedById}
                    value={[
                      port.lastInitiatedById
                        ? port.lastInitiatedById
                        : chargeStation.unknown,
                    ]}
                  />
                </div>
              </>
            )}
          </Grid>
        </AccordionPanel>
      </Accordion>
    </Box>
  );
};

export default PortInfo;
