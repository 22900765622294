import { VehicleJourney } from "api/domains/vehicles/types";
import { ICSVConfig } from "components/Table/types";
import { secondsToMinutes } from "utils/dateFormat";
import {
  formatTableDate,
  showDashForCombustionVehicle,
} from "utils/formatTableData";

export const journeysCSVTableConfig: Array<ICSVConfig<VehicleJourney>> = [
  {
    label: "VRN",
    key: "vrn",
  },
  {
    label: "Location Start",
    key: "startPostcode",
  },
  {
    label: "Location End",
    key: "endPostcode",
  },
  {
    label: "Journey Start",
    key: "startTime",
    formatFunc: formatTableDate,
  },
  {
    label: "Journey End",
    key: "endTime",
    formatFunc: formatTableDate,
  },
  {
    label: "Duration (mins)",
    key: "journeyTime",
    formatFunc: secondsToMinutes,
  },
  {
    label: "Charge Start (%)",
    key: "startCharge",
    formatFunc: showDashForCombustionVehicle,
  },
  {
    label: "Charge End (%)",
    key: "endCharge",
    formatFunc: showDashForCombustionVehicle,
  },
  {
    label: "Charge Used (%)",
    key: "chargeUsed",
    formatFunc: showDashForCombustionVehicle,
  },
  {
    label: "kWh Used",
    key: "kwhUsed",
    formatFunc: showDashForCombustionVehicle,
  },
  {
    label: "Miles per kWh",
    key: "averageMiles",
    formatFunc: showDashForCombustionVehicle,
  },
  {
    label: "Eco score (%)",
    key: "ecoSafetyScore",
    formatFunc: showDashForCombustionVehicle,
  },
];
