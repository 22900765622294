import api from "api";
import { Organisation } from "api/domains/organisations/types";
import { useEffect, useState } from "react";

export const useUserOrganisations = () => {
  const [state, setState] = useState({
    organisations: [] as Array<Organisation>,
    error: null as any,
    isLoading: true,
  });

  useEffect(() => {
    const getUserOrganisations = async () => {
      try {
        setState((p) => ({ ...p, isLoading: true }));
        const organisations =
          await api.domains.Organisations.getUserOrganisations();
        setState({ organisations, isLoading: false, error: null });
      } catch (error) {
        setState({ organisations: [], isLoading: false, error });
      }
    };

    getUserOrganisations();
  }, []);

  return state;
};
