import { FunctionComponent } from "react";
import { fillArrayTemplate } from "utils/stringHelpers";
import {
  formatToLocaleString,
  getScale,
  ScalesType,
} from "../Insights/reportSavingsData";

export interface NumberFormatterProps {
  template: string;
  value?: number | null;
  scales?: ScalesType;
  precision?: number;
  noDataMsg?: string;
  roundFunc?: (v: number) => number;
}

export const NumberFormatter: FunctionComponent<NumberFormatterProps> = ({
  template,
  scales,
  value,
  noDataMsg,
  roundFunc = Math.floor,
  precision = 0,
}) => {
  let valueString = noDataMsg ?? "-";
  let scaleString = "";

  if (value != null) {
    let { value: v, scale } =
      scales?.length === 3 ? getScale(value, scales) : { value, scale: "" };

    const p = Math.pow(10, precision);
    v = roundFunc(v * p) / p;
    valueString = formatToLocaleString(v, precision) as string;
    scaleString = scale;
  }

  return <>{fillArrayTemplate(template, [valueString, scaleString], false)}</>;
};

export const HourFormatter: FunctionComponent<NumberFormatterProps> = (
  props
) => {
  let { value, precision, roundFunc } = props;

  if (value! < 1000) {
    precision = 0;
    roundFunc = Math.round;
  }
  return (
    <NumberFormatter {...props} precision={precision} roundFunc={roundFunc} />
  );
};
