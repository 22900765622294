import Domain from "api/common/Domain";
import apiErrorHandler from "api/common/errorHandler";
import { IHttpClient } from "api/httpClient";
import { IDomainRequestDetails } from "api/types";
import strings from "assets/strings";
import {
  INotification,
  NotificationUserState,
} from "store/slices/notifications/types";
import urls from "../urls";
import { IActionResult, isActionResult } from "./types";

const str = strings.api.errors.messaging;

export class Messaging extends Domain {
  httpClient: IHttpClient;

  constructor(httpClient: IHttpClient) {
    super();
    this.httpClient = httpClient;
  }

  async getAllNotifications() {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "GET",
      url: urls.notifications,
    };

    const response = await this.httpClient.request<INotification[]>(request);
    // Notifications response from API doesn't follow standard format
    // as the rest of the API services

    if (this.isError(response)) {
      return apiErrorHandler(response, str.fetchNotifications);
    }

    if (response === null || !Array.isArray(response)) {
      throw new Error(str.fetchNotifications);
    }

    return response;
  }

  async markSeen(ids: string[]) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url: `${urls.notifications}/markseen`,
      data: { notificationIds: ids },
    };

    const response = await this.httpClient.request<INotification[] | string>(
      request
    );
    if (this.isError(response) || response === null || response === "") {
      throw new Error(str.setNotificationAsSeen);
    }
    return response;
  }

  async markDeleted(ids: string[]) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url: `${urls.notifications}/markdeleted`,
      data: { notificationIds: ids },
    };

    const response = await this.httpClient.request<IActionResult>(request);

    if (
      this.isError(response) ||
      response === null ||
      !isActionResult(response)
    ) {
      throw new Error(str.deleteNotification);
    }

    return response;
  }

  async updateState(
    state: NotificationUserState,
    options: { notificationId: string } | { lastViewDateUtc: Date | string }
  ) {
    const { lastViewDateUtc, notificationId } = options as any;
    const url =
      notificationId != null
        ? `${urls.notifications}/${notificationId}/updateState`
        : `${urls.notifications}/updateState`;

    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url,
      data: {
        state,
        lastViewDateUtc,
      },
    };

    const response = await this.httpClient.request<IActionResult>(request);

    if (
      this.isError(response) ||
      response === null ||
      !isActionResult(response)
    ) {
      throw new Error(str.updateNotification);
    }

    return response;
  }

  async updateStatus(notificationId: string, status: string) {
    const request: IDomainRequestDetails = {
      isAuthenticated: true,
      method: "POST",
      url: `${urls.notifications}/updateStatus`,
      data: { notificationId, status },
    };

    const response = await this.httpClient.request<INotification[]>(request);

    if (this.isError(response) || response === null) {
      throw new Error(str.updateNotification);
    }

    return response;
  }
}
