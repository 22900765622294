import { IVehicle } from "api/domains/vehicles/types";
import {
  electricCar,
  electricCarFaulty,
  electricCarFaultyFocused,
  electricCarFocused,
} from "assets/images/svgs";
import { MapMarker } from "components";
import { FunctionComponent } from "react";
import { VehicleStatus } from "store/slices/vehicles/types";
import { generateRange } from "utils/distanceUtils";
import EntityToolTip from "../EntityToolTip";

export interface ElectricVehiclesMarkersProps {
  vehicleId: string | null;
  vehicles: IVehicle[];
  onSelectVehicle: (id: string) => void;
}

const getVehicleIcon = (veh: IVehicle, vehicleId?: string | null) => {
  if (veh.statuses.includes(VehicleStatus.Fault)) {
    return veh.id === vehicleId ? electricCarFaultyFocused : electricCarFaulty;
  }
  return veh.id === vehicleId ? electricCarFocused : electricCar;
};

const ElectricVehiclesMarkers: FunctionComponent<ElectricVehiclesMarkersProps> =
  ({ vehicleId, vehicles, onSelectVehicle }) => {
    return (
      <>
        {vehicles.map((v) => {
          if (!v.coordinates) return null;

          return (
            <MapMarker
              key={v.id}
              id={v.id}
              icon={getVehicleIcon(v, vehicleId)}
              zIndex={
                v.id === vehicleId ? google.maps.Marker.MAX_ZINDEX : undefined
              }
              position={{
                lat: v.coordinates.latitude,
                lng: v.coordinates.longitude,
              }}
              onClick={() => onSelectVehicle(v.id)}
              hoverComponent={<EntityToolTip type={v.fuelType} dataId={v.id} />}
              radius={v.id === vehicleId ? generateRange(v.range) : null}
            />
          );
        })}
      </>
    );
  };

export default ElectricVehiclesMarkers;
