import paths from "appRoutes/paths";
import strings from "assets/strings";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import { getHomeChargersTotalAmountData } from "store/slices/reports";
import { Scales } from "../Insights/reportSavingsData";
import { TrendError } from "../Trend";
import { FinancialTrend } from "../Trend/FinancialTrend";

export const FinancialReportingHomeChargersTrends = () => {
  const {
    reports: {
      trends: { financialReporting, homeChargesTotalAmount, allTime },
    },
  } = strings;

  const homeChargesTotal = useSelector(
    (state: RootState) =>
      state.reports.finances.financialReporting.homeChargesTotal
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getHomeChargersTotalAmountData());
  }, [dispatch]);

  const onForward = useCallback(() => {
    navigate(paths.financialReporting + "?premise=Home");
  }, [navigate]);

  if (homeChargesTotal.hasError) {
    return <TrendError />;
  }

  return (
    <>
      <FinancialTrend
        testId="ev-financial-reporting-home"
        value={homeChargesTotal.data}
        onForward={onForward}
        title={financialReporting}
        subTitle={homeChargesTotalAmount}
        valueTemplate="£{0}{1}"
        targetComponent={allTime}
        scales={Scales.Number}
      />
    </>
  );
};
