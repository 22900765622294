import appConfig from "config/app";

export const generateMeters = (val: number) => {
  const { mile } = appConfig.app;
  return val * mile;
};

export const percentageOfRange = (range: number) =>
  range * appConfig.app.lowRangePercent;

export const rangePercentageInMeters = (range: number) =>
  generateMeters(percentageOfRange(range));

export const generateRange = (val: number) => {
  const { vehicleLowRangeLevel } = appConfig.app;
  if (val >= vehicleLowRangeLevel) return null;
  const range = percentageOfRange(val);
  return generateMeters(range);
};
