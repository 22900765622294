const gtmClasses = {
  mapZoomIn: "gtm__map-zoom-in",
  mapZoomOut: "gtm__map-zoom-out",
  logOut: "gtm__log-out",
  changePassword: "gtm__change-password",
  navItem: "gtm__nav-item",
  filter: "gtm__filter",
  reviewNotifications: "gtm__review-notifications",
  stationPanelBack: "gtm__station-panel-back",
  stationPanel: "gtm__station-panel",
  stationReportsButton: "gtm__station-reports-button",
  portInfoButton: "gtm__port-info-button",
  portInfoExpanded: "gtm__port-info-expanded",
  vehiclePanel: "gtm__vehicle-panel",
  vehiclePanelBack: "gtm__vehicle-panel-back",
  combustionVehiclePanel: "gtm__combustion-vehicle-panel",
  combustionVehiclePanelBack: "gtm__combustion-vehicle-panel-back",
  vehicleReportsButton: "gtm__vehicle-reports-button",
  vehicleJourniesButton: "gtm__vehicle-journies-button",
  stationsInRange: "gtm__stations-in-range",
  exportButton: "gtm__export-button",
  tableSort: "gtm__table-sort",
  mapToggle: "gtm__map-toggle",
  tableToggle: "gtm__table-toggle",
  markAllAsSeen: "gtm__mark-all-as-seen",
  deleteAll: "gtm__delete-all",
  seen: "gtm__seen",
  delete: "gtm__delete",
  chargeSessionsReport: "gtm__charge-sessions-report",
  vehicleJourniesReport: "gtm__vehicle-journies-report",
};

export default gtmClasses;
