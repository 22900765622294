import React, { FunctionComponent } from "react";
import {
  findTarget,
  generalTargetData,
  ScalesType,
  TargetDataType,
} from "../Insights/reportSavingsData";
import { NumberFormatter, NumberFormatterProps } from "./NumberFormatter";
import "./styles.scss";
import Trend from "./Trend";

const NoData = "N/A";

export const GeneralTrend: FunctionComponent<{
  title: string;
  subTitle?: string;
  valueTemplate: string;
  targetTemplate: string;
  value: number | null;
  scales: ScalesType;
  onForward?: () => void;
  theme?: "dark" | "gray";
  testId?: string;
  targetData?: Array<TargetDataType>;
  Formatter?: React.ComponentType<NumberFormatterProps>;
  TooltipComponent?: React.ComponentType<any>;
}> = (props) => {
  const { value, onForward } = props;
  const { title, subTitle } = props;
  const { valueTemplate, targetTemplate } = props;
  const { scales, theme, testId } = props;
  const { targetData = generalTargetData } = props;
  const { Formatter = NumberFormatter, TooltipComponent } = props;

  const data = findTarget(targetData, value);

  const { max: target, name: dataSubTitle } = data ?? {};

  return (
    <Trend
      testId={testId}
      title={title}
      subTitle={subTitle ?? dataSubTitle ?? NoData}
      currValue={value}
      targetValue={target}
      onForward={onForward}
      TooltipComponent={TooltipComponent}
      ValueComponent={({ currValue }) => {
        return (
          <Formatter
            template={valueTemplate}
            value={currValue}
            scales={scales}
            precision={1}
            noDataMsg={NoData}
            roundFunc={Math.floor}
          />
        );
      }}
      TargetComponent={({ targetValue }) => {
        return (
          <Formatter
            template={targetTemplate}
            value={targetValue}
            scales={scales}
            precision={1}
            noDataMsg={NoData}
            roundFunc={Math.round}
          />
        );
      }}
      theme={theme ?? "gray"}
    />
  );
};
