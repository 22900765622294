import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { initialState } from "./initialModalState";
import { IModal } from "./types";

const modal = createSlice({
  name: "modal",
  initialState: initialState,
  reducers: {
    setModalType(
      state: IModal,
      {
        payload: { type, message, shouldLogOut, blocking },
      }: PayloadAction<IModal>
    ) {
      state.type = type;
      state.message = message;
      state.shouldLogOut = shouldLogOut;
      state.blocking = blocking;
    },
  },
});

export const { setModalType } = modal.actions;
export default modal;

export const selectModalInfo = (state: RootState) => state.modal;
