import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "api";
import { buildDataQuery } from "api/protocols/OData/dataQueryBuilder";
import { RootState } from "store";
import { AppThunk } from "store/types";
import { mergeArrays } from "utils/arrayUtils";
import { convertToLocalTime, sortByDate } from "../../../utils/dateFormat";
import { setModalType } from "../modal";
import { ModalTypes } from "../modal/types";
import { initialState } from "./initialNotificationsState";
import {
  IDeleteNotificationsPayload,
  IManyNotificationsPayload,
  IMarkNotificationSeenPayload,
  INotification,
  INotificationsDataPayload,
  INotificationsErrorPayload,
  INotificationSetStatusPayload,
  INotificationState,
  IUnreadNotifications,
  NotificationTypes,
  NotificationUserState,
} from "./types";

const notifications = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    updateUnreadNotifications(
      state: INotificationState,
      { payload }: PayloadAction<Partial<IUnreadNotifications>>
    ) {
      const prev = state.unreadNotifications ?? {
        isLoading: false,
        isError: false,
        count: undefined,
      };
      state.unreadNotifications = {
        ...prev,
        ...payload,
        isRealoadNeeded: false,
      };
    },
    setNotifications(
      state: INotificationState,
      { payload: { notifications } }: PayloadAction<IManyNotificationsPayload>
    ) {
      state.notifications = notifications;
      state.total = notifications.length;
    },
    setUnreadNotificationsRealoadNeeded(state: INotificationState) {
      //@ts-ignore
      state.unreadNotifications = {
        ...state.unreadNotifications,
        isRealoadNeeded: true,
      };
    },
    setNotificationsData(
      state: INotificationState,
      {
        payload: { items, total, skip, top },
      }: PayloadAction<INotificationsDataPayload>
    ) {
      state.notifications = mergeArrays(state.notifications ?? [], items, skip);
      state.total = total;
    },
    resetNotificationsData(state: INotificationState) {
      state.notifications = null;
      state.total = null;
    },
    setNotificationsError(
      state: INotificationState,
      {
        payload: { notificationsError },
      }: PayloadAction<INotificationsErrorPayload>
    ) {
      state.notificationsError = notificationsError;
    },
    markNotificationSeen(
      state: INotificationState,
      { payload: { ids } }: PayloadAction<IMarkNotificationSeenPayload>
    ) {
      state.notifications =
        state.notifications?.map((el) => {
          if (ids.some((s) => s === el.notificationId))
            el.userState = NotificationUserState.Seen;
          return el;
        }) || [];
    },

    deleteNotifications(
      state: INotificationState,
      { payload: { ids } }: PayloadAction<IDeleteNotificationsPayload>
    ) {
      if (ids.length === state.notifications?.length) {
        state.notifications = [];
        return;
      }
      const prevLength = state.notifications?.length ?? 0;
      state.notifications =
        state.notifications?.filter(
          (el) => !ids.some((s) => s === el.notificationId)
        ) || [];
      const nextLength = state.notifications?.length ?? 0;
      if (prevLength > nextLength && state.total != null) {
        state.total -= prevLength - nextLength;
      }
    },

    updateNotifications(
      state: INotificationState,
      { payload: { notifications } }: PayloadAction<IManyNotificationsPayload>
    ) {
      const newNotifications = notifications.filter(
        (f) =>
          !state.notifications?.some(
            (s) => s.notificationId === f.notificationId
          )
      );
      state.total = (state.total ?? 0) + newNotifications.length;
      state.notifications = [
        ...(state.notifications?.map((el) => {
          const update = notifications.find(
            (f) => f.notificationId === el.notificationId
          );
          if (update) {
            const { dateCreatedUtc, ...remain } = update;
            return { ...el, ...remain };
          }
          return el;
        }) || []),
        ...newNotifications,
      ];
    },

    resetNotifications(state: INotificationState) {
      return initialState;
    },
  },
});

export const {
  updateNotifications,
  deleteNotifications,
  markNotificationSeen,
  setNotifications,
  setNotificationsError,
  setNotificationsData,
  updateUnreadNotifications,
  resetNotificationsData,
  setUnreadNotificationsRealoadNeeded,
  resetNotifications,
} = notifications.actions;

export default notifications;

export const getMoreNotifications =
  (skip: number, top: number, types: Array<NotificationTypes>): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setNotificationsError({ notificationsError: false }));

      let items: INotification[] = [];
      let total = 0;
      if (types.length) {
        const query = buildDataQuery<INotification>((b) =>
          b
            .skip(skip)
            .top(top)
            .filter("notificationType", "in", types)
            .orderBy("dateCreatedUtc", "desc")
            .count()
        );
        const res = (await api.domains.MessagingData.getData(
          query
        )) as INotificationsDataPayload;

        items = res.items;
        total = res.total ?? items.length;
      }
      dispatch(setNotificationsData({ items, total, skip, top }));
      return items;
    } catch (error: any) {
      dispatch(setNotificationsError({ notificationsError: true }));
    }
  };

export const getUnreadNotificationsCount =
  (types: Array<NotificationTypes>): AppThunk =>
  async (dispatch, getState) => {
    try {
      const isLoading =
        getState()?.notifications?.unreadNotifications?.isLoading ?? false;
      if (isLoading) {
        return;
      }

      dispatch(updateUnreadNotifications({ isLoading: true }));
      let count = 0;
      if (types.length) {
        const query = buildDataQuery<INotification>((b) =>
          b
            .skip(0)
            .top(0)
            .filter("notificationType", "in", types)
            .filter("userState", "eq", "New")
            .count()
        );
        const res = (await api.domains.MessagingData.getData(
          query
        )) as INotificationsDataPayload;
        count = res.total;
      }
      dispatch(
        updateUnreadNotifications({ isLoading: false, isError: false, count })
      );
      return count;
    } catch (error) {
      dispatch(updateUnreadNotifications({ isLoading: false, isError: true }));
    }
  };

const getLastViewDateUtc = (getState: () => RootState) => {
  const items = getState().notifications?.notifications ?? [];
  const orderedNotification = sortByDate([...items], "dateCreatedUtc");
  return orderedNotification[0]?.dateCreatedUtc ?? new Date().toISOString();
};

// Set one or all as read
export const setNotificationsAsRead =
  (id?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const options =
        id != null
          ? { notificationId: id }
          : { lastViewDateUtc: getLastViewDateUtc(getState) };
      await api.domains.Messaging.updateState(
        NotificationUserState.Seen,
        options
      );

      const lastViewDateTime =
        options?.lastViewDateUtc != null
          ? convertToLocalTime(options.lastViewDateUtc!)?.getTime()
          : null;
      const newIds =
        id != null
          ? [id]
          : (getState().notifications.notifications ?? [])
              .filter((x) => x != null)
              .filter((x) => {
                const isNew = x.userState === NotificationUserState.New;
                const isViewed =
                  lastViewDateTime == null ||
                  convertToLocalTime(x.dateCreatedUtc)!.getTime() <=
                    lastViewDateTime;
                return isNew && isViewed;
              })
              .map((x) => x.notificationId);

      const notifications = newIds.map(
        (notificationId) =>
          ({
            notificationId,
            userState: NotificationUserState.Seen,
          } as INotification)
      );
      dispatch(updateNotifications({ notifications }));
      dispatch(setUnreadNotificationsRealoadNeeded());
    } catch (e) {
      dispatch(setModalType({ type: ModalTypes.GenericError }));
      console.log(e);
    }
  };

// Delete single or multiple notifications
export const setDeleteNotifications =
  (id?: string): AppThunk =>
  async (dispatch, getState) => {
    try {
      const options =
        id != null
          ? { notificationId: id }
          : { lastViewDateUtc: getLastViewDateUtc(getState) };
      await api.domains.Messaging.updateState(
        NotificationUserState.Deleted,
        options
      );

      if (id != null) {
        dispatch(deleteNotifications({ ids: [id] }));
      } else {
        dispatch(resetNotificationsData());
      }
      dispatch(setUnreadNotificationsRealoadNeeded());
    } catch (e) {
      dispatch(setModalType({ type: ModalTypes.GenericError }));
    }
  };

// Set status of one notification
// Endpoint returns an array of notification objects
export const setNotificationStatus =
  ({ notificationId, status }: INotificationSetStatusPayload): AppThunk =>
  async (dispatch) => {
    try {
      const res = (await api.domains.Messaging.updateStatus(
        notificationId,
        status
      )) as INotification[];
      if (res != null) {
        dispatch(updateNotifications({ notifications: res }));
      }
    } catch (e) {
      dispatch(setModalType({ type: ModalTypes.GenericError }));
    }
  };

export const selectNotifications = (state: RootState) => state.notifications;
