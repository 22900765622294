import {
  ILatLng,
  IPerformancePayload,
  IVehicle,
  IVehicleDetails,
  VehicleFault,
} from "api/domains/vehicles/types";

export interface IVehicleDataPayload {
  data: IVehicle[];
}

export interface IVehiclesDataPayload {
  vehicles: IVehicle[];
}

export interface ISingleVehicleDataPayload {
  data: IVehicleDetailView | null;
}

export interface IFilterPayload {
  data: VehicleStatus;
}

export interface IVehicleAtStationPayload {
  data: IVehicleAtStation[];
}

export interface IVehiclesError {
  vehiclesError: boolean;
}

export interface IVehicleDetailView extends IVehicleDetails {
  statuses: VehicleStatus[];
}

export interface IVehicleDetailMap {
  [id: string]: IVehicleDetailView;
}

export interface IVehicleDataState {
  wasInitialLoad?: boolean;
  eVPerformance: IPerformancePayload;
  vehicles: IVehicle[];
  vehiclesError: boolean;
  details: IVehicleDetailMap;
}

export interface IVehicleAtStation extends IVehicle {
  stationId: string;
  portId: string;
}

export enum VehicleStatus {
  UnderCharged = "UnderCharged",
  Charging = "Charging",
  Stationary = "Stationary",
  Fault = "Faulty", // This is a custom status derived from the Fault prop for vehicle
  InTransit = "Moving",
  Unknown = "Unknown",
}

export enum CombustionVehicleStatus {
  Stationary = "Stationary",
  Fault = "Faulty",
  InTransit = "Moving",
  Unknown = "Unknown",
}

export enum VehicleStatusIdle {
  Idle = "Idle",
}

export enum VehicleFuelType {
  Electric = "Electric",
  Combustion = "Combustion",
}

export interface IVehicleStatusUpdate {
  id: string;
  imei: string;
  status: VehicleStatus | VehicleStatusIdle;
  fault: VehicleFault;
  coordinates: ILatLng;
  lastUpdated: Date; // string
  stateOfCharge: number;
  range: number;
  totalMileage: number;
}

export enum VehicleEvents {
  UpdateStatus = "UpdateVehicleStatus",
}
