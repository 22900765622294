import AppRoutes from "appRoutes";
import { ErrorBoundary } from "components";
import ModalFactory from "components/ModalFactory";
import { AuthenticationContextProvider } from "contexts/AuthenticationContext";
import { SubscriptionProvider } from "contexts/subscriptionContext";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AnalyticsManager from "services/Analytics/AnalyticsManager";
import { store } from "store";
import "./styles/index.scss";

function App() {
  AnalyticsManager.initialiseAnalytics();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <AuthenticationContextProvider>
            <SubscriptionProvider>
              <AppRoutes />
              <ModalFactory />
            </SubscriptionProvider>
          </AuthenticationContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
