import { Grid } from "drax-design-system";
import React from "react";
import { useDispatch } from "react-redux";
import { setModalType } from "store/slices/modal";
import "./styles.scss";

const Modal: React.FC<{ children?: any; blocking?: boolean }> = ({
  children,
  blocking,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        data-testid="modal-backdrop"
        onClick={() => {
          if (blocking === true) {
            return;
          }
          dispatch(setModalType({ type: null }));
        }}
        className="c-modal__backdrop"
      ></div>
      <div className="c-modal__flex">
        <div className="c-modal__container">
          <Grid template="1fr">{children}</Grid>
        </div>
      </div>
    </>
  );
};

export default Modal;
