import React from "react";
import {
  ScalesType
} from "../Insights/reportSavingsData";
import { NumberFormatter, NumberFormatterProps } from "./NumberFormatter";
import "./styles.scss";
import Trend from "./Trend";

const NoData = "N/A";

interface FinancialTrendProps {
    title: string;
    subTitle: string;
    valueTemplate: string;
    targetComponent: string;
    value: number | null;
    scales: ScalesType;
    onForward?: () => void;
    theme?: "dark" | "gray";
    testId?: string;
    targetData?: number;
    Formatter?: React.ComponentType<NumberFormatterProps>;
}

export const FinancialTrend = (props: FinancialTrendProps) => {
  const { value, onForward } = props;
  const { title, subTitle } = props;
  const { valueTemplate } = props;
  const { scales, theme, testId } = props;
  const { targetData = 0, targetComponent } = props;
  const { Formatter = NumberFormatter } = props;

  return (
    <Trend
      testId={testId}
      title={title}
      subTitle={subTitle}
      currValue={value}
      targetValue={targetData}
      onForward={onForward}
      ValueComponent={({ currValue }) => {
        return (
          <Formatter
            template={valueTemplate}
            value={currValue}
            scales={scales}
            precision={2}
            noDataMsg={NoData}
          />
        );
      }}
      TargetComponent={targetComponent}
      theme={theme ?? "gray"}
    />
  );
};
