import classnames from "classnames";
import React, { FC, ReactElement } from "react";
import "./ProgressBar.scss";

export type CSSVarTypeReturn = {
  [key: string]: string | number;
};

export type ProgressBarType =
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "disabled";

export type ProgressBarProps = {
  state?: ProgressBarType;
  value?: number;
  height?: number;
} & React.HTMLAttributes<HTMLDivElement>;

const ProgressBar: FC<ProgressBarProps> = ({
  state = "primary",
  className,
  value,
  height = 3,
}: ProgressBarProps): ReactElement => {
  const styleVars: CSSVarTypeReturn = {
    "--progressbar-indicator-width": `${(value || 0) * 100}%`,
    "--progressbar-height": `${height}px`,
  };

  return (
    <div className={classnames("d-progressbar", state, className)}>
      <div className="d-progressbar__indicator" style={styleVars} />
    </div>
  );
};

export default ProgressBar;
