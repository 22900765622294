import strings from "assets/strings";
import { Error } from "components";
import { Container, Grid, Panel } from "drax-design-system";
import "./styles.scss";
interface IPageError {
  withPanel: boolean;
  title?: string;
  message?: string;
}

const PageError: React.FC<IPageError> = ({ withPanel, title, message }) => {
  const ErrorElement = () => (
    <Error
      title={title ? title : strings.api.errors.pageError.title}
      message={message ? message : strings.api.errors.pageError.message}
    />
  );

  return withPanel ? (
    <Container className="p-error-page__container">
      <Grid align="center" gap="var(--spacing-gap-s)" template="1fr">
        <Panel>
          <ErrorElement />
        </Panel>
      </Grid>
    </Container>
  ) : (
    <ErrorElement />
  );
};

export default PageError;
