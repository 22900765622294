import { useAuthenticationContext } from "contexts/AuthenticationContext";
import { useCallback, useEffect, useState } from "react";
import { fetchBffUser } from "./useBffUser";
import { useDocumentEventListener } from "./useDocumentEventListener";

const DELAY_SEC = -5;

export const useExpiration = () => {
  const [expiresInTime, setExpiresInTime] = useState<number>();
  const { isLoggedIn, login, logout } = useAuthenticationContext();

  const fetchBffUserCb = useCallback(() => {
    fetchBffUser(false)
      .then((userClaims) => {
        const seconds = userClaims?.["bff:session_expires_in"] ?? 0;
        if (seconds > Math.abs(DELAY_SEC)) {
          const nextExpiresInTime = new Date(Date.now() + seconds * 1000);
          setExpiresInTime(nextExpiresInTime.getTime());
        } else {
          logout();
        }
      })
      .catch((err: any) => {
        if (err?.response?.status === 401) {
          login();
        }
      });
  }, [logout, login]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    let checkTimeout = (expiresInTime ?? 0) - Date.now() + DELAY_SEC * 1000;

    if (checkTimeout < 0) {
      checkTimeout = Math.abs(DELAY_SEC * 1000);
    }

    const id = setTimeout(fetchBffUserCb, checkTimeout);

    return () => {
      clearTimeout(id);
    };
  }, [isLoggedIn, expiresInTime, fetchBffUserCb]);

  const visibilityChangeCb = useCallback(async () => {
    if (document.hidden || !isLoggedIn) {
      return;
    }
    fetchBffUserCb();
  }, [fetchBffUserCb, isLoggedIn]);

  useDocumentEventListener("visibilitychange", visibilityChangeCb, 100);
};
