import paths from "appRoutes/paths";
import { draxLogo } from "assets/images/svgs";
import { Authorization, Role } from "components/Authorization";
import { Subscription, useSubscription } from "contexts/subscriptionContext";
import { Grid } from "drax-design-system";
import { default as React, FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectUserInfo } from "store/slices/auth";
import { Profile, Settings } from "./components";
import {
  dashboardNavItem,
  energyNavItem,
  navigationItems,
  notificationNavItem,
} from "./config";
import { NavigationItem } from "./NavigationItem";
import "./styles.scss";
import { NavItem } from "./types";

const Header: FunctionComponent = () => {
  const location = useLocation();

  const activeState = (el: NavItem): boolean => {
    const isHome = location.pathname === paths.home && el.route === paths.home;
    if (isHome) return isHome;
    return el.route.length > 1 && location.pathname.indexOf(el.route) === 0;
  };

  const user = useSelector(selectUserInfo);
  const { orgId } = useSubscription();

  const subscription = useSubscription();
  const navLinks: NavItem[] = subscription.checkSubscription(
    Subscription.Energy
  )
    ? [dashboardNavItem, energyNavItem, ...navigationItems]
    : [dashboardNavItem, ...navigationItems];

  if (user?.orgId === orgId) {
    navLinks.push(notificationNavItem);
  }

  return (
    <div className="l-header">
      <Grid template="1fr 1fr">
        <Link to={paths.home}>
          <img alt="Drax logo" className="l-header__logo" src={draxLogo} />
        </Link>
      </Grid>
      <nav>
        <Authorization role={Role.User}>
          <ul className="l-header__nav-list">
            {navLinks.map((el) => {
              const Component = el.component ?? NavigationItem;
              return (
                <Component
                  key={el.route}
                  isActive={activeState(el)}
                  data={el}
                />
              );
            })}
          </ul>
        </Authorization>
      </nav>
      <div style={{ display: "flex" }}>
        <Profile userName={user?.name ?? ""} />
        <Authorization role={Role.Admin}>
          <Settings userOrgId={user?.orgId} />
        </Authorization>
      </div>
    </div>
  );
};

export default Header;
