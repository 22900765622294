import { FinanceReportingData } from "api/domains/chargingStations/types";
import "./styles.scss";

type TotalCalculationsProps = {
    data: FinanceReportingData[]
}

const TotalCalculations = (props: TotalCalculationsProps) => {
    const { data } = props;

    const totalVolume = data.reduce((accumulator, item) => {
        return accumulator + item.totalVolume;
      }, 0).toFixed(2);

      const totalAmount = data.reduce((accumulator, item) => {
        return accumulator + item.totalAmount;
      }, 0).toFixed(2);

    return (
        <table className="rounded-corners">
            <tr>
                <td className="total" rowSpan={2}>Total</td>
                <td className="headers">Total volume (kWh)</td>
                <td className="headers" >Total amount (£)</td>
            </tr>
            <tr>
                <td className="values">{totalVolume}</td>
                <td className="values">{totalAmount}</td>
            </tr>
        </table>
    )
}

export default TotalCalculations;