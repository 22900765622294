import { IChargingStationPort } from "api/domains/chargingStations/types";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { selectSelectedChargingStationDetails } from "store/selectors/chargeStations";
import {
  getChargeStationDetails,
  selectChargeStations,
} from "store/slices/chargestations";

export function useChargingStationDetails(stationId: string) {
  const dispatch = useDispatch();
  const station = useSelector((state: RootState) => {
    const list = selectChargeStations(state);
    return list.find((cs) => cs.id === stationId);
  });
  const details = useSelector((state: RootState) =>
    selectSelectedChargingStationDetails(state, { stationId })
  );

  const portBaseList = station?.ports;
  const portDetailsList = details?.data?.ports;

  const ports: Array<IChargingStationPort> = useMemo(() => {
    return (portBaseList ?? []).map((base) => {
      const details = (portDetailsList ?? []).find((x) => x.id === base.id);
      if (details) {
        return { ...base, ...details };
      }
      return base as any;
    });
  }, [portBaseList, portDetailsList]);

  const updateTimestamp = ports
    .map((p) => `${p.lastStatusUpdate}-${p.lastSessionUpdated}`)
    .join("");

  const updateTimestampRef = useRef(updateTimestamp);

  useEffect(() => {
    if (!stationId) {
      return;
    }
    if (!(updateTimestampRef.current === "" && updateTimestamp !== "")) {
      dispatch(getChargeStationDetails(stationId));
    }
    updateTimestampRef.current = updateTimestamp;
  }, [dispatch, stationId, updateTimestamp]);

  return useMemo(() => {
    return { station, details, ports };
  }, [station, details, ports]);
}
