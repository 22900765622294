import strings from "assets/strings";
import { Heading, Text } from "drax-design-system";
import "./styles.scss";

interface ITitle {
  title: string;
  count?: number;
}

const Title: React.FC<ITitle> = ({ title, count }) => (
  <div className="p-title">
    <Heading.h2 textColor="tertiary">{title}</Heading.h2>
    {count && (
      <Text textColor="gray" className="p-title__count">
        {count} {strings.reports.table.items}
      </Text>
    )}
  </div>
);

export default Title;
